import { InputLabel, MenuItem, Popover, Select } from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { SHOW_CABIN_CLASS } from "../../constants";
import { FlightClassOption } from "../../constants/Options";
import { useBooleanState } from "../../hooks/CommonHooks";
import { CustomButton, CustomItem, DropdownNumber, DropdownText, StyledSelect } from "./Style";

const FlightDropdownPassenger = ({ adults, child, infants, cabinClass, onChangePassenger }) => {
    const [adultPassenger, setAdults] = useState(adults);
    const [childPassenger, setChild] = useState(child);
    const [infantsPassenger, setInfants] = useState(infants);
    const [flightClass, setCabinClass] = useState(cabinClass);
    const openGuestPopOver = useBooleanState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        openGuestPopOver.toggle();
    };

    const subAdult = () => {
        if (adultPassenger > 1) {
            setAdults((prev) => prev - 1);
        }
    };

    const addAdult = () => {
        if (adultPassenger + childPassenger + infantsPassenger < 6) {
            setAdults((prev) => prev + 1);
        }
    };

    const subChildren = () => {
        if (childPassenger > 0) {
            setChild((prev) => prev - 1);
        }
    };

    const addChildren = () => {
        if (adultPassenger + childPassenger + infantsPassenger < 6) {
            setChild((prev) => prev + 1);
        }
    };

    const subInfant = () => {
        if (infantsPassenger > 0) {
            setInfants((prev) => prev - 1);
        }
    };

    const addInfant = () => {
        if (adultPassenger + childPassenger + infantsPassenger < 6) {
            setInfants((prev) => prev + 1);
        }
    };

    const handleFlightClassChange = (event) => {
        setCabinClass(event.target.value);
    };

    const closeModal = () => {
        setAnchorEl(event.currentTarget);
        openGuestPopOver.toggle();
        onChangePassenger({
            adults: adultPassenger,
            child: childPassenger,
            infants: infantsPassenger,
            cabinClass: flightClass
        });
        setAnchorEl(null);
        openGuestPopOver.toggle();
    };

    return (
        <div className="SearchForm__dropdown dropdown-passengers">
            <Button onClick={handleClick} className="SearchForm__dropdownToggleButton">
                <div className="SearchForm__label">Passengers</div>
                <div className="SearchForm__fieldValue">
                    {adults > 0 && (
                        <span className="info-passenger">
                            {adults} adult{adults > 1 ? "s" : ""}
                        </span>
                    )}
                    {child > 0 && (
                        <span className="info-passenger">
                            {adults > 0 && ", "}
                            {child} child{child > 1 ? "ren" : ""}
                        </span>
                    )}
                    {infants > 0 && (
                        <span className="info-passenger">
                            {(adults > 0 || child > 0) && ", "}
                            {infants} infant{infants > 1 ? "s" : ""}
                        </span>
                    )}
                    {SHOW_CABIN_CLASS && cabinClass && (
                        <span className="info-passenger">
                            , {FlightClassOption.find((item) => item.value === cabinClass).name}
                        </span>
                    )}
                </div>
            </Button>
            <Popover
                open={openGuestPopOver.value}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                onClose={closeModal}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                PaperProps={{
                    style: {
                        minWidth: 310,
                        paddingTop: "15px",
                        paddingBottom: "15px"
                    }
                }}
            >
                <div className="SearchForm__dropdownMenu">
                    <CustomItem>
                        <Row>
                            <Col>
                                <DropdownNumber>{adultPassenger}</DropdownNumber>
                                <DropdownText>Adults</DropdownText>
                            </Col>
                            <Col xs="auto">
                                <CustomButton
                                    onClick={subAdult}
                                    type="button"
                                    className="btn-count"
                                    disabled={adultPassenger === 0}
                                >
                                    -
                                </CustomButton>
                                <CustomButton onClick={addAdult} type="button" className="btn-count">
                                    +
                                </CustomButton>
                            </Col>
                        </Row>
                    </CustomItem>
                    <CustomItem>
                        <Row>
                            <Col>
                                <DropdownNumber>{childPassenger}</DropdownNumber>
                                <DropdownText>
                                    Children <span className="note">(2 to 11yrs)</span>
                                </DropdownText>
                            </Col>
                            <Col xs="auto">
                                <CustomButton
                                    onClick={subChildren}
                                    type="button"
                                    className="btn-count"
                                    disabled={childPassenger === 0}
                                >
                                    -
                                </CustomButton>
                                <CustomButton onClick={addChildren} type="button" className="btn-count">
                                    +
                                </CustomButton>
                            </Col>
                        </Row>
                    </CustomItem>
                    <CustomItem>
                        <Row>
                            <Col>
                                <DropdownNumber>{infantsPassenger}</DropdownNumber>
                                <DropdownText>
                                    Infants <span className="note">(under 2)</span>
                                </DropdownText>
                            </Col>
                            <Col xs="auto">
                                <CustomButton
                                    onClick={subInfant}
                                    type="button"
                                    className="btn-count"
                                    disabled={infantsPassenger === 0}
                                >
                                    -
                                </CustomButton>
                                <CustomButton onClick={addInfant} type="button" className="btn-count">
                                    +
                                </CustomButton>
                            </Col>
                        </Row>
                    </CustomItem>
                    {SHOW_CABIN_CLASS && (
                        <CustomItem style={{ marginTop: 30 }}>
                            <Row>
                                <Col>
                                    <StyledSelect fullWidth>
                                        <InputLabel>Cabin Class</InputLabel>
                                        <Select
                                            id="demo-simple-select"
                                            value={flightClass}
                                            label="Cabin Class"
                                            sx={{
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    border: "2px #19b4d1 solid"
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#19b4d1"
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#19b4d1"
                                                }
                                            }}
                                            onChange={handleFlightClassChange}
                                        >
                                            {FlightClassOption.filter((item) => item.toShow).map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledSelect>
                                </Col>
                            </Row>
                        </CustomItem>
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default FlightDropdownPassenger;
