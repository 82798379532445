import { repaymentApiUrl } from "../../constants/APIConstants";
import { createAction } from "../utils";

const GET_PAYMENT_PLAN = "plt-web/paymentPlan/GET_PAYMENT_PLAN";
const LOADING_PAYMENT_PLAN = "plt-web/paymentPlan/LOADING_PAYMENT_PLAN";
const EMPTY_PAYMENT_PLAN = "plt-web/paymentPlan/EMPTY_PAYMENT_PLAN";

const initialState = {
    data: {},
    loading: false
};

export default function reducer(state = initialState, { type, ...action }) {
    switch (type) {
        case LOADING_PAYMENT_PLAN: {
            return { data: state.data, loading: true };
        }
        case GET_PAYMENT_PLAN: {
            return { data: action.payload, loading: false };
        }
        case EMPTY_PAYMENT_PLAN: {
            return initialState;
        }
        default:
            return state;
    }
}

// TODO: Remove this, doesn't seem to be used anywhere
export const emptyPaymentPlan = () => ({
    type: EMPTY_PAYMENT_PLAN
});

export const getPaymentPlan = ({ logError, dispatch, sendHttpRequest }) => (params) => {
    dispatch(createAction(LOADING_PAYMENT_PLAN));
    return sendHttpRequest(repaymentApiUrl, { method: 'POST', body: JSON.stringify(params) })
        .then(response => response.text())
        .then(textResponse => JSON.parse(textResponse))
        .then((paymentPlan) => {
            dispatch(createAction(GET_PAYMENT_PLAN, paymentPlan));
            return paymentPlan;
        })
        .catch((err) => {
            logError("Error calling " + repaymentApiUrl + ". Error: " + err.message, err);
            dispatch(createAction(GET_PAYMENT_PLAN, {}));
            return { message: err.message };
        });
};
