import React from "react";
import { _url } from "../../../config/utils";

const ReviewSupportAu = () => {
    return (
        <div>
            <div className="checkout-feature">
                <img alt="support" src={_url("assets/images/support.png")} />
                <div className="checkout-feature-content">
                    <h5>24/7 Support team</h5>
                    <p>
                        Call{" "}
                        <u>
                            <a href="tel:1300 399 902">1300 399 902</a>
                        </u>{" "}
                        or contact us via chat or email.
                    </p>
                </div>
            </div>
            <div className="checkout-feature">
                <img alt="yoga" src={_url("assets/icons/yoga.png")} />
                <div className="checkout-feature-content">
                    <h5>Flexible Interest-Free Payments</h5>
                    <p>
                        Interest-free weekly or fortnightly instalments before you travel. Pick a payment plan that
                        suits your lifestyle and budget.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ReviewSupportAu;
