/* eslint-disable unused-imports/no-unused-imports */
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { Col, Row } from "reactstrap";
import { FLIGHT_BEST_SORT } from "../../../constants";
import { FlightSortOptions } from "../../../constants/Options";
import {
    updateCarriersArrivalFilter,
    updateCarriersDepartureFilter,
    updateCarriersFilter,
    updateCarriersStopFilter
} from "../../../redux/modules/search";
import { logAmplitudeEvent } from "../../../utils/SiteUtils";

export const SearchResultCount = ({ filteredFlights, flights, dispatch, sortOrder, setSortOrder }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!flights || flights.length === 0) {
        return null;
    }
    return (
        <Row className="search-results-padding">
            <Col>
                <p className="search-results-number">
                    {`${filteredFlights.length !== flights.length ? `${filteredFlights.length} of ` : ""}`}
                    {flights.length} result
                    {flights.length === 1 ? "" : "s"}
                    {filteredFlights.length !== flights.length && (
                        <a
                            className="show-all"
                            onClick={() => {
                                dispatch(updateCarriersFilter([]));
                                dispatch(updateCarriersStopFilter([]));
                                dispatch(updateCarriersArrivalFilter([]));
                                dispatch(updateCarriersDepartureFilter([]));
                            }}
                        >
                            {" "}
                            (Show all)
                        </a>
                    )}
                </p>
            </Col>
            <Col style={{ textAlign: "end" }} className="text-right search-results-sort-box">
                Sort by:{" "}
                <span onClick={handleClick} style={{ cursor: "pointer", color: "#19B4D1" }}>
                    {sortOrder === FLIGHT_BEST_SORT ? "Best" : "Cheapest"}
                </span>
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                >
                    {FlightSortOptions.map((option) => (
                        <MenuItem
                            onClick={() => {
                                logAmplitudeEvent("flightSortOrderChanged", {
                                    order: option.value
                                });
                                setSortOrder(option.value);
                                handleClose();
                            }}
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Menu>
            </Col>
        </Row>
    );
};
