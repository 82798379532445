import React from "react";
import { Col, Row } from "reactstrap";

export const MobileFiltersHeader = ({ searchState, setSearchState }) => (
    <>
        <div className="filters__mobile-header d-sm-block d-md-block d-lg-none">
            <Row>
                <Col>
                    <h4>Filters</h4>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    <span
                        onClick={() =>
                            setSearchState({
                                ...searchState,
                                filterToggled: false
                            })
                        }
                    >
                        Done
                    </span>
                </Col>
            </Row>
        </div>
    </>
);
