import moment from "moment";
import InternalApi from "../../config/internalApi";
import { getSiteValueByLang, windowScroll } from "../../utils/SiteUtils";

export const transformPaymentPlanPayload = (reviewData, auth, detailFlight, departureDate) => {
    const payload = {
        productType: "flight",
        searchGuid: detailFlight.searchGuid,
        resultGuid: detailFlight.guid,
        departureDate: moment(departureDate).format("YYYY-MM-DD"),
        paymentStartDate: reviewData.startPayment ? moment(reviewData.startPayment).unix() : moment().unix(),
        credit: reviewData.credit || false,
        couponCode: reviewData.couponCode || undefined,
        customerIdentifier: auth.isAuthenticated ? auth.user.id : undefined,
        currency: getSiteValueByLang("currencyCode")
    };
    if (reviewData.depositPercent) {
        payload.depositPercentage = reviewData.depositPercent;
    }
    return payload;
};

const transformTravellorDetails = (passengers, detailFlight, searchQuery) =>
    [
        ...passengers.adults.map((r) => ({ ...r, type: "Adult" })),
        ...passengers.children.map((r) => ({ ...r, type: "Child" })),
        ...passengers.infants.map((r) => ({ ...r, type: "Infant", isInfant: true }))
    ].map((p, index) => ({
        id: index + 1,
        type: p.type,
        title: p.title,
        gender: p.title === "Mr" || p.title === "Master" ? "m" : "f",
        firstName: p.firstName,
        middleName: p.midName,
        lastName: p.lastName,
        dateOfBirth: p.date,
        passportOrigin: p.passport,
        departTime: searchQuery.departureDate,
        departAirline: detailFlight.itineraries[0].segments[0].aircraftname,
        customerEmail: passengers.email,
        isInfant: !!p.isInfant
    }));

export const transformPaymentPlanPayloadForTraveller = (
    passengers,
    detailFlight,
    reviewData,
    paymentPlan,
    searchQuery,
    paymentMethod
) => {
    const departureDate =
        searchQuery.type === "multi-city" ? searchQuery.multiCityLegs[0].departureDate : searchQuery.departureDate;

    // update unix payment start date
    const now = new Date();
    const formattedStartDate = moment(reviewData.startPayment)
        .set({
            hour: now.getHours(),
            minute: now.getMinutes(),
            second: now.getSeconds(),
            millisecond: now.getMilliseconds()
        })
        .unix();

    const data = {
        searchGuid: detailFlight.searchGuid,
        resultGuid: detailFlight.guid,
        email: passengers.email,
        deposit: paymentPlan.data.deposit,
        minDepositPercent: getInitialDepositPercent(departureDate),
        depositPercent: reviewData.depositPercent,
        instalmentType: reviewData.plan === 1 ? "week" : "fortnight",
        pricePerInstalment: reviewData.plan === 1 ? paymentPlan.data.pricePerWeek : paymentPlan.data.pricePerFortnight,
        numberOfInstalments: reviewData.plan === 1 ? paymentPlan.data.weeks : paymentPlan.data.fortnights,
        startDate: formattedStartDate,
        preMarkupPrice: detailFlight.totalPrice + detailFlight.totalTaxes, // TODO: no totalPrice in detailFlight
        totalCompletePrice: detailFlight.totalCompletePrice,
        travellers: searchQuery.adults + searchQuery.children + searchQuery.infants,
        customerPhone: passengers.phone_number,
        travellersInfo: JSON.stringify(transformTravellorDetails(passengers, detailFlight, searchQuery)),
        outboundAirportIATAcode: detailFlight.itineraries[0].segments[0]
            ? detailFlight.itineraries[0].segments[0].departureCode
            : "",
        outboundAirline: detailFlight.itineraries[0].segments[0]
            ? detailFlight.itineraries[0].segments[0].carrierName
            : "",
        outboundDepartureTime: detailFlight.itineraries[0].segments[0]
            ? detailFlight.itineraries[0].segments[0].departureAt
            : "",
        originalPrice: detailFlight.totalOwed,
        couponCode: reviewData.couponCode || undefined,
        credit: reviewData.credit || undefined,
        paymentMethod
    };

    if (passengers.password.length > 0) {
        data.password = passengers.password;
    }

    return data;
};

export const updateDataLayer = (data, couponCode, reference) => {
    const cleanedData = data;
    cleanedData.password = undefined;
    cleanedData.productId = "flight";
    cleanedData.couponCode = couponCode || undefined;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "bookingCompleted",
        newBooking: {
            ...cleanedData,
            reference: reference
        }
    });
};

export const validateReviewData = (reviewData, setReviewData) => {
    if (reviewData.startPayment === "") {
        setReviewData({
            ...reviewData,
            error: {
                startPayment: true
            }
        });
        windowScroll("single-date-col");
        return false;
    }
    return true;
};

export const getInitialDepositPercent = () => {
    return 20; // todo - remove this
};

export const paymentSubmit = async (data, auth) =>
    InternalApi.post("v2/booking", data, { "x-access-token": auth ? auth.token : undefined })
        .then((res) => Promise.resolve(res))
        .catch((err) => Promise.reject(err));

export const dividePaymentPlanInRange = (start, end, interval) => {
    const result = [];

    for (let i = start; i <= end; i += interval) {
        result.push(i);
    }

    if (result[result.length - 1] !== end) {
        result.push(end);
    }

    return result;
};
