import React from "react";
import { NavLink } from "react-router-dom";
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from "reactstrap";
import { DropdownContainer } from "./Style";

const CollapsableMenu = ({
    auth,
    isOpen,
    toggleMobileMenu,
    path,
    toggleLoginModal,
    accountDropdownOpen,
    toggleAccountDropdown,
    logoutUser
}) => {
    return (
        <Collapse style={{ zIndex: 1 }} isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink to="/how-it-works" className="nav-link" onClick={toggleMobileMenu}>
                        How it works
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/faq" className="nav-link" onClick={toggleMobileMenu}>
                        FAQ
                    </NavLink>
                </NavItem>

                {!auth.isAuthenticated &&
                    path !== "/login" &&
                    !path.includes("review") &&
                    !path.includes("confirm") && (
                        <button className="header__button d-none d-md-block" onClick={toggleLoginModal}>
                            Log In
                        </button>
                    )}
                {!auth.isAuthenticated &&
                    path === "/login" &&
                    !path.includes("review") &&
                    !path.includes("confirm") && (
                        <NavItem>
                            <NavLink
                                to="/login"
                                className="header__button d-none d-md-block"
                                onClick={toggleMobileMenu}
                            >
                                Log In
                            </NavLink>
                        </NavItem>
                    )}
                {!auth.isAuthenticated && !path.includes("review") && !path.includes("confirm") && (
                    <NavItem>
                        <NavLink to="/login" className="nav-link d-block d-md-none" onClick={toggleMobileMenu}>
                            Log In
                        </NavLink>
                    </NavItem>
                )}
                {auth.isAuthenticated && (
                    <DropdownContainer
                        style={{
                            right: window.innerWidth > 768 ? 20 : 0
                        }}
                        isOpen={accountDropdownOpen}
                        toggle={toggleAccountDropdown}
                        nav
                        inNavbar
                    >
                        <DropdownToggle
                            className="header__button"
                            nav
                            caret={!path.includes("review")}
                            disabled={path.includes("review")}
                        >
                            {window.innerWidth < 768 ? "My Account" : auth.user.firstName}
                        </DropdownToggle>
                        <DropdownMenu right>
                            <div className="dropdown-item__primary-wrapper">
                                <NavLink
                                    to="/bookings"
                                    className="dropdown-item"
                                    onClick={() => {
                                        toggleMobileMenu();
                                        toggleAccountDropdown();
                                    }}
                                >
                                    My Bookings
                                </NavLink>
                                <NavLink
                                    to="/refer"
                                    className="dropdown-item"
                                    onClick={() => {
                                        toggleMobileMenu();
                                        toggleAccountDropdown();
                                    }}
                                >
                                    Credit
                                </NavLink>
                                <NavLink
                                    to="/refer"
                                    className="dropdown-item refer-nav-link"
                                    onClick={() => {
                                        toggleMobileMenu();
                                        toggleAccountDropdown();
                                    }}
                                >
                                    Refer and Get $25!
                                </NavLink>
                            </div>
                            <DropdownItem divider />
                            <DropdownItem
                                onClick={() => {
                                    logoutUser();
                                }}
                            >
                                Sign Out
                            </DropdownItem>
                        </DropdownMenu>
                    </DropdownContainer>
                )}
            </Nav>
        </Collapse>
    );
};

export default CollapsableMenu;
