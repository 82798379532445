import { Button } from "reactstrap";

export const ShowMoreResults = ({ visibleFlightCount, filteredFlightsCount, flights, showMoreResults }) => {
    if (!flights || flights.length === 0) {
        return null;
    }
    return (
        <div className="button-content">
            <Button
                color="default"
                size="lg"
                onClick={() => showMoreResults()}
                disabled={visibleFlightCount > filteredFlightsCount}
            >
                {visibleFlightCount > filteredFlightsCount ? "No more results" : "Show more results"}
            </Button>
        </div>
    );
};
