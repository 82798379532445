import Styled from "@emotion/styled";
import { Box, FormControl } from "@mui/material";
import { Button } from "reactstrap";

export const DropdownNumber = Styled.span`
font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #191b37;
    margin-right: 10px;
`;

export const DropdownText = Styled.span`
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #353d74;

    span {
        font-weight: 300;
    }
`;

export const CustomButton = Styled(Button)`
    background-color: #ffffff !important;
    font-size: 28px;
    font-weight: bold;
    color: #19b4d1 !important;
    border: 1px solid #e1e1e1;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 0px;
    text-align: center;
    margin-right: 3px;
    & .disabled {
        border-color: #6c757d;
    }
`;

export const CustomItem = Styled(Box)`
    margin-bottom: 14px;
    padding-left: 20px;
    padding-right: 20px;

    & .row {
        align-items: center;
    }
`;

export const StyledSelect = Styled(FormControl)`
    & .MuiFormLabel-root {
        color: #19b4d1;
    }    

`;
