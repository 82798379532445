import { ClickAwayListener, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React from "react";

const PaymentLinkDatePicker = ({
    date,
    isError,
    label,
    minimumDate,
    handleDateChange,
    type,
    onFieldBlur,
    maximumDate,
    errorMessage,
    dateFormat,
    handleClose,
    ...rest
}) => {
    const handleChange = (newValue) => {
        handleDateChange(new Date(newValue));
    };
    return (
        <ClickAwayListener
            onClickAway={() => {
                handleClose();
            }}
        >
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    label={label}
                    inputFormat={dateFormat || "DD MMM"}
                    value={date}
                    open
                    onChange={handleChange}
                    views={["year", "month", "day"]}
                    maxDate={maximumDate ? new Date(maximumDate) : new Date(moment().add("12", "months"))}
                    minDate={minimumDate ? new Date(minimumDate) : new Date()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{ background: "white", borderRadius: 10, width: "100%" }}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: "Add Date"
                            }}
                            onKeyDown={(e) => rest.isDisabled && e.preventDefault()}
                            error={!!isError}
                            helperText={!!isError && errorMessage}
                            onClick={() => setOpenDatepicker((prev) => !prev)}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        </ClickAwayListener>
    );
};

export default PaymentLinkDatePicker;
