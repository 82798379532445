import { Box, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { LoginedAccountButton } from "./Style";

const AccountButton = ({ auth, logoutUser, isScrolled }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <Box>
            <LoginedAccountButton isscrolled={isScrolled} endIcon={<ArrowDropDownIcon />} onClick={handleClick}>
                {window.innerWidth < 768 ? "My Account" : auth.user.firstName}
            </LoginedAccountButton>

            <Menu id="basic-menu" anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={() => navigate("/bookings")}>My Bookings</MenuItem>
                <MenuItem onClick={() => navigate("/refer")}>Credit</MenuItem>
                <MenuItem onClick={() => navigate("/refer")}>Refer and Get $25!</MenuItem>
                <MenuItem
                    onClick={() => {
                        logoutUser();
                    }}
                >
                    Sign out
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default AccountButton;
