/* eslint-disable unused-imports/no-unused-imports */
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LicenseInfo } from "@mui/x-license-pro";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "../components/ScrollToTop";
import { MUI_LICENSE_KEY } from "../constants";
import { updateSiteLang } from "../utils/SiteUtils";
import {
    randomizeDisplayForRedesignedSearch,
    setupExternalServices,
    setupExternalServicesWithUser,
    shouldDisplayTransparentHeader
} from "./AppUtils";
import AppRoute from "./route/AppRoute";

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const App = () => {
    const user = useSelector((state) => state.auth);
    const location = useLocation();
    const auth = useSelector((state) => state.auth);
    const loading = useSelector((state) => state.loading);
    const [displayRedesignedSearch, setDisplayRedesignedSearch] = useState();

    useEffect(() => {
        updateSiteLang();
        setupExternalServices();
        randomizeDisplayForRedesignedSearch(setDisplayRedesignedSearch);
    }, []);

    useEffect(() => {
        setupExternalServicesWithUser(user);
    }, [user]);

    const transparentHeader = shouldDisplayTransparentHeader(location.pathname);

    return (
        <div className="PLT">
            <div className={"Content"}>
                <ToastContainer position="top-center" hideProgressBar />
                <ScrollToTop>
                    <AppRoute
                        transparentHeader={transparentHeader}
                        displayRedesignedSearch={displayRedesignedSearch}
                        auth={auth}
                    />
                </ScrollToTop>
            </div>
            {loading.status && (
                <div className="loading">
                    <FontAwesomeIcon spin style={{ fontSize: "30px" }} icon={faSpinner} />
                </div>
            )}
        </div>
    );
};

export default App;
