/* eslint-disable arrow-body-style */
import React from "react";
import styled from "styled-components";
import StripeForm from "../../components/StripeForm";
import { CallToActionAcknowledgement } from "./acknowledgement/CallToActionAcknowledgement";
import { ReviewTermsAndConditions } from "./termsandcondition/ReviewTermsAndConditions";

const StyleBodyText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    color: #5f5f5f;
`;

export const ReviewPaymentCard = ({ paymentPlan, onPaymentSubmit }) => (
    <div className="card-details-wrapper payment max-w-640">
        <div className="card-detail max-w-640">
            {!(paymentPlan.total === 0) && <h3>Card details</h3>}
            <div className="content">
                <div className="CheckoutForm">
                    <StripeForm
                        buttonColor="success"
                        buttonText="Pay Deposit & Book now"
                        noLoading
                        disclaimerAboveButton={
                            <StyleBodyText>
                                <ReviewTermsAndConditions paymentPlan={paymentPlan} />
                                <CallToActionAcknowledgement paymentPlan={paymentPlan} />
                            </StyleBodyText>
                        }
                        noPaymentRequired={paymentPlan.total === 0}
                        handleSubmit={onPaymentSubmit}
                    />
                </div>
            </div>
        </div>
    </div>
);
