import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import * as moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../hooks/CommonHooks";
import { updateSearchFlights, updateSearchLoading, updateSearchResults } from "../redux/modules/search";
import SearchForm from "./searchform/index";

class EditSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    componentDidMount() {
        if (this.props.setOpen) this.props.setOpen(false);
        if (this.props.editSearchOpenOnDesktop && window.innerWidth > 768) this.setState({ open: true });
    }

    editSearch = (query, auth) => {
        if (this.props.productType === "hotels") {
            this.props.updateSearchResults(query);
        } else {
            this.props.updateSearchLoading();
            // supply included carrier codes for change flights edit search
            if (this.props.changeFlights) {
                const allCarriers = this.props.booking.lines[0].product.itineraries.map(
                    (itinerary) => itinerary.segments.map((segment) => segment.carrierCode)[0]
                );
                const carriers = [...new Set(allCarriers)];
                query.includedAirlineCodes = carriers.toString();
                this.props.updateSearchFlights(query);
            } else {
                this.props.updateSearchFlights(query, null, auth);
            }
        }
        if (this.props.onSearch) this.props.onSearch();

        if (this.props.setOpen) this.props.setOpen(false);
        if (this.props.editSearchOpenOnDesktop && window.innerWidth > 768) {
            this.setState({ open: true });
        } else {
            this.setState({ open: false });
        }
    };

    render() {
        const searchQuery = this.props.searchQuery || {};
        const open = this.props.open || this.state.open;

        let locations = "";
        let dates = "";
        let travellersInfo = "";

        if (searchQuery) {
            if (this.props.productType === "hotels") {
                const checkInDate = moment(searchQuery.checkInDate).format("D MMM");
                const checkOutDate = moment(searchQuery.checkOutDate).format("D MMM");
                // const children = (searchQuery.childAges && searchQuery.childAges.length > 0) ? searchQuery.childAges.split(',').length : 0;

                locations = searchQuery.destination && searchQuery.destination.includes("_") && (
                    <div className="EditSearch__info">
                        <span>
                            {searchQuery.destination.split("_")[0]},{" "}
                            {searchQuery.destination.split("_")[1].replace("/", "")}
                        </span>
                    </div>
                );

                dates = (
                    <div className="EditSearch__infoDates">
                        <span>{`${checkInDate} - ${checkOutDate}`}</span>
                    </div>
                );

                travellersInfo = searchQuery.childAges && (
                    <div className="EditSearch__infoTravellers">
                        <span>
                            {searchQuery.adults + searchQuery.childAges.length}{" "}
                            {`${searchQuery.adults + searchQuery.childAges.length > 1 ? "guests" : "guest"}`},{" "}
                            {searchQuery.rooms} room
                            {searchQuery.rooms > 1 ? "s" : ""}
                        </span>
                    </div>
                );
            } else {
                const { type, origin, multiCityLegs } = searchQuery;

                if (type === "multi-city") {
                    if (!multiCityLegs) return false;
                } else if (!origin) return false;

                const originIata = type === "multi-city" ? multiCityLegs[0].origin.id : searchQuery.origin.id;
                // const originCityName = type === "multi-city" ? multiCityLegs[0].origin.name.split(',',1)[0] : searchQuery.origin.city;
                const destinationIata =
                    type === "multi-city"
                        ? multiCityLegs[multiCityLegs.length - 1].destination.id
                        : searchQuery.destination.id;
                // const destinationCityName = type === "multi-city" ? multiCityLegs[multiCityLegs.length - 1].destination.name.split(',',1)[0] : searchQuery.destination.city;
                const departureDate = moment(
                    type === "multi-city" ? multiCityLegs[0].departureDate : searchQuery.departureDate
                ).format("D MMM");
                const returnDate = moment(
                    type === "multi-city"
                        ? multiCityLegs[multiCityLegs.length - 1].departureDate
                        : searchQuery.returnDate
                ).format("D MMM");
                // const longLocations = `${originCityName} (${originIata}) - ${destinationCityName} (${destinationIata})`;
                const shortLocations = `${originIata} - ${destinationIata}`;

                locations = (
                    <div className="EditSearch__info">
                        {/* <span className="EditSearch__long-locations">{longLocations}</span>  */}
                        <span className="EditSearch__locations">{shortLocations} </span>
                        <span className="search-info__airport-codes__multi-city-flights">
                            {type === "multi-city" && `(${multiCityLegs.length} flights)`}
                        </span>
                    </div>
                );

                dates = (
                    <div className="EditSearch__infoDates">
                        <span>
                            {departureDate} {type !== "one-way" && ` - ${returnDate}`}
                        </span>
                    </div>
                );

                travellersInfo = (
                    <div className="EditSearch__infoTravellers">
                        <span>
                            {searchQuery.adults + searchQuery.children + searchQuery.infants}{" "}
                            {`${
                                searchQuery.adults + searchQuery.children + searchQuery.infants > 1 ? "Guests" : "Guest"
                            }`}
                        </span>
                    </div>
                );
            }
        }

        return (
            <div
                className={classNames("EditSearch", {
                    expanded: open
                })}
            >
                {!open && (
                    <div
                        className="EditSearch__currentSearchDetails"
                        onClick={() => {
                            if (this.props.setOpen) {
                                this.props.setOpen(true);
                            } else {
                                this.setState({ open: true });
                            }
                        }}
                    >
                        {locations}
                        <div className="EditSearch__info EditSearch__infoDatesAndTravellers">
                            {dates}
                            {travellersInfo}
                        </div>
                        <div className="EditSearch__icon">
                            <FontAwesomeIcon icon="search" />
                        </div>
                    </div>
                )}
                {open && <SearchForm editSearch={this.editSearch} />}
                {!this.props.open && open && (
                    <p className="close" onClick={() => this.setState({ open: false })}>
                        Close
                    </p>
                )}
            </div>
        );
    }
}

// const mapStateToprops = (state) => ({
//   auth: state.auth
// });

const mapDispatchToProps = (dispatch) => ({
    updateSearchResults: (params) => {
        dispatch(updateSearchResults(params));
    },
    updateSearchLoading: () => {
        dispatch(updateSearchLoading());
    },
    updateSearchFlights: (params, saveState, auth) => {
        dispatch(updateSearchFlights(params, saveState, auth));
    }
});

export default withRouter(connect(null, mapDispatchToProps)(EditSearch));
