import queryString from "query-string";
import InternalApi from "../../config/internalApi";
import { EMPTY_BOOKING } from "../../redux/modules/bookings";
import { updateDetailFlight } from "../../redux/modules/detailFlight";
import {
    updateCarriersArrivalFilter,
    updateCarriersDepartureFilter,
    updateCarriersFilter,
    updateCarriersStopFilter,
    updateSearchFlights,
    updateSearchLoading
} from "../../redux/modules/search";
import { triggerBookingFlightSelectEvent } from "../../utils/events";
import { titleCase } from "../../utils/SearchUtils";
import { getSiteValueByLang } from "../../utils/SiteUtils";

export const onFlightSelect = (dispatch, history, searchResult, id, index) => {
    const selectedFlight = searchResult.data.find((x) => x.id === id);
    selectedFlight.index = index + 1;
    dispatch(updateDetailFlight(selectedFlight));
    history("/flightssearch/passenger");

    // push search event to GTM dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "bookingFlightSelected",
        selectedFlight
    });
    triggerBookingFlightSelectEvent();
    InternalApi.post("/flight-offers/select-result", {
        searchGuid: selectedFlight.searchGuid,
        resultGuid: selectedFlight.guid,
        originalId: id,
        index: selectedFlight.index,
        totalResults: searchResult.data.length
    });
};

export const onToggleCarriersFilter = (dispatch, key, searchResult) => {
    const activeCarriers = searchResult.carriersVisibilityFilter;
    const index = activeCarriers.indexOf(key);
    if (index > -1) {
        activeCarriers.splice(index, 1);
    } else {
        activeCarriers.push(key);
    }
    dispatch(updateCarriersFilter(activeCarriers));
};

export const onToggleCarriersStopFilter = (dispatch, key, searchResult) => {
    const activeStop = searchResult.carrierStopFilter;
    const index = activeStop.indexOf(key);
    if (index > -1) {
        activeStop.splice(index, 1);
    } else {
        activeStop.push(key);
    }
    dispatch(updateCarriersStopFilter(activeStop));
};

export const onToggleDepartureFilter = (dispatch, key, searchResult) => {
    const departureTime = searchResult.carrierDepartureFilter;
    const index = departureTime.indexOf(key);
    if (index > -1) {
        departureTime.splice(index, 1);
    } else {
        departureTime.push(key);
    }
    dispatch(updateCarriersDepartureFilter(departureTime));
};

export const onToggleArrivalFilter = (dispatch, key, searchResult) => {
    const arrivalTime = searchResult.carrierArrivalFilter;
    const index = arrivalTime.indexOf(key);
    if (index > -1) {
        arrivalTime.splice(index, 1);
    } else {
        arrivalTime.push(key);
    }
    dispatch(updateCarriersArrivalFilter(arrivalTime));
};

export const onShowMoreResults = (searchState, setSearchState) => {
    setSearchState({
        ...searchState,
        numflightsVisible: searchState.numflightsVisible + 20
    });

    // push search event to GTM dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "bookingShowMoreResultsClicked",
        searchResultsOnPage: searchState.numflightsVisible
    });
};

export const getFlightSearchList =
    ({ locationInfo, origin, destination, urlMultiCityLegs, searchQuery, returnDate, departureDate, auth }) =>
    (dispatch) => {
        // retrieve paramaters from the url
        const urlParams = queryString.parse(locationInfo.search);
        // retreive iata code information from the server
        let iataCodes = "";
        if (origin) {
            iataCodes = `${origin},${destination}`;
        }
        let multiCityLegs = [];
        if (urlMultiCityLegs.multicity) {
            multiCityLegs = JSON.parse(urlMultiCityLegs.multicity);
        }

        multiCityLegs.forEach((leg) => {
            iataCodes += `${leg.originId},${leg.destinationId},`;
        });

        dispatch(updateSearchLoading());
        let places;
        InternalApi.get("/iata-lookup", { iataCodes }).then((placesResult) => {
            places = placesResult;
            let query = {
                ...searchQuery,
                includedAirlineCodes: undefined,
                currencyCode: getSiteValueByLang("currencyCode")
            };

            // if origin and return date exists then we are in a return search

            if (origin) {
                const type = returnDate ? "return" : !returnDate && !departureDate ? "return" : "one-way";
                const originIataMeta = places.find((x) => x.iata_code === origin);
                const destinationIataMeta = places.find((x) => x.iata_code === destination);
                query = {
                    ...query,
                    origin: {
                        id: origin,
                        city: titleCase(originIataMeta.address_city_name),
                        name: titleCase(originIataMeta.name),
                        country: titleCase(originIataMeta.address_country_name)
                    },
                    destination: {
                        id: destination,
                        city: titleCase(destinationIataMeta.address_city_name),
                        name: titleCase(destinationIataMeta.name),
                        country: titleCase(destinationIataMeta.address_country_name)
                    },
                    type,
                    c: undefined,
                    ...urlParams
                };

                if (departureDate) query.departureDate = departureDate;
                if (returnDate) query.returnDate = returnDate;
                if (type === "one-way") query.returnDate = undefined;
            } else {
                // we are in a multi city search
                const formattedMultiCityLegs = multiCityLegs.map((key) => {
                    const originIataMeta = places.find((x) => x.iata_code === key.originId);
                    const destinationIataMeta = places.find((x) => x.iata_code === key.destinationId);
                    return {
                        origin: {
                            id: key.originId,
                            city: titleCase(originIataMeta.address_city_name),
                            name: titleCase(originIataMeta.name),
                            country: titleCase(originIataMeta.address_country_name)
                        },
                        destination: {
                            id: key.destinationId,
                            city: titleCase(destinationIataMeta.address_city_name),
                            name: titleCase(destinationIataMeta.name),
                            country: titleCase(destinationIataMeta.address_country_name)
                        },
                        departureDate: key.departureDate
                    };
                });
                query = {
                    ...query,
                    multiCityLegs: formattedMultiCityLegs,
                    type: "multi-city",
                    c: undefined,
                    ...urlParams
                };
            }
            dispatch(updateSearchFlights(query, null, auth));
            dispatch({ type: EMPTY_BOOKING });
        });
    };

export const StopsOptions = [
    {
        value: "0",
        label: "Non Stop"
    },
    {
        value: "1",
        label: "1 stop"
    },
    {
        value: "2",
        label: "2+ stop"
    }
];

export const DepartureTimeOptions = [
    {
        id: "1",
        title: "Morning (05:00 - 11:59)",
        timeRange: {
            startTime: 5,
            endTime: 12
        }
    },
    {
        id: "2",
        title: "Afternoon (12:00 - 17:59)",
        timeRange: {
            startTime: 12,
            endTime: 18
        }
    },
    {
        id: "3",
        title: "Evening (18:00 - 11:59)",
        timeRange: {
            startTime: 18,
            endTime: 24
        }
    }
];

export const ArrivalTimeOptions = [
    {
        id: "1",
        title: "Early Morning(00:00 - 04:59)",
        timeRange: {
            startTime: 0,
            endTime: 5
        }
    },
    {
        id: "2",
        title: "Morning (05:00 - 11:59)",
        timeRange: {
            startTime: 5,
            endTime: 12
        }
    },
    {
        id: "3",
        title: "Afternoon (12:00 - 17:59)",
        timeRange: {
            startTime: 12,
            endTime: 18
        }
    },
    {
        id: "4",
        title: "Evening (18:00 - 11:59)",
        timeRange: {
            startTime: 18,
            endTime: 24
        }
    }
];
