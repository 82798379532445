import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Tooltip = ({ id, icon, placement, children }) => (
    <span className="plt-tooltip">
        <FontAwesomeIcon icon={icon} id={`Tooltip__${id}`} />
        <UncontrolledTooltip placement={placement} target={`Tooltip__${id}`}>
            {children}
        </UncontrolledTooltip>
    </span>
);

export default Tooltip;
