import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Divider, Grid, IconButton } from "@mui/material";
import { useState } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { Alert, Col, Row } from "reactstrap";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { DisplayText, Text } from "../../../components/common/typography/Style";
import { _formatMoney, _url } from "../../../config/utils";
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY } from "../../../constants";
import { ColorTheme } from "../../../styles/color";
import { usePaymentAmountDisplay } from "./PaymentAmountHooks";
import { PaymentStartDate } from "./PaymentStartDate";

export const PaymentAmountDisplay = ({ reviewData, paymentPlan, setReviewData, onUpdatePaymentPlan }) => {
    const { startDate, frequency, moneyDisplay } = usePaymentAmountDisplay(reviewData, paymentPlan);
    const balance = useSelector((state) => state.referrals.balance);

    const { mobileView } = useGetWindowSize();
    const [openPaymentAccordian, setOpenPaymentAccordian] = useState(true);

    return (
        <>
            <Grid container px={[0, 0, undefined]} justifyContent={"space-between"} flexWrap={"nowrap"}>
                <Grid item>
                    <Text size={mobileView ? "sm" : "md"} textweight={"regular"}>
                        {paymentPlan.discount ? "New Total" : "Total"}
                    </Text>
                </Grid>
                <Grid item>
                    {paymentPlan.discount && paymentPlan.discount.amount && (
                        <Text
                            textcolor={ColorTheme.grayNeutral[700]}
                            size={mobileView ? "md" : "lg"}
                            textweight={"medium"}
                        >
                            ${parseFloat((paymentPlan.total + paymentPlan.discount.amount) / 100).toFixed(2)}
                        </Text>
                    )}
                    <Text textcolor={ColorTheme.grayNeutral[700]} size={mobileView ? "md" : "lg"} textweight={"medium"}>
                        ${paymentPlan && parseFloat(paymentPlan.total / 100).toFixed(2)}
                    </Text>
                </Grid>
            </Grid>
            <Grid
                container
                px={[0, 0, undefined]}
                mt={"8px"}
                alignItems={"center"}
                justifyContent={"space-between"}
                flexWrap={"nowrap"}
            >
                <Grid item>
                    <Text size={mobileView ? "sm" : "md"} textweight={"regular"}>
                        {reviewData.depositPercent} % Deposit (due today)
                    </Text>
                </Grid>
                <Grid item>
                    <DisplayText
                        textHeight={mobileView ? "30px" : "32px"}
                        textsize={mobileView ? "20px" : undefined}
                        size={"xs"}
                        textweight={"bold"}
                    >
                        ${_formatMoney(paymentPlan && paymentPlan.deposit ? paymentPlan.deposit / 100 : 0)}
                    </DisplayText>
                </Grid>
            </Grid>
            <Grid container my={"20px"}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
            <Grid
                container
                px={[0, 0, undefined]}
                alignItems={"center"}
                style={{ cursor: "pointer" }}
                onClick={() => setOpenPaymentAccordian((prev) => !prev)}
                justifyContent={"space-between"}
            >
                <Grid item>
                    <Text
                        size={mobileView ? "sm" : "md"}
                        textweight={"semiBold"}
                        display={"flex"}
                        gap={"5px"}
                        alignItems={"center"}
                        textcolor={ColorTheme.secondary[1000]}
                    >
                        <img src={_url("assets/icons/calendar_star.svg")} /> ${moneyDisplay}/
                        <Trans>{frequency === "Weekly" ? "week" : "fortnight"}</Trans> for{" "}
                        {reviewData.plan === 1 ? paymentPlan.weeks : paymentPlan.fortnights} times
                    </Text>
                </Grid>
                <Grid item>
                    <IconButton>
                        {openPaymentAccordian ? (
                            <KeyboardArrowUpIcon
                                style={{
                                    color: ColorTheme.secondary[1000]
                                }}
                            />
                        ) : (
                            <KeyboardArrowDownIcon
                                style={{
                                    color: ColorTheme.secondary[1000]
                                }}
                            />
                        )}
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container px={[0, 0, undefined]}>
                <Grid item xs={12} mt={"24px"}>
                    {openPaymentAccordian && (
                        <PaymentStartDate
                            reviewData={reviewData}
                            setReviewData={setReviewData}
                            onUpdatePaymentPlan={onUpdatePaymentPlan}
                            paymentPlan={paymentPlan}
                            frequency={frequency}
                        />
                    )}
                </Grid>
            </Grid>

            {paymentPlan.discount && paymentPlan.discount.amount && (
                <Row className="discount">
                    <Col>
                        <span className="title">
                            {paymentPlan.discount.couponCode === ""
                                ? "Credit applied"
                                : `Discount Applied: '${paymentPlan.discount.couponCode}'`}
                            {reviewData.daysTillDeparture >= FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY && (
                                <FontAwesomeIcon icon="times" />
                            )}
                        </span>
                    </Col>
                    <Col xs="auto" className="price">
                        <span>
                            - ${paymentPlan.discount && parseFloat(paymentPlan.discount.amount / 100).toFixed(2)}
                        </span>
                    </Col>
                </Row>
            )}

            {paymentPlan.discount && paymentPlan.discount.amount && (
                <div className="title">
                    <Alert color="info">
                        ${parseFloat((balance - paymentPlan.discount.amount) / 100).toFixed(2)} credit remaining after
                        this booking.
                    </Alert>
                </div>
            )}
        </>
    );
};
