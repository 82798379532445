/* eslint-disable unused-imports/no-unused-imports */
import { Box } from "@mui/material";
import { format } from "date-fns";
import moment from "moment/moment";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY } from "../../constants";
import { logAmplitudeEvent } from "../../utils/SiteUtils";
import AirportCityTypeahead from "../AirportCityTypeahead";
import BasicDateRangePicker from "../common/datepicker/MuiDateRangePicker";
import MuiSingleDatePicker from "../common/datepicker/MuiSingleDatePicker";
import FlightDropdownPassenger from "./FlightDropdownPassenger";

const SingleDestinationControls = ({
    auth,
    searchQuery,
    balance,
    updateLocation,
    setSearchQuery,
    onFlightSearch,
    setForm,
    isLogAmplitude
}) => {
    const flightsQuery = searchQuery.flights;
    const { isAuthenticated } = auth;

    const renderFlightPassengerForm = (query) => (
        <Box mt={["10px", "0px"]}>
            <FlightDropdownPassenger
                adults={query.adults}
                child={query.children}
                infants={query.infants}
                cabinClass={query.cabinClass || "Y"}
                onChangePassenger={(e) => {
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...query,
                            adults: e.adults,
                            children: e.child,
                            infants: e.infants,
                            cabinClass: e.cabinClass
                        }
                    }));
                    setForm((prev) => ({
                        ...prev,
                        adults: {
                            isTouched: true
                        }
                    }));
                }}
                onChangeAdults={(e) => {
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...query,
                            adults: e
                        }
                    }));
                    setForm((prev) => ({
                        ...prev,
                        adults: {
                            isTouched: true
                        }
                    }));
                }}
                onChangeChildren={(e) => {
                    console.log("children", e);
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...query,
                            children: e
                        }
                    }));
                }}
                onChangeInfants={(e) => {
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...query,
                            infants: e
                        }
                    }));
                }}
            />
        </Box>
    );

    return (
        <div className="SearchForm__flights">
            <Row>
                <Col>
                    <div className="SearchForm__flightsSingleDestination">
                        <AirportCityTypeahead
                            id="origin"
                            placeholder="Add location"
                            fieldName="origin"
                            updateLocation={({ field, location }) => {
                                updateLocation({ field, location });
                                if (location & isLogAmplitude) {
                                    logAmplitudeEvent("departureAirportSelected", {
                                        airportCode: location.id,
                                        country: location.country,
                                        city: location.city
                                    });
                                }
                            }}
                            selected={flightsQuery.origin ? [flightsQuery.origin] : null}
                            label="Leaving from"
                        />
                        <AirportCityTypeahead
                            id="destination"
                            fieldName="destination"
                            placeholder="Add location"
                            updateLocation={({ field, location }) => {
                                updateLocation({ field, location });
                                if (location && isLogAmplitude) {
                                    logAmplitudeEvent("destinationAirportSelected", {
                                        airportCode: location.id,
                                        country: location.country,
                                        city: location.city
                                    });
                                }
                            }}
                            label="Going to"
                            selected={flightsQuery.destination ? [flightsQuery.destination] : null}
                        />
                        {flightsQuery.type === "return" && (
                            <BasicDateRangePicker
                                startDateLabel="Depart"
                                endDateLabel="Return"
                                startDate={flightsQuery.departureDate ? moment(flightsQuery.departureDate) : null}
                                endDate={flightsQuery.returnDate ? moment(flightsQuery.returnDate) : null}
                                handleDateChange={({ startDate, endDate }) => {
                                    if (startDate && endDate && isLogAmplitude) {
                                        logAmplitudeEvent("departureDateEntered", {
                                            year: format(new Date(startDate), "yyyy"),
                                            month: format(new Date(startDate), "MM"),
                                            date: format(new Date(startDate), "dd"),
                                            day: format(new Date(startDate), "eeee")
                                        });
                                        logAmplitudeEvent("returnDateEntered", {
                                            year: format(new Date(endDate), "yyyy"),
                                            month: format(new Date(endDate), "MM"),
                                            date: format(new Date(endDate), "dd"),
                                            day: format(new Date(endDate), "eeee")
                                        });
                                    }
                                    setSearchQuery((prev) => ({
                                        ...prev,
                                        flights: {
                                            ...prev.flights,
                                            departureDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                                            returnDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null
                                        }
                                    }));
                                }}
                                maximumDate={moment().add("11", "months")}
                                minimumDate={
                                    isAuthenticated && balance > 0
                                        ? moment().add("3", "days")
                                        : moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days")
                                }
                            />
                        )}
                        {flightsQuery.type === "one-way" && (
                            <MuiSingleDatePicker
                                date={flightsQuery.departureDate ? moment(flightsQuery.departureDate) : null}
                                label="Depart"
                                handleDateChange={(startDate) => {
                                    setSearchQuery((prev) => ({
                                        ...prev,
                                        flights: {
                                            ...prev.flights,
                                            departureDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                                            returnDate: null
                                        }
                                    }));
                                }}
                                maximumDate={moment().add("11", "months")}
                                minimumDate={
                                    isAuthenticated && balance > 0
                                        ? moment().add("3", "days")
                                        : moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days")
                                }
                            />
                        )}
                        {renderFlightPassengerForm(flightsQuery)}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={onFlightSearch} type="button" className="SearchForm__submit btn-search">
                        Search flights
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default SingleDestinationControls;
