import { faAngleDown } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { FlightDetails } from "../../../components";
import ShowChangedFlightPrice from "../../../components/search/form/ShowChangedFlightPrice";
import { renderFlightSegments } from "../../../components/search/results/SearchResultHooks";
import { _url } from "../../../config/utils";
import { logAmplitudeEvent } from "../../../utils/SiteUtils";
import { ChangesAndCancellation } from "../components/ChangesAndCancellation";
import { CurrentFlightPrice } from "../components/CurrentFlightPrice";
import { MobileChangesAndCancellation } from "../components/MobileChangesAndCancellation";
import { FlightResultBadgeContainer } from "../components/style";

export const FlightSearchResultBox = ({
    searchResult,
    noSelect,
    currentFlightPrice,
    type,
    onSelect,
    showMinDepositPerTag = false,
    lowestWeekPaymentTag = false
}) => {
    const [detailOpen, setDetailOpen] = React.useState(false);
    const toggleDetailOpen = () => {
        setDetailOpen(!detailOpen);
        !detailOpen && logAmplitudeEvent("flightAndBaggageInfoExpanded");
    };

    return (
        <div
            key={`result-box-${searchResult.guid}-${Math.random()}`}
            className={classNames("search-result-box", { "no-select": noSelect })}
        >
            <div className={classNames("search-result", { "detail-open": detailOpen })}>
                <Row>
                    <Col className="left-content">
                        <Row>
                            <Box>
                                <Box mb={"10px"} display={"flex"} gap={"10px"}>
                                    {showMinDepositPerTag && (
                                        <FlightResultBadgeContainer>Lowest Deposit</FlightResultBadgeContainer>
                                    )}
                                    {lowestWeekPaymentTag && (
                                        <FlightResultBadgeContainer>Lowest Installments</FlightResultBadgeContainer>
                                    )}
                                </Box>
                                {renderFlightSegments(searchResult)}
                            </Box>
                            <Col xs={12} className="search-result-box__controls" style={{ marginTop: 15 }}>
                                <div className="flight-details-toggle">
                                    <div className="content-icon" onClick={toggleDetailOpen}>
                                        Flight and baggage info
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </div>
                                </div>
                                {!noSelect && <ChangesAndCancellation searchResult={searchResult} />}
                            </Col>
                        </Row>
                    </Col>
                    {!noSelect && (
                        <Col md="auto" className="right-content">
                            {currentFlightPrice ? (
                                <ShowChangedFlightPrice
                                    searchResult={searchResult}
                                    currentFlightPrice={currentFlightPrice}
                                />
                            ) : (
                                <CurrentFlightPrice searchResult={searchResult} />
                            )}
                            <div>
                                <Button onClick={() => onSelect(searchResult.id)} className="btn-select">
                                    Select
                                    <img alt="left-arrow" src={_url("assets/images/left-arrow.png")} />
                                </Button>

                                <MobileChangesAndCancellation searchResult={searchResult} />
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
            {detailOpen && (
                <FlightDetails
                    itinerary={searchResult}
                    flights={searchResult.itineraries}
                    type={type}
                    key={`result-details-${searchResult.guid}-${Math.random()}`}
                />
            )}
        </div>
    );
};
