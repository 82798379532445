import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../Footer";

const AuPrivacyPolicy = () => (
    <div>
        <Helmet>
            <title>Privacy Policy | Book Now, Pay Later</title>
        </Helmet>
        <div className="term-content">
            <div className="container">
                <h1 className="title">Privacy Policy</h1>
                <p>
                    Sky Pay Group Pty Ltd (ACN 621 979 431) trading as Pay Later Travel is committed to providing
                    quality services to you and this policy outlines our ongoing obligations to you in respect of how we
                    manage your Personal Information. We have adopted the Australian Privacy Principles (APPs) contained
                    in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the way in which we collect, use,
                    disclose, store, secure and dispose of your Personal Information. A copy of the Australian Privacy
                    Principles may be obtained from the website of The Office of the Australian Information Commissioner
                    at{" "}
                    <a href="www.aoic.gov.au" target="blank">
                        www.aoic.gov.au
                    </a>
                </p>
                <p>
                    This policy (together with our Terms & Conditions and any other documents referred to on it) sets
                    out the basis on which any personal data we collect from you, or that you provide to us, will be
                    processed by us. Please read the following carefully to understand our views and practices regarding
                    your personal data and how we will treat it. By using Pay Later Travel (“our Website/Site”) you are
                    accepting and consenting to the practices described in this policy.
                </p>
                <div className="block-content">
                    <h2>1. Personal Information</h2>
                    <ol type={"a"}>
                        <li>
                            Personal Information means any information from which your identity is apparent or can be
                            reasonably ascertained.
                        </li>
                    </ol>
                </div>
                <div className="block-content">
                    <h2>2. Purposes</h2>
                    <ol type={"a"}>
                        <li>
                            We may collect personal information including your name, age, gender, username, e-mail
                            address contact details, residential address, date of birth, geographic location, passport
                            information, credit card information, details of products or services you have purchased,
                            payment details as well as details of your interactions with us.
                        </li>
                        <li>Your data may be used for the following purposes: </li>
                        <ol type={"i"}>
                            <li>
                                In the course of providing you with the Services or access to the Website, we will be
                                collecting, using, disclosing, storing and/or processing data, including your personal
                                data.
                            </li>
                            <li>
                                To communicate with you regarding your bookings, including sending you booking
                                confirmations, changes in bookings, availability of services, and force majeure
                                circumstances.
                            </li>
                            <li>
                                To respond to your queries, feedback, claims or disputes, whether directly or through
                                our outsourced customer service agents.
                            </li>
                            <li>
                                Credit or other payment card verification/screening; and payment information for
                                accounting, billing and audit purposes and to detect or prevent any fraudulent
                                activities.
                            </li>
                            <li>
                                To compare information, and verify with third parties in order to ensure that the
                                information is accurate.
                            </li>
                            <li>To ascertain your identity for fraud detection purposes.</li>
                            <li>To administer your account (if any) with us.</li>
                            <li>
                                To verify and carry out financial transactions in relation to payments you make online.
                            </li>
                            <li>
                                To improve the layout or content of the pages of the Website and customize them for
                                users.
                            </li>
                            <li>To carry out research on our users’ demographics and behaviour.</li>
                            <li>
                                To provide you with information we think you may find useful or which you have requested
                                from us, including information about our or third party sellers’ products and services,
                                provided you have indicated that you have not objected to being contacted for these
                                purposes.
                            </li>
                            <li>To display your name, username or profile on the Website.</li>
                            <li>
                                To process any complaints, feedback, enforcement action and take-down requests in
                                relation to any content you have uploaded to the Website.
                            </li>
                            <li>
                                To derive further attributes relating to you based on personal data provided by you
                                (whether to us or third parties), in order to provide you with more targeted and/or
                                relevant information.
                            </li>
                            <li>
                                Administrative or legal purposes: we use your data for statistical and marketing
                                analysis, systems testing, customer surveys, maintenance and development, or in order to
                                deal with a dispute or claim. We may perform data profiling based on the data we collect
                                from you for statistical and marketing analysis purposes, but only with your prior
                                consent, and by making best endeavours to ensure that all data it is based on is
                                accurate. By providing any personal data you explicitly agree that we may use it to
                                perform profiling activities in accordance with this privacy policy.
                            </li>
                            <li>
                                Security, administrative, crime prevention/detection: we may pass your information to
                                government authorities or enforcement bodies for compliance with legal requirements.
                            </li>
                            <li>
                                Customer service communications: we use your data to manage our relationship with you as
                                our customer and to improve our services and enhance your experience with us.
                            </li>
                            <li>
                                Provide tailored services: we use your data to provide information we believe is of
                                interest to you, prior to, during, and after your interactions with us, and to
                                personalise the services we offer to you, such as special offers.
                            </li>
                            <li>
                                Personal information might be shared to government agencies and airlines in case there
                                is a risk of COVID-19 transmission.
                            </li>
                        </ol>
                        <li>
                            Information we collect about you. With regard to each of your visits to our site we may
                            automatically collect the following information:{" "}
                        </li>
                        <ol type={"i"}>
                            <li>
                                Technical information, including the Internet protocol (“IP”) address used to connect
                                your computer to the Internet, your login information, browser type and version, time
                                zone setting, browser plug-in types and versions, operating system and platform.
                            </li>
                            <li>
                                Information about your visit, including the full Uniform Resource Locators (“URL”)
                                clickstream to, through and from our site (including date and time); products you viewed
                                or searched for; page response times, download errors, length of visits to certain
                                pages, page interaction information (such as scrolling, clicks, and mouse-overs), and
                                methods used to browse away from the page and any phone number used to call our customer
                                service number.
                            </li>
                        </ol>
                        <li>
                            Information we receive from other sources. We may receive information about you if you use
                            any of the other websites we operate or the other services we provide. We are also working
                            closely with third parties (including, for example, business partners, sub-contractors in
                            technical, payment and delivery services, advertising networks, analytics providers, search
                            information providers, credit reference agencies) and may receive information about you from
                            them.
                        </li>
                        <li>
                            We will ordinarily provide you with an explanation as to why we are collecting your personal
                            information at the time that we collect it. Sometimes, we are required by law to collect
                            your personal information, for example, to comply with our legislative or legal obligations.
                            While there may be some circumstances where it will be possible for you to interact with us
                            on an anonymous basis or using an alias, these are rare and we will generally need to know
                            who you are in order to comply with our legal obligations and to assist you.
                        </li>
                        <li>
                            We will only process your personal data where we have a legal basis to do so, which will
                            depend on the reasons for which we have collected and need to use your personal data.
                        </li>
                        <li>
                            In most cases, we will need to process your personal data so that we can enter into our
                            contract and fulfil the provision or delivery of goods or services to you.
                        </li>
                        <li>
                            We will not retain your data for longer than is necessary to fulfil the purpose for which it
                            is being processed. To determine the appropriate retention period, we consider the amount,
                            nature and sensitivity of the personal data, the purposes for which we process it, and
                            whether we can achieve those purposes through other means.
                        </li>
                    </ol>
                </div>
                <div className="block-content">
                    <h2>3. Disclosure</h2>
                    <ol type="a">
                        <li>
                            We do not disclose personal information to third parties unless we are permitted or required
                            to do so by law, or if you have given us your consent to do so.
                        </li>
                        <li>
                            We may share your personal data with the following third parties for the purpose described
                            in this privacy policy:
                        </li>
                        <ol type={"i"}>
                            <li>
                                Government authorities, law enforcement bodies and regulators for compliance with legal
                                requirements.
                            </li>
                            <li>
                                Other companies, contractors or agents to provide services to you including delivery,
                                marketing, or marketing platform providers, communications, legal services, debt
                                collection, administration services, customer services, information technology
                                providers, credit card or other payment methods to conduct transactions.
                            </li>
                            <li>
                                Credit and debit card companies which facilitate your payments to us, and for anti-fraud
                                screening, which may need information about your method of payment to process payment or
                                ensure the security of your payment transaction. Before entering your personal details
                                we suggest that you read and become familiar with the privacy policy for any such
                                third-party provider.
                            </li>
                            <li>
                                Legal and other professional advisers, law courts and law enforcement bodies in
                                countries in which we operate, in order to enforce our legal rights in relation to our
                                contract with you.
                            </li>
                        </ol>
                    </ol>
                </div>
                <div className="block-content">
                    <h2>4. Cookies</h2>
                    <ol type={"a"}>
                        <li>
                            We may collect data from other sources which may not always be obvious, such as through the
                            use of “cookies”. We may also gather information from both online and offline data
                            providers. This information could include internet browsing behaviour, demographic data or
                            interest-based data.
                        </li>
                        <li>
                            Cookies are a small text file placed on your computer by our website provider. We use
                            cookies when you visit our website so we can improve your user experience. To opt-out of
                            cookies, you can alter the settings on your internet browser to accept or reject a website
                            from using cookies. This may affect the functionality of the website. Some third parties may
                            use cookies and other technologies. We recommend that you read their privacy policies or
                            policies relating to the use of cookies and technology.
                        </li>
                    </ol>
                </div>
                <div className="block-content">
                    <h2>5. Choice OR opt-out</h2>
                    <ol type={"a"}>
                        <li>
                            We give you the choice regarding the collection and usage of your personally identifiable
                            information. Again, you don't need to register to access and use our Site. You may,
                            therefore, choose to opt-out of providing such information.
                        </li>
                        <li>
                            Further, once you are registered at the site, you will have the option at any stage to
                            inform us that you no longer wish to receive future e-mails and you may "unsubscribe" by
                            contacting on support@paylatertravel.com.
                        </li>
                    </ol>
                </div>
                <div className="block-content">
                    <h2>6. Third-Party Links</h2>
                    <ol type={"a"}>
                        <li>
                            Our Website may contain links to external websites. Please be aware that we are not
                            responsible for the privacy practices of such other sites. When you go to other websites
                            from here, we advise you to be aware and read their privacy policy.
                        </li>
                    </ol>
                </div>
                <div className="block-content">
                    <h2>7. Spam Act of 2003</h2>
                    <ol type={"a"}>
                        <li>
                            Legislation in Australia prohibits the sending of unsolicited commercial electronic
                            messages. We will never knowingly send you messages or notices electronically without your
                            consent.
                        </li>
                    </ol>
                </div>
                <div className="block-content">
                    <h2>8. Access and Correction of Information</h2>
                    <ol type={"a"}>
                        <li>
                            You may request access to Personal Information about you that we hold and you may ask us to
                            correct your Personal Information if you find that it is not accurate, up-to-date or
                            complete. You may also make a complaint about our handling of your Personal Information.
                        </li>
                        <li>
                            You will not have to pay a fee to access your personal information (or to exercise any of
                            the other rights). However, we may charge a reasonable fee if your request for access is
                            clearly completely unfounded or excessive. Alternatively, we may refuse to comply with the
                            request in such circumstances.
                        </li>
                        <li>
                            To protect your privacy and the privacy of others, we will need evidence of your identity
                            before we can grant you access to information about you or change it.
                        </li>
                        <li>
                            If possible, we will contact you to notify you if any of your sensitive data has been
                            subject to a data breach. If we are unable to contact effected individuals, we will post
                            notices on our website.
                        </li>
                    </ol>
                </div>
                <div className="block-content">
                    <h2>9. Report of Data Breaches</h2>
                    <ol type={"a"}>
                        <li>
                            The Privacy Act requires anyone who collects sensitive information to assess any data
                            breaches and report to the Office of the Australian Information Commissioner and to the
                            individuals to whom the information relates.
                        </li>
                        <li>
                            A data breach will occur where there has been unauthorised access, modification, disclosure,
                            or other misuse or interference of sensitive data that may pose the risk of serious harm to
                            the affected individual.
                        </li>
                        <li>
                            If you or someone you know suspect there has been a data breach, please contact us so we can
                            assess.
                        </li>
                        <li>
                            If possible, we will contact you to notify you if any of your sensitive data has been
                            subject to a data breach. If we are unable to contact effected individuals, we will post
                            notices on our website.
                        </li>
                    </ol>
                </div>
                <div className="block-content">
                    <h2>10. Contact us</h2>
                    <ol type={"a"}>
                        <li>
                            If you have any concerns please regarding your privacy or wish to discuss this policy,
                            please contact us at{" "}
                            <a href="mailto:support@paylatertravel.com">support@paylatertravel.com</a>
                        </li>
                        <li>
                            We will endeavour to respond to all privacy queries, corrections and complaints promptly.
                            Sometimes this may mean we will need to assess and investigate your request.
                        </li>
                        <li>
                            We may update this Privacy Policy from time to time to take into account changes in our
                            practices for the handling of personal information. We do so by publishing amended Privacy
                            Policies on our website. You should regularly review the most recent version available
                            online. You can contact us if you have difficulties accessing our policy.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <Footer />
    </div>
);

export default AuPrivacyPolicy;
