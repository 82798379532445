import { useEffect } from "react";

const FaqUs = () => {
    useEffect(() => {
        window.location.href = "https://help.paylatertravel.com/";
    }, []);

    return <>Loading..</>;
};
export default FaqUs;
