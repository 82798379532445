import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Footer } from "../../components";
import EditSearch from "../../components/EditSearch";
import { _url } from "../../config/utils";
import { FLIGHT_BEST_SORT } from "../../constants";
import { clearCarriersFilter, updateSearchFlights, updateSearchLoading } from "../../redux/modules/search";
import { triggerFlightSearchPageLoadedEvent } from "../../utils/events";
import { FlightFilters } from "./FlightFilters";
import { FlightLoading } from "./FlightLoading";
import { useFiltereredFlights } from "./Hooks";
import {
    getFlightSearchList,
    onFlightSelect,
    onShowMoreResults,
    onToggleArrivalFilter,
    onToggleCarriersFilter,
    onToggleCarriersStopFilter,
    onToggleDepartureFilter
} from "./Utils";
import { SearchResultCount } from "./components/SearchResultCount";
import { ShowMoreResults } from "./components/ShowMoreResults";
import { FlightSearchResultBox } from "./result/FlightSearchResultBox";

const SearchPage = ({ query }) => {
    const navigate = useNavigate();
    const [searchState, setSearchState] = useState({
        filterToggled: false,
        numflightsVisible: 20,
        sortBy: FLIGHT_BEST_SORT
    });
    const dispatch = useDispatch();
    const searchQuery = useSelector((state) => state.search.query.flights);
    const searchResult = useSelector((state) => state.search.results);
    const loading = useSelector((state) => state.search.loading);
    const booking = useSelector((state) => state.bookings.detail);
    const auth = useSelector((state) => state.auth);
    const flights = searchResult.data;
    const { origin, destination, departureDate, returnDate, ...urlMultiCityLegs } = useParams();
    const locationInfo = useLocation();
    const minDepositFlight = flights.sort((a, b) => a.deposit - b.deposit)?.[0]?.deposit;
    const lowestWeekPayment = flights.sort((a, b) => a.pricePerWeek - b.pricePerWeek)?.[0]?.pricePerWeek;

    useEffect(() => {
        triggerFlightSearchPageLoadedEvent();
        if (query) {
            // if we supply a query via props (i.e. like the change flights page)
            // supply included carrier codes for change flights edit search
            const flightSearchQuery = query;
            const allCarriers = booking.lines[0].product.itineraries.map(
                (itinerary) => itinerary.segments.map((segment) => segment.carrierCode)[0]
            );
            const carriers = [...new Set(allCarriers)];
            flightSearchQuery.includedAirlineCodes = carriers.toString();
            dispatch(updateSearchLoading());
            dispatch(updateSearchFlights(flightSearchQuery, false));
        } else {
            dispatch(
                getFlightSearchList({
                    locationInfo,
                    origin,
                    destination,
                    urlMultiCityLegs,
                    searchQuery,
                    returnDate,
                    departureDate,
                    auth
                })
            );
        }
    }, []);

    useEffect(() => {
        setSearchState((prev) => ({
            ...prev,
            sortBy: FLIGHT_BEST_SORT
        }));
    }, [searchResult]);

    const onSelect = (id, index) => {
        onFlightSelect(dispatch, navigate, searchResult, id, index);
    };

    const toggleCarriersFilter = (key) => {
        onToggleCarriersFilter(dispatch, key, searchResult);
    };

    const toggleStopFilter = (key) => {
        onToggleCarriersStopFilter(dispatch, key, searchResult);
    };

    const toggleDepartureFilter = (key) => {
        onToggleDepartureFilter(dispatch, key, searchResult);
    };

    const toggleArrivalFilter = (key) => {
        onToggleArrivalFilter(dispatch, key, searchResult);
    };

    const showMoreResults = () => {
        onShowMoreResults(searchState, setSearchState);
    };

    const filteredFlights = useFiltereredFlights(flights, searchResult, searchState.sortBy);
    if (loading) {
        return (
            <div>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={11} sm={8}>
                        <FlightLoading />
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <Helmet>
                <title>Search | Pay Later Travel</title>
            </Helmet>
            <div className="page-content" style={{ background: "#efefef" }}>
                <div className="content-search">
                    <Container
                        style={{
                            maxWidth: 1140
                        }}
                        fluid="xl"
                    >
                        <EditSearch productType="flights" searchQuery={searchQuery} />
                    </Container>
                    {flights.length > 0 && (
                        <div className="search-results">
                            <Container fluid="xl">
                                <Row>
                                    <Col className="filters-wrapper" md={12} lg="auto">
                                        {!loading && (
                                            <FlightFilters
                                                searchResult={searchResult}
                                                searchState={searchState}
                                                setSearchState={setSearchState}
                                                onClearFilter={() => dispatch(clearCarriersFilter())}
                                                toggleCarriersFilter={toggleCarriersFilter}
                                                toggleStopFilter={toggleStopFilter}
                                                toggleDepartureFilter={toggleDepartureFilter}
                                                toggleArrivalFilter={toggleArrivalFilter}
                                                showReturnFilter={!!returnDate}
                                            />
                                        )}
                                    </Col>
                                    <Col className="content">
                                        <SearchResultCount
                                            flights={flights}
                                            filteredFlights={filteredFlights}
                                            dispatch={dispatch}
                                            setSortOrder={(order) => {
                                                setSearchState((prev) => ({
                                                    ...prev,
                                                    sortBy: order
                                                }));
                                            }}
                                            sortOrder={searchState.sortBy}
                                        />
                                        <div className="content-search-result">
                                            {flights &&
                                                filteredFlights
                                                    .slice(0, searchState.numflightsVisible)
                                                    .map((result, index) => (
                                                        <FlightSearchResultBox
                                                            key={`${result.guid}-${Math.random()}`}
                                                            searchResult={result}
                                                            showMinDepositPerTag={minDepositFlight === result.deposit}
                                                            lowestWeekPaymentTag={
                                                                lowestWeekPayment === result.pricePerWeek
                                                            }
                                                            type={searchQuery.type}
                                                            onSelect={(id) => onSelect(id, index)}
                                                        />
                                                    ))}
                                        </div>
                                        <ShowMoreResults
                                            flights={flights}
                                            filteredFlightsCount={filteredFlights?.length || 0}
                                            visibleFlightCount={searchState.numflightsVisible}
                                            showMoreResults={showMoreResults}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    )}
                </div>
            </div>
            {flights && flights.length === 0 && searchResult.errors && (
                <div style={{ textAlign: "center", paddingTop: "100px", paddingBottom: "80px" }}>
                    <p style={{ fontWeight: "600" }}>No results found - try another search!</p>
                    <img alt="search" style={{ maxWidth: "100%" }} src={_url("assets/images/searchplane.png")} />
                </div>
            )}
            {flights && flights.length === 0 && searchResult.errors && (
                <div
                    className="city-footer-image"
                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/cityfooter.png)` }}
                />
            )}
            <Footer />
        </div>
    );
};

export default SearchPage;
