import { useMemo } from "react";
import { FLIGHT_BEST_SORT } from "../../constants";
import { ArrivalTimeOptions, DepartureTimeOptions } from "./Utils";

const filterMap = {
    airlines: {
        filterFn: (airlineCodes) => {
            if (airlineCodes && airlineCodes.length > 0) {
                return (itinerary) => {
                    if (
                        itinerary.carriers.length === 1 &&
                        (airlineCodes.length === 0 || airlineCodes.includes(itinerary.carriers[0]))
                    ) {
                        return true;
                    }
                    if (
                        itinerary.carriers.length > 1 &&
                        (airlineCodes.length === 0 || airlineCodes.includes("COMBINATIONS"))
                    ) {
                        return true;
                    }
                    return false;
                };
            }
            return () => true;
        }
    },
    departureTime: {
        filterFn: (departureTimeIds) => {
            if (!departureTimeIds || departureTimeIds.length === 0) {
                return () => true;
            }
            const timeRangeArray = departureTimeIds.map(
                (departureTimeId) => DepartureTimeOptions.find(({ id }) => id === departureTimeId)?.timeRange
            );
            return (itinerary) => {
                const itineraries = itinerary.itineraries[0];
                const firstSegmentDepartureTime = itineraries.segments[0].departureAt;
                const departureHour = parseInt(firstSegmentDepartureTime.split("T")[1].split(":")[0]);
                return !!timeRangeArray.find(
                    (timeRange) => timeRange.startTime <= departureHour && timeRange.endTime > departureHour
                );
            };
        }
    },
    arrivalTime: {
        filterFn: (arrivalTimeIds) => {
            if (!arrivalTimeIds || arrivalTimeIds.length === 0) {
                return () => true;
            }
            const timeRangeArray = arrivalTimeIds.map(
                (arrivalTimeId) => ArrivalTimeOptions.find(({ id }) => id === arrivalTimeId)?.timeRange
            );
            return (itinerary) => {
                const segments = itinerary.itineraries[itinerary.itineraries.length - 1].segments;
                const lastSegmentArrivalTime = segments[0].departureAt;
                const arrivalHour = parseInt(lastSegmentArrivalTime.split("T")[1].split(":")[0]);
                return !!timeRangeArray.find(
                    (timeRange) => timeRange.startTime <= arrivalHour && timeRange.endTime > arrivalHour
                );
            };
        }
    },
    stops: {
        filterFn: (stops) => {
            if (!stops || stops.length === 0) {
                return () => true;
            }
            if (stops.includes("2")) {
                stops = [...stops, "3", "4", "5", "6", "7", "8", "9", "10"];
            }

            return (itinerary) => {
                if (stops.includes("0") && stops.length === 1) {
                    return itinerary.itineraries.every((itineraryObj) => itineraryObj.stops === 0);
                }
                return stops.includes((itinerary.itineraries[0].stops || 0).toString());
            };
        }
    }
};

export const useFiltereredFlights = (flights, searchResult, sortOrder) =>
    useMemo(() => {
        if (!flights) {
            return [];
        }
        let filteredSearchResults = flights;
        filteredSearchResults = filteredSearchResults.filter(
            filterMap.airlines.filterFn(searchResult.carriersVisibilityFilter)
        );
        filteredSearchResults = filteredSearchResults.filter(filterMap.stops.filterFn(searchResult.carrierStopFilter));
        filteredSearchResults = filteredSearchResults.filter(
            filterMap.departureTime.filterFn(searchResult.carrierDepartureFilter)
        );
        filteredSearchResults = filteredSearchResults.filter(
            filterMap.arrivalTime.filterFn(searchResult.carrierArrivalFilter)
        );
        return sortOrder === FLIGHT_BEST_SORT
            ? filteredSearchResults.sort((a, b) => a.rankingScore - b.rankingScore)
            : filteredSearchResults.sort((a, b) => a.totalCompletePrice - b.totalCompletePrice);
    }, [flights, searchResult, sortOrder]);
