import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Collapse } from "reactstrap";

const Toggle = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="Toggle">
            <div className="Toggle__header" onClick={toggle}>
                <h3>{props.title}</h3>
                <FontAwesomeIcon className="Toggle__icon" icon={isOpen ? "angle-up" : "angle-down"} />
            </div>
            <Collapse isOpen={isOpen} className="Toggle__content">
                {props.children}
            </Collapse>
        </div>
    );
};

export default Toggle;
