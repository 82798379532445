import { Box } from "@mui/material";
import moment from "moment";
import React from "react";
import { renderDetailFlight } from "../../../components/search/results/SearchResultHooks";
import { _formatMoney } from "../../../config/utils";
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY } from "../../../constants";
import { UpfrontContainer } from "./style";

export const CurrentFlightPrice = ({ searchResult }) => {
    const daysTillDeparture = searchResult
        ? moment(searchResult.itineraries[0].segments[0].departureAt.split("T")[0]).diff(moment(), "days") + 1
        : 0;
    return (
        <div>
            {renderDetailFlight(searchResult)}
            <p className="title">from</p>
            {daysTillDeparture < FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY ? (
                <p className="pw">
                    ${_formatMoney(searchResult.totalCompletePrice / 100)}
                    <sup>{searchResult.currency.slice(0, -1)}</sup>
                </p>
            ) : (
                <div>
                    <p className="pw">
                        ${_formatMoney(searchResult.pricePerWeek / 100)}
                        <sup>{searchResult.currency.slice(0, -1)}</sup>
                        /wk
                    </p>
                    <p className="total">
                        ${_formatMoney(searchResult.totalCompletePrice / 100)} total
                        <sup>{searchResult.currency.slice(0, -1)}</sup>
                    </p>
                    <Box display={"flex"} justifyContent={"center"}>
                        <UpfrontContainer>Deposit: ${_formatMoney(searchResult.deposit / 100)}</UpfrontContainer>
                    </Box>
                </div>
            )}
        </div>
    );
};
