import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { BASE_PATH } from "./config";
import { App } from "./containers";
import configureStore from "./redux/store";
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/tippy.css";
import "./lang/i18n";
import "./styles/index.scss";

export const { store, persistor } = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename={BASE_PATH}>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);
