import InternalApi from "../../config/internalApi";

export const australianStates = {
    QLD: "Queensland",
    NSW: "New South Wales",
    WA: "Western Australia",
    VIC: "Victoria",
    SA: "South Australia",
    TAS: "Tasmania",
    NT: "Northern Territory",
    ACT: "Australian Capital Territory"
};

export const getHotelDestination = async () => {
    return InternalApi.get("/v2/hotel-destinations").then((response) => {
        return Promise.resolve(response.data);
    });
};

export const formatHotelDestination = (destination) =>
    destination.includes("_") ? `${destination.split("_")[0]}, ${destination.split("_")[1].replace("/", "")}` : "";
