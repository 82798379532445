import React from "react";

const ReviewSupportUs = () => {
    return (
        <div>
            <p className="text-center">
                Have questions? Contact us via chat or call us on
                <a href="tel:+1 888 974 0329">+1 (888) 974 0329.</a>
            </p>
        </div>
    );
};

export default ReviewSupportUs;
