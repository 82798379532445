export const IS_USA_SITE = [
    "www.paylatertravel.com", // production
    "www.paylatertravel.dev", // development / test
    "www.staging.paylatertravel.dev", // staging
    "www.preprod.paylatertravel.dev", // preprod
    "localhost"
].includes(window.location.hostname); // removed .replace("www.", "")
export const FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY = 28;
export const TRAVEL_INSURANCE_LINK_US =
    "https://www.agentmaxonline.com/agentmaxweb/storefront/index.html#/home/?emaillinkcode=ABIYU4TLWGBGTNHC6ZWLRSKAR7AIBWE33AAW7OYIPBPWYZZAHMNFAPHPMCRSOIHOT2A76BONGXKQL73NZ75RJQPPH5HHTT7WBY42JRA%3d";
export const TRAVEL_INSURANCE_LINK_AU =
    "https://www.agentmaxonline.com/agentmaxweb/storefront/index.html#/home/?emaillinkcode=ABIYU4TLWGBGTNHC6ZWLRSKAR7AIBWE33AAW7OYIPBPWYZZAHMNFAPHPMCRSOIHOT2A76BONGXKQL73NZ75RJQPPH5HHTT7WBY42JRA%3d";
export const HOTEL_LINK_US = "https://www.booking.com/index.html?aid=1826066";
export const HOTEL_LINK_AU = "https://www.booking.com/index.html?aid=1826066";
export const CAR_RENTAL_LINK_AU = "https://www.driveaway.com.au/affiliates/1014025";
export const CAR_RENTAL_LINK_US = "https://www.gotrentalcars.com/us-label/?agence_id=5890";
export const CHANGES_ELIGIBILITY_DATE = "2021-11-12";
export const CANCELLATIONS_ELIGIBILITY_DATE = "2021-12-03";
export const MAX_HOTEL_LIST = 30;
export const STAYS_MIN_DAYS_BEFORE_BOOKING = 14;
export const LOGIN_URL = "plt-web/auth/LOGIN";
export const STAYS_2_0 = true;
export const STAYS_2_0_API = false;
export const EDIT_TRAVELLERS = false;
export const WISTIA_EMBED_ID = "96qxzhtajy";
export const STAYS_NEW_STICKER = true;
export const BOOKING_RECOMMENDED_STAYS_LIST = true;
export const MUI_LICENSE_KEY =
    "922af3b2e61bd63dbac6098f405590c3Tz02MTM0NyxFPTE3MDk3MDQ4MTcxNDUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";
export const FLIGHT_BEST_SORT = "BEST";
export const FLIGHT_CHEAPEST_SORT = "PRICE_LOW_TO_HIGH";
export const FLIGHT_DEPOSIT_SORT = "DEPOSIT_LOW_TO_HIGH";
export const FLIGHT_WEEKLY_SORT = "WEEKLY_LOW_TO_HIGH";
export const AU_TRUSTPILOT_URL = "https://au.trustpilot.com/review/paylatertravel.com";
export const US_TRUSTPILOT_URL = "https://www.trustpilot.com/review/paylatertravel.com";
export const AU_BOOKING_REVIEW = "https://au.trustpilot.com/evaluate/paylatertravel.com";
export const US_BOOKING_REVIEW = "https://www.trustpilot.com/evaluate/paylatertravel.com";
export const SHOW_CABIN_CLASS = true;
export const FEATURE_FLAG_MOBILE_NEW_DATEPICKER = false;
export const SHOW_FLIGHT_SEARCH_NEW_DESIGN = true;
export const SHOW_REDESIGN_NAVBAR = ["/", "s"];
