import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BlockIcon from "@mui/icons-material/Block";
import React from "react";
import { Trans } from "react-i18next";

export const MobileChangesAndCancellation = ({ searchResult }) => (
    <>
        <span className="no-change-fees d-block d-md-none">
            {searchResult.itineraries[0].changesPermitted ? (
                <AttachMoneyIcon style={{ fontSize: 16, marginBottom: 2 }} />
            ) : (
                <BlockIcon style={{ fontSize: 14, marginBottom: 2, marginRight: 2 }} />
            )}
            Changes
        </span>
        <span className="no-change-fees d-block d-md-none">
            {searchResult.itineraries[0].cancellationsPermitted ? (
                <AttachMoneyIcon style={{ fontSize: 16, marginBottom: 2 }} />
            ) : (
                <BlockIcon style={{ fontSize: 14, marginBottom: 2, marginRight: 2 }} />
            )}
            <Trans>cancelation</Trans>
        </span>
    </>
);
