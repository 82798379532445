import { Box } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../../containers/banner";
import { TOGGLE_LOGIN_MODAL, logoutUser } from "../../redux/modules/auth";
import { useGetWindowSize } from "../common/hook/WindowSize";
import EditSearch from "../editsearch/index";
import CollapsableMenu from "./CollapsableMenu";
import CountryModal from "./CountryModal";
import HeaderLogo from "./HeaderLogo";
import LoginModal from "./LoginModal";
import { NavbarContainer } from "./Style";
import { getGeoInfo } from "./util";

const Header2 = ({ transparent, auth, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
    const [scrollY, setScrollY] = useState(window.scrollY);
    const [editSearchOpen, setEditSearchOpen] = useState(false);
    const [countryCode, setCountryCode] = useState("AU");
    const [countryName, setCountryName] = useState("Australia");
    const [countryBanner, setCountryBanner] = useState(false);
    const [countryModal, setCountryModal] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const loginModal = useSelector((state) => state.auth.loginModal);

    const searchQuery = useSelector((state) => state.search.query);
    const { mobileView } = useGetWindowSize();
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getUserGeoInfo();
        window.addEventListener("scroll", handleScroll);
        if (auth.user.email && !auth.user.exp) dispatch(logoutUser());
        if (auth.user.exp <= new Date()) dispatch(logoutUser());
        getUserGeoInfo();
    }, []);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    const toggleMobileMenu = () => {
        if (window.innerWidth < 768) {
            if (!isOpen) {
                document.querySelector("html").style.overflow = "hidden";
            } else {
                document.querySelector("html").style.overflow = "auto";
            }
            setIsOpen(!isOpen);
        }
        setEditSearchOpen(false);
    };

    const toggleAccountDropdown = () => {
        setAccountDropdownOpen((prev) => !prev);
    };

    const handleSeeAllPropClick = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const params = {
            roominfo: searchParams.get("roominfo"),
            checkindate: searchParams.get("checkindate"),
            checkoutdate: searchParams.get("checkoutdate"),
            locationCode: searchParams.get("locationCode"),
            location: searchParams.get("location"),
            destination: searchParams.get("destination")
        };
        navigation(
            `/stays/search?${Object.keys(params)
                .map((key) => `${key}=${params[key]}`)
                .join("&")}`
        );
    };
    const getUserGeoInfo = () => {
        getGeoInfo().then((res) => {
            if (!res || !res.countryCode) {
                return;
            }
            setCountryCode(res.countryCode);
            setCountryName(res.countryName);
            setCountryModal(true);
        });
    };

    const burger = isOpen ? " is-active" : "";
    const path = location.pathname;
    let { hostname, search } = window.location;
    search += `${search === "" ? "?" : "&"}redirectedFrom=${hostname}`;
    return (
        <Box>
            <NavbarContainer
                isopen={editSearchOpen ? 1 : 0}
                className={classNames("Header", {
                    "transparent-page": transparent,
                    transparent: transparent && scrollY === 0 && window.innerWidth > 768,
                    hasCountryBanner: countryBanner
                })}
                expand="md"
                fixed={mobileView ? "" : "top"}
                style={{
                    marginTop: 0,
                    marginBottom: 30
                }}
            >
                {["/stays", "/flights", "/"].includes(path) && (
                    <Banner
                        showBanner={showBanner}
                        hideBanner={() => {
                            setShowBanner(false);
                        }}
                    />
                )}
                <HeaderLogo
                    setEditSearchOpen={setEditSearchOpen}
                    handleSeeAllPropClick={handleSeeAllPropClick}
                    isOpen={isOpen}
                    path={path}
                    toggleMobileMenu={toggleMobileMenu}
                />
                {countryModal && (
                    <CountryModal
                        handleClose={() => setCountryModal((prev) => !prev)}
                        countryCode={countryCode}
                        countryName={countryName}
                        search={search}
                    />
                )}
                {path.includes("/flightssearch/s/") && !isOpen && (
                    <EditSearch
                        productType="flights"
                        searchQuery={searchQuery.flights}
                        open={editSearchOpen}
                        setOpen={(open) => setEditSearchOpen(open)}
                    />
                )}
                <button
                    className={`navbar-toggler hamburger hamburger--collapse${burger}`}
                    type="button"
                    style={{ zIndex: 4 }}
                    onClick={toggleMobileMenu}
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner" />
                    </span>
                </button>
                <CollapsableMenu
                    accountDropdownOpen={accountDropdownOpen}
                    auth={auth}
                    isOpen={isOpen}
                    logoutUser={() => {
                        dispatch(logoutUser());
                    }}
                    path={path}
                    toggleAccountDropdown={toggleAccountDropdown}
                    toggleLoginModal={() => {
                        dispatch({
                            type: TOGGLE_LOGIN_MODAL
                        });
                    }}
                    toggleMobileMenu={toggleMobileMenu}
                />
                <div className="overlay" onClick={toggleMobileMenu} />
                <div
                    className={classNames("edit-search-overlay", {
                        active: editSearchOpen
                    })}
                    onClick={() => setEditSearchOpen(false)}
                />
                {loginModal && (
                    <LoginModal
                        toggleLoginModal={() =>
                            dispatch({
                                type: TOGGLE_LOGIN_MODAL
                            })
                        }
                    />
                )}
            </NavbarContainer>
            <Box mt={"100px"}>{children}</Box>
        </Box>
    );
};

export default Header2;
