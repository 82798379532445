import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Coronavirus from "../../components/Coronavirus";
import Header2 from "../../components/header/Header2";
import PropertyDetailView from "../../components/stays/propertydetail/PropertyDetailView";
import HotelSearchView from "../../components/stays/result/HotelSearchView";
import { SHOW_FLIGHT_SEARCH_NEW_DESIGN } from "../../constants";
import {
    BookingComplete,
    BookingContract,
    BookingDetail,
    Bookings,
    ChangeFlightPage,
    ConfirmBooking,
    Faq,
    FlightSearchPage,
    HowItWorks,
    Login,
    PassengerDetail,
    PrivacyPolicy,
    PrivateRoute,
    RedesignFlightSearchPage,
    RedesignHomepage,
    Refer,
    RestrictedRoute,
    ReviewPage,
    Term
} from "../LoadingView";
import Navbar from "../redesign/navbar/Navbar";

const AppRoute = ({ auth, displayRedesignedSearch, transparentHeader }) => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <>
                        <Navbar auth={auth} />
                        <RedesignHomepage />
                    </>
                }
            />
            <Route
                path="/flightssearch/s/:multicity"
                element={
                    SHOW_FLIGHT_SEARCH_NEW_DESIGN && displayRedesignedSearch ? (
                        <>
                            <Navbar auth={auth} />
                            <RedesignFlightSearchPage />
                        </>
                    ) : (
                        <>
                            <Header2 transparent={transparentHeader} auth={auth}>
                                <FlightSearchPage />
                            </Header2>
                        </>
                    )
                }
            />
            <Route
                path="/flightssearch/s/:origin/:destination/:departureDate/:returnDate"
                element={
                    SHOW_FLIGHT_SEARCH_NEW_DESIGN && displayRedesignedSearch ? (
                        <>
                            <Navbar auth={auth} />
                            <RedesignFlightSearchPage />
                        </>
                    ) : (
                        <>
                            <Header2 transparent={transparentHeader} auth={auth}>
                                <FlightSearchPage />
                            </Header2>
                        </>
                    )
                }
            />
            <Route
                path="/flightssearch/s/:origin/:destination/:departureDate"
                element={
                    SHOW_FLIGHT_SEARCH_NEW_DESIGN && displayRedesignedSearch ? (
                        <>
                            <Navbar auth={auth} />
                            <RedesignFlightSearchPage />
                        </>
                    ) : (
                        <>
                            <Header2 transparent={transparentHeader} auth={auth}>
                                <FlightSearchPage />
                            </Header2>
                        </>
                    )
                }
            />
            <Route
                path="/flightssearch/passenger"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <PassengerDetail />
                    </Header2>
                }
            />
            <Route
                path="/flights/review"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <ReviewPage />
                    </Header2>
                }
            />
            <Route
                path="/stays/search"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <HotelSearchView />
                    </Header2>
                }
            />
            <Route
                path="/stays/confirm"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <BookingComplete />
                    </Header2>
                }
            />
            <Route
                path="/stays/detail"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <PropertyDetailView />
                    </Header2>
                }
            />
            <Route
                path="/stays/checkout"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <BookingContract />
                    </Header2>
                }
            />
            <Route
                path="/faq"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <Faq />
                    </Header2>
                }
            />
            <Route
                path="/terms"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <Term />
                    </Header2>
                }
            />
            <Route
                path="/privacy-policy"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <PrivacyPolicy />
                    </Header2>
                }
            />
            <Route
                path="/confirm"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <ConfirmBooking />
                    </Header2>
                }
            />
            <Route
                path="/confirmbooking"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <ConfirmBooking />
                    </Header2>
                }
            />
            <Route
                path="/how-it-works"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <HowItWorks />
                    </Header2>
                }
            />
            <Route
                path="/travel-alert/coronavirus"
                element={
                    <Header2 transparent={transparentHeader} auth={auth}>
                        <Coronavirus />
                    </Header2>
                }
            />
            <Route
                path="/login"
                element={
                    <RestrictedRoute auth={auth}>
                        <Header2 transparent={transparentHeader} auth={auth}>
                            <Login />
                        </Header2>
                    </RestrictedRoute>
                }
            />
            <Route
                path="/refer"
                element={
                    <PrivateRoute auth={auth}>
                        <Header2 transparent={transparentHeader} auth={auth}>
                            <Refer />
                        </Header2>
                    </PrivateRoute>
                }
            />
            <Route
                path="/bookings"
                element={
                    <PrivateRoute auth={auth}>
                        <Header2 transparent={transparentHeader} auth={auth}>
                            <Bookings />
                        </Header2>
                    </PrivateRoute>
                }
            />
            <Route
                path="/bookings/:reference"
                element={
                    <PrivateRoute auth={auth}>
                        <Header2 transparent={transparentHeader} auth={auth}>
                            <BookingDetail />
                        </Header2>
                    </PrivateRoute>
                }
            />
            <Route
                path="/bookings/:reference/change/flights"
                element={
                    <PrivateRoute auth={auth}>
                        <Header2 transparent={transparentHeader} auth={auth}>
                            <ChangeFlightPage />
                        </Header2>
                    </PrivateRoute>
                }
            />
            <Route
                path="/bookings/:reference/change/flights"
                element={
                    <PrivateRoute auth={auth}>
                        <Header2 transparent={transparentHeader} auth={auth}>
                            <ChangeFlightPage />
                        </Header2>
                    </PrivateRoute>
                }
            />
            <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
    );
};

export default AppRoute;
