import React from "react";
import { Trans } from "react-i18next";
import { Col } from "reactstrap";
import { Text } from "../../../components/common/typography/Style";

export const PaymentPlanChooseText = () => (
    <Col sm={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Text size={"lg"} textweight={"semiBold"}>
            Choose your payment plan
        </Text>
        <Text size={"sm"} textweight={"regular"}>
            Select from weekly or <Trans>biweekly</Trans> payments.
        </Text>
    </Col>
);
