import { Grid } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import { _url } from "../../config/utils";
import { AU_TRUSTPILOT_URL } from "../../constants";
import { logAmplitudeEvent } from "../../utils/SiteUtils";

const HomeFeatureAu = () => {
    return (
        <Container>
            <Grid mb={"40px"} container justifyContent={"center"}>
                <a
                    onClick={() => {
                        logAmplitudeEvent("clicksTrustPilotRating");
                    }}
                    href={AU_TRUSTPILOT_URL}
                    target={"_blank"}
                    rel="noreferrer"
                >
                    <img
                        style={{ width: "300px", height: "100%", cursor: "pointer" }}
                        src={_url("assets/images/trustpilot.png")}
                    />
                </a>
            </Grid>
            <Row>
                <Col md={4} xs={12} className="IconBox text-center">
                    <img alt="search" src={_url("assets/icons/phone.png")} />
                    <h3>Book your getaway</h3>
                    <p> Search from thousands of flights and hotels. Book with a fraction of the upfront cost.</p>
                </Col>
                <Col md={4} xs={12} className="IconBox text-center">
                    <img alt="yoga" src={_url("assets/icons/yoga.png")} />
                    <h3>Enjoy flexible payments</h3>
                    <p> Pay weekly or fortnightly before you travel. No interest. No credit checks.</p>
                </Col>
                <Col md={4} xs={12} className="IconBox text-center">
                    <img alt="hero" src={_url("assets/icons/sunset.png")} />
                    <h3>Travel with confidence</h3>
                    <p> Enjoy your getaway knowing that your flights are fully paid off. </p>
                </Col>
            </Row>
        </Container>
    );
};

export default HomeFeatureAu;
