import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import { Footer } from "../../components";
import Toggle from "./Toggle";

const FaqAu = () => {
    const [currentActive, setCurrentActive] = useState(1);

    const scrollTo = (event, activeIndex) => {
        setCurrentActive(activeIndex);
        window.scrollTo({
            top:
                document.getElementById(event.target.getAttribute("data-id")).getBoundingClientRect().top +
                window.scrollY -
                (80 + 68),
            behavior: "smooth"
        });
    };
    return (
        <div>
            <Helmet>
                <title>FAQ | Pay Later Travel</title>
            </Helmet>
            <div className="FAQ page-content">
                <Container fluid="xl" className="page-content__container">
                    <Row>
                        <Col>
                            <h1>Frequently Asked Questions</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="d-none d-md-block">
                            <Sticky enabled top=".Header" bottomBoundary=".page-content__container">
                                <div className="FAQ__nav">
                                    <Nav vertical className="booking-detail-sub-nav">
                                        <NavItem>
                                            <NavLink
                                                active={currentActive === 0}
                                                data-id="about"
                                                onClick={(e) => scrollTo(e, 0)}
                                            >
                                                About
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                active={currentActive === 1}
                                                data-id="how-it-works"
                                                onClick={(e) => scrollTo(e, 1)}
                                            >
                                                How it works
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                active={currentActive === 2}
                                                data-id="pay-later-flights"
                                                onClick={(e) => scrollTo(e, 2)}
                                            >
                                                Pay Later Flights
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                active={currentActive === 3}
                                                data-id="pay-later-stays"
                                                onClick={(e) => scrollTo(e, 3)}
                                            >
                                                Pay Later Stays
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                active={currentActive === 4}
                                                data-id="covid-19"
                                                onClick={(e) => scrollTo(e, 4)}
                                            >
                                                COVID-19
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Sticky>
                        </Col>
                        <Col md={9} style={{ marginTop: "20px" }}>
                            <div id="about" className="FAQ__section">
                                <h2>About</h2>
                                <div className="ToggleGroup card">
                                    <Toggle title="Who is Pay Later Travel?">
                                        <p>
                                            Pay Later Travel was founded in 2017 with a simple mission: to make travel
                                            accessible for everyone. We believe that there’s a better way to pay for
                                            travel, one that allows you to book, budget and pay for your getaway,
                                            without getting into debt.{" "}
                                        </p>
                                        <div>
                                            <p>
                                                Pay Later Travel promotes responsible financial habits by providing
                                                traditional layby for travel. Our layby system allows you to secure
                                                great travel deals on the world’s best airlines and hotels with a
                                                fraction of the upfront cost, providing you protection from price
                                                increases.
                                            </p>
                                            <p>
                                                You then spread the remainder in affordable instalments that suit your
                                                budgets and lifestyle. Since it’s layby, we don’t do credit checks and
                                                you’ll never be charged interest.{" "}
                                            </p>
                                            <p>
                                                The best part is, your trip will be paid in full before you depart,
                                                allowing you to travel with peace of mind.{" "}
                                            </p>
                                        </div>
                                    </Toggle>

                                    <Toggle title="Why does Pay Later Travel exist?">
                                        <p>
                                            Financial wellness and flexibility are at the core of our company. We
                                            believe that you should be able to pay for your getaway on your own terms.{" "}
                                        </p>
                                        <p>
                                            Our aim is to promote responsible financial habits, this means no
                                            debilitating debt and no interest, ever!{" "}
                                        </p>
                                    </Toggle>

                                    <Toggle title="Is Pay Later Travel accredited?">
                                        <p>
                                            Pay Later Travel is proud to be part of the IATA network of Travel Industry
                                            Designator Services (TIDS).
                                        </p>
                                        <p>
                                            A travel intermediary that has been accepted into the TIDS program has been
                                            properly vetted by IATA, meets all industry requirements, and can access a
                                            broad supplier network to book travel.
                                        </p>
                                    </Toggle>

                                    <Toggle title="What products do you offer?">
                                        <p>
                                            Pay Later Travel offers Flights and Stays. Our platform provides real-time
                                            prices from the world&apos;s leading airlines and hotel chains for you to
                                            browse.{" "}
                                        </p>
                                        <p>
                                            You can secure a great deal with a fraction of the upfront cost and spread
                                            the remainder over flexible instalments that suit your budget and lifestyle.{" "}
                                        </p>
                                        <p>
                                            Our friction-free checkout means that you stay on our site throughout the
                                            booking process. No pop-ups, no third parties and no credit checks.
                                        </p>
                                        <p>
                                            Once your booking is made, you’ll have access to a portal where you can
                                            manage your booking and payments.
                                        </p>
                                    </Toggle>

                                    <Toggle title="Who can use Pay Later Travel?">
                                        <p>
                                            To be able to use Pay Later Travel, you must be above the age of 18, have a
                                            verifiable email address, phone number and a valid debit or credit card.
                                        </p>
                                    </Toggle>

                                    <Toggle title="How can I trust this service?">
                                        <p>
                                            Pay Later Travel has been serving thousands of <Trans>aussie</Trans>{" "}
                                            travellers since 2017. Customer experience is at the heart of what we do but
                                            don’t just take our word for it.
                                        </p>
                                        {/* <p>
                                            Check out our reviews here on{" "}
                                            <a
                                                href="https://www.trustpilot.com/review/paylatertravel.com"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Trustpilot
                                            </a>
                                            .
                                        </p> */}
                                        <p>
                                            If you have any questions at all, our <Trans>australiaBased</Trans>
                                            team is here to support via live chat, phone or email.
                                        </p>
                                    </Toggle>
                                </div>
                            </div>
                            <div id="how-it-works" className="FAQ__section">
                                <h2>How it works</h2>
                                <div className="ToggleGroup card">
                                    <Toggle title="How does it work?">
                                        <p>
                                            Please scroll down to{" "}
                                            <a data-id="pay-later-flights" onClick={(e) => scrollTo(e, 3)}>
                                                Pay Later Flights
                                            </a>{" "}
                                            or{" "}
                                            <a data-id="pay-later-stays" onClick={(e) => scrollTo(e, 4)}>
                                                Pay Later Stays
                                            </a>{" "}
                                            to find out more about how each product works.
                                        </p>
                                    </Toggle>
                                    <Toggle title="Do I have to pay it off prior to a departure date?">
                                        <p>
                                            As we offer a <Trans>layaway</Trans> service, flight tickets/hotel
                                            reservations need to be paid off prior to a departure date/arrival date to a
                                            hotel.
                                        </p>
                                    </Toggle>
                                    <Toggle title="Can I make early payments?">
                                        <p>
                                            Absolutely. Our layaway plans allow you to pay sooner if you wish, make
                                            extra payments or pay the entire thing off! We don&apos;t charge any fees
                                            for If you wish to make extra payments, please reach out to us via chat and
                                            we’ll assist you further.
                                        </p>
                                    </Toggle>
                                    <Toggle title="Are there any fees or interest involved?">
                                        <p>
                                            No. There are no booking fees or interest charges, ever! The price you see
                                            at the start of your search is the price you’ll pay at the end.
                                        </p>
                                    </Toggle>
                                    <Toggle title="Can I login to view my booking and payments?">
                                        <p>
                                            Yes! Once you’ve made your first booking, you’ll be taken to our customer
                                            portal. Here you’ll be able to view your bookings, payment plan, remaining
                                            balances and instructions on how to make extra or early payments. If you
                                            have an existing booking, login <Link to="/login">here.</Link>
                                        </p>
                                    </Toggle>

                                    <Toggle title="What happens if I missed a payment?">
                                        <p>
                                            Prior to each instalment being processed, we’ll send you a payment reminder
                                            and after each successful instalment you’ll be provided with a receipt. Your
                                            payment history is also reflected within the customer portal.
                                        </p>
                                        <p>
                                            We do understand that sometimes payments are made late. We won’t charge you
                                            a late fee, but we’ll send you a notification to let you know we’ve been
                                            unable to process a scheduled instalment with a method of how you can catch
                                            up.{" "}
                                        </p>
                                        <p>
                                            If we see scheduled instalments are consistently being missed, we’ll attempt
                                            to reach out to make sure everything’s okay. Please note that if more than 2
                                            scheduled instalments are missed, you may be at risk of the booking being
                                            cancelled, or the total price of your booking increasing. This is because
                                            failed instalments do prevent us from keeping your booking secured with
                                            providers.
                                        </p>
                                        <p>
                                            Please note that if the booking is cancelled as a result of consecutive
                                            missed payments, a layby termination fee applies. More information can be
                                            found in our <Link to="/terms">Terms</Link>.
                                        </p>
                                        <p>
                                            In line with our obligations under layby agreements, if the full and final
                                            balance is not paid prior to departure, we’re unable to issue your travel
                                            documents and you won’t be able to check-in for your flight, or check-in to
                                            your hotel.{" "}
                                        </p>
                                        <p>
                                            We understand that each customer’s circumstance is unique, so if you are
                                            having issues maintaining your scheduled instalments, we have a dedicated
                                            hardship team who are here to help. We’ll discuss your current needs and
                                            come up with a tailored solution for you.
                                        </p>
                                    </Toggle>

                                    <Toggle title="Can I make multiple bookings?">
                                        <p>
                                            Yes, you are able to make multiple bookings with us. Your customer portal
                                            will allow you to track and manage these bookings as well as see where
                                            you’re at in your payment schedule(s).{" "}
                                        </p>
                                    </Toggle>

                                    <Toggle title="Is travel insurance included?">
                                        <p>
                                            We do not sell travel insurance directly, however we always recommend taking
                                            out travel insurance to protect yourself from unexpected events.
                                        </p>
                                    </Toggle>

                                    <Toggle title="I have PLT Credit, how do I use it?">
                                        <p>
                                            That’s awesome! PLT Credit can be used for any of our products on our site.
                                            You’ll need to head over <Link to="/">to our home page</Link> and select a
                                            travel deal. Once you’ve made the booking, reach out and quote your PLT
                                            Credit number and we’ll deduct any outstanding balance by your PLT Credit.
                                        </p>
                                        <p>
                                            Based on popular demand from our customers, this can now be used on Flights
                                            and Hotel Stays.{" "}
                                        </p>
                                    </Toggle>
                                </div>
                            </div>

                            <div id="pay-later-flights" className="FAQ__section">
                                <h2>Pay Later Flights</h2>
                                <div className="ToggleGroup card">
                                    <Toggle title="How does Pay Later Flights work?">
                                        <h4>1. Search real-time flight prices and select the one you’d like to book</h4>
                                        <p>
                                            Select your origin, destination, dates and the number of people flying.
                                            You’ll be presented with real-time prices from hundreds of popular airlines
                                            like Qantas, Singapore Airlines, Virgin Australia plus many more. Select the
                                            flight you’d like to book.
                                        </p>
                                        <h4>2. Enter your passenger and contact details</h4>
                                        <p>
                                            Our form will then direct you to enter the full names, date of birth and
                                            passport origin so that we may put this information on your airline tickets.
                                            Please ensure these match your passport details. For domestic bookings, we
                                            don’t need passport information.{" "}
                                        </p>
                                        <h4>3. Set up your payment plan and secure your price with a deposit</h4>
                                        <p>
                                            You’ll then be able to a payment plan that fits your lifestyle and budget
                                            all with no interest, credit checks or fees. You’ll do this all on our site
                                            and never have to leave. Next, lock in your price with a small deposit of
                                            10-15%. Once you click ‘book now’ you’ll receive a confirmation email and
                                            you’ll be directed to login to the portal to manage your booking.
                                        </p>
                                        <h4>4. Your instalments will be processed automatically </h4>
                                        <p>
                                            Before each instalment is processed, we’ll send you a reminder and on the
                                            day, the payment will be processed automatically. You’ll get a receipt for
                                            every successful payment that is made. You’ll also be able to login to the
                                            customer portal to manage your booking and payments.{" "}
                                        </p>
                                        <h4>5. Receive your e-tickets and travel documentation</h4>
                                        <p>
                                            Once your final instalment is successfully processed, we’ll send across your
                                            e-tickets. Now you’ll be able to login to the airline portal and manage your
                                            booking directly with the airline. Have a safe flight!
                                        </p>
                                    </Toggle>
                                    <Toggle title="Why can’t I book dates in the next 6 weeks?">
                                        <p>
                                            As this is a layby service, all flights must be paid off prior to departure.
                                            Therefore, we present options that will enable you to have a deposit plus at
                                            least 2 instalments to pay for your layby. If you wish to enquire about
                                            flights within the next 6 weeks, we can assist with ticketing these at
                                            upfront competitive rates. If this is you, please reach out on chat.{" "}
                                        </p>
                                    </Toggle>
                                    <Toggle title="When do I get my e-ticket?">
                                        <p>
                                            Your e-ticket is released within 24 hours from your final instalment.
                                            You&apos;re able to access your e-ticket early if you wish, by paying the
                                            full remaining balance on your layby. We do not charge any fees for early
                                            repayments.
                                        </p>
                                    </Toggle>
                                    <Toggle title="Can I make changes to my flight after I’ve booked?">
                                        <p>
                                            This will depend on the fare rules on the flight you’ve booked. In many
                                            cases, date changes are permitted and may be subject to an airline change
                                            fee and fare difference. This means if the new dates are more expensive than
                                            the original date, the airline will require you to pay the difference.{" "}
                                        </p>
                                        <p>
                                            With regards to name changes, these are usually not permitted. If they are
                                            permitted, the airline may charge a fee.{" "}
                                        </p>
                                        <p>
                                            Please note, all changes are subject to availability. If you need to change
                                            your flight(s) please let us know and we’ll check the fare rules to confirm
                                            applicable change fees and fare differences.{" "}
                                        </p>
                                    </Toggle>
                                    <Toggle title="What is the cancellation process?">
                                        <p>
                                            We understand plans change! If you need to cancel, the cancellation policy
                                            will be as per the fare rules of your chosen airline.{" "}
                                        </p>
                                        <p>
                                            Depending on your airline, tickets may be refundable minus an airline
                                            penalty. Please note, many Low-Cost Carriers (LCCs) do not offer refunds and
                                            instead reserve the right to refund you in the form of a credit voucher.{" "}
                                        </p>
                                        <p>
                                            When you request a cancellation, we will assess the fare rules of booking
                                            and advise you on your options. If a cash refund is approved by the airline,
                                            we’ll pass this on, less our reasonable costs of making, managing and
                                            cancelling your booking.{" "}
                                        </p>
                                        <p>
                                            We’ve got dedicated support teams ready to assist. If you wish to cancel
                                            your booking please email bookings@paylatertravel.com.au.
                                        </p>
                                    </Toggle>
                                    <Toggle title="Are flight tickets refundable?">
                                        <p>
                                            Cancellation policies vary by airline and unfortunately we don’t control
                                            these commercial policies. However, depending on the airline you’ve chosen,
                                            your fare could be refundable less a cancellation fee.{" "}
                                        </p>
                                        <p>
                                            Please note that some airlines such as Low-Cost Carriers reserve the right
                                            to provide no refunds, or provide your refund in the form of a travel
                                            voucher.{" "}
                                        </p>
                                        <p>
                                            Please don’t hesitate to reach out to us on chat if you’d like us to find
                                            the fare rules on the flight you’re looking at.
                                        </p>
                                    </Toggle>
                                    <Toggle title="I don’t have a passport or it hasn’t arrived yet, can I still book?">
                                        <p>
                                            That’s totally fine! For Domestic flights we do not require passport
                                            details, just ensure you have a valid ID for check-in. When completing your
                                            Domestic booking, leave the passport field as ‘Australia’.
                                        </p>
                                        <p>
                                            For international flights, you will need a valid passport at check-in. If
                                            you don’t have it yet, you can still make your booking but please ensure you
                                            use the same name (and spelling) as what you will use on your passport.{" "}
                                        </p>
                                        <p>
                                            It&apos;s important you have all the required documentation with sufficient
                                            validity prior to your flight departure. Some countries will only allow you
                                            to enter if you have sufficient validity on your passport! Check out Smart
                                            Traveller for more info:{" "}
                                            <a
                                                href="https://www.smartraveller.gov.au/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                https://www.smartraveller.gov.au/
                                            </a>
                                            .
                                        </p>
                                    </Toggle>
                                    <Toggle title="What happens if the airline changes or cancels my flight?">
                                        <p>
                                            From time to time, airlines may modify their schedules based on operational
                                            requirements. When they do make a change or cancellation, most airlines will
                                            present you with some other options. For example, they might suggest the
                                            next flight the same day. In the case of a major schedule change or route
                                            cancellation, the airline may offer a refund or travel credits to the value
                                            of your booking.
                                        </p>
                                    </Toggle>
                                    {/* <Toggle title="How does your 30 day free date change work?">
                  <p>We know our customer’s want flexibility when booking their travel. That’s why we’ve introduced a 30 day fee-free period from the day you make your booking for any date changes. This means we’ll waive the fee, and you’ll only need to cover any airline fare difference.</p>
                  <p>If this is you, please reach out via chat and we’ll organise flights for your new dates.</p>
                </Toggle> */}
                                    <Toggle title="Can I book Premium Economy or Business Class?">
                                        <p>
                                            For the moment, the service is available for Economy Class only. However,
                                            we&apos;re able to offer Premium or Business Class on a case by case basis.
                                            If you&apos;d like a quote, please reach out on chat.
                                        </p>
                                    </Toggle>
                                    <Toggle title="Can I add my frequent flyer number to my flights?">
                                        <p>
                                            Absolutely. You&apos;ll have an opportunity to do this either through us, or
                                            once you&apos;ve received your e-ticket directly with the airline.
                                        </p>
                                    </Toggle>
                                </div>
                            </div>

                            <div id="pay-later-stays" className="FAQ__section">
                                <h2>Pay Later Stays</h2>
                                <div className="ToggleGroup card">
                                    <Toggle title="How does it work?">
                                        <h4>1. Search real-time rates for popular hotels</h4>
                                        <p>
                                            Search popular hotel accommodation at your favourite chains like Hilton,
                                            Shangri-La, Meriton and hundreds more! Once you’ve found a great deal,
                                            select it to view your room options.{" "}
                                        </p>
                                        <h4>2. Select a room option and then enter your contact details</h4>
                                        <p>
                                            Many of our hotel room options accommodate guests of all sizes: couples,
                                            families, friends. Don’t see the room type you’re after? Reach out on chat
                                            and we’ll organise it for you. Next, enter some contact details so we can
                                            pass this onto the hotel.{" "}
                                        </p>
                                        <h4>
                                            3. Set up your payment plan and secure your deal with no upfront payment
                                        </h4>
                                        <p>
                                            You’ll then be able to customise a payment plan that fits your lifestyle and
                                            budget all with no interest, credit checks or fees. You’ll do this all on
                                            our site and never have to leave. Book now to secure your price with no
                                            upfront payment!
                                        </p>
                                        <h4>4. Your instalments will be processed automatically </h4>
                                        <p>
                                            Before each instalment is processed, we’ll send you a reminder and on the
                                            day, the payment will be processed automatically. You’ll get a receipt for
                                            every successful payment that is made. You’ll also be able to login to the
                                            customer portal to manage your booking and payments.{" "}
                                        </p>
                                        <h4>5. Enjoy free cancellation and unlimited date changes</h4>
                                        <p>
                                            Rest easy with the peace of mind that you’ll have free cancellation and
                                            unlimited date changes up to certain key dates. You’ll find these dates in
                                            your trip confirmation email and within the portal.{" "}
                                        </p>
                                        <h4>6. Receive your travel documentation and enjoy your holiday!</h4>
                                        <p>
                                            Once your final instalment is successfully processed, we’ll send across your
                                            travel documentation that you’ll present to the hotel at check-in. Easy!
                                        </p>
                                    </Toggle>
                                    <Toggle title="When do I get my reservation confirmation?">
                                        <p>
                                            Once we’ve processed your final instalment, we’ll send across your
                                            reservation confirmation. It’ll have the unique codes that you’ll need to
                                            check-in to the hotel. Please note that if we’re unable to collect the full
                                            and final payment 7 days prior to check-in, the booking will be cancelled
                                            and you will not receive your travel documentation.{" "}
                                        </p>
                                    </Toggle>
                                    <Toggle title="What if you do not have a hotel I would like to book but I still want to use your service?">
                                        <p>
                                            Please let us know! Our team is building our catalogue and we’d love to hear
                                            from you. If there’s a hotel that you’d like to book, let us know and we’ll
                                            get it organised for you.{" "}
                                        </p>
                                    </Toggle>
                                    <Toggle title="Why can’t I book dates within 21 days? ">
                                        <p>
                                            As this is a book now, pay later service, all bookings must be paid off
                                            prior to departure. Therefore, we present options that will enable you to
                                            have at least 2 instalments to pay for your layby.{" "}
                                        </p>
                                        <p>
                                            If there’s a specific deal you’d like to book that is within 21 days, we’re
                                            able to assist with competitive upfront rates. Please reach out on chat if
                                            this is you.{" "}
                                        </p>
                                    </Toggle>
                                    <Toggle title="Will I have to pay a security bond at the hotel?">
                                        <p>
                                            Usually hotels will require you to pay a security bond on check-in in case
                                            of any miscellaneous charges or damages that may occur during your stay.
                                            This amount varies for different hotels and it is established by the
                                            hotel/chain individually.{" "}
                                        </p>
                                    </Toggle>
                                    <Toggle title="If I have a PLT credit with you, can I use it to book a hotel stay?">
                                        <p>
                                            You can certainly use a PLT credit to book a stay. Please be advised that it
                                            has the same terms and conditions (please refer to your PLT credit
                                            confirmation for the details). You will need to make a booking on our
                                            website, and once it is made, please contact us, so we can apply your PLT
                                            credit towards your new booking.
                                        </p>
                                    </Toggle>
                                    <Toggle title="What if I have a special request for a hotel (e.g special occasion, early check-in, quiet room etc)?">
                                        <p>
                                            Please let us know if you have a special request. We will pass it on to the
                                            hotel, but unfortunately we cannot guarantee that it will be accommodated as
                                            it is up to the hotel.
                                        </p>
                                    </Toggle>
                                    <Toggle title="I want to book meals, parking and other services that are NOT included in the rate, can I do it through you?">
                                        <p>
                                            If you would like to add any services that are not included in the room
                                            rate, please feel free to contact the hotel directly after your reservation
                                            confirmation is issued, and they will be able to assist you directly.
                                        </p>
                                    </Toggle>
                                    <Toggle title="Can I make changes to my booking?">
                                        <p>
                                            You are able to make changes to your booking up to 7 days prior to your
                                            check-in date. Please contact our friendly customer service team to assist
                                            with your change enquiry.
                                        </p>
                                    </Toggle>
                                    <Toggle title="What’s the cancellation process?">
                                        <p>
                                            If you decide to cancel your booking up to 7 days prior to check-in, you
                                            will get a full refund back to your card unless a different cancellation
                                            policy was advised. If you used a PLT credit to make a booking, it will be
                                            stored back into PLT credit.
                                        </p>
                                        <p>
                                            Unfortunately no cancellations or changes are permitted within 7 days to
                                            your arrival. Please contact us via email/chat or over the phone to proceed
                                            with the cancellation.{" "}
                                        </p>
                                    </Toggle>
                                </div>
                            </div>

                            <div id="covid-19" className="FAQ__section">
                                <h2>COVID-19</h2>
                                <div className="ToggleGroup card">
                                    <Toggle title="What would happen If my flight is cancelled due to COVID-19 restrictions? Will I get the money back?">
                                        <p>
                                            If your flight is cancelled due to COVID-19 restrictions, you will have at
                                            least two options from the airline you are booked with - get a travel
                                            credit/voucher or change dates. If you decide to change dates, the airline
                                            would waive the change fee, but if the new ticket for the same class is more
                                            expensive, you will need to pay a fare difference. Unfortunately we cannot
                                            guarantee a refund as it depends on the airline’s commercial policy at the
                                            time. Our experience is that some of the airlines we work with are approving
                                            cash refunds. Our team will do their best to provide you with the desired
                                            outcome!
                                        </p>
                                    </Toggle>
                                    <Toggle title="What if my flight is still scheduled but I want to cancel my trip due to health concerns?">
                                        <p>
                                            We will get in touch with the airline to check your cancellation options. If
                                            your flight is still far ahead and not covered by the airline’s commercial
                                            policy, we will have to follow the fare rules of your ticket. Most of the
                                            tickets that we book are non-refundable. It might be worth waiting longer
                                            until/if your flight gets <Trans>canceled</Trans> by the airline, so there
                                            would be more options available to you.
                                        </p>
                                    </Toggle>

                                    <Toggle title="Where can I travel to?">
                                        <p>
                                            The best place to check is the Australian Government’s Smart Traveller
                                            website{" "}
                                            <a
                                                href="https://www.smartraveller.gov.au/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                here
                                            </a>
                                            . As travel restrictions are eased, the Government will update the list.
                                        </p>
                                        <p>
                                            Many airlines have routes scheduled for the next 12 months, and you are
                                            still able to book in advance. Should Government restrictions still be in
                                            place at the time of travel, you’ll be presented with cancellation options
                                            or date change options.
                                        </p>
                                        <p>
                                            If you are looking for information on getting an exemption to travel, the
                                            Australian Government has put an information link{" "}
                                            <a
                                                href="https://covid19.homeaffairs.gov.au/leaving-australia"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                here
                                            </a>
                                            .
                                        </p>
                                    </Toggle>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default FaqAu;
