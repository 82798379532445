/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { _url } from "../config/utils";
import { getSiteValueByLang } from "../utils/SiteUtils";

const Footer = () => {
    const dt = new Date();

    return (
        <div className="footer">
            <div className="container">
                <Row className="content-text">
                    <Col md={4} xs={12}>
                        <div className="content">
                            <p>
                                {`${getSiteValueByLang("madeWithLove")} Get in touch with us at ${getSiteValueByLang(
                                    "supportMail"
                                )}.`}
                            </p>
                        </div>
                    </Col>
                    <Col md={4} xs={12}>
                        <div className="content">
                            <p>
                                By accessing our website, you agree to our <Link to="/terms">terms of service</Link> and{" "}
                                <Link to="/privacy-policy">privacy policy</Link>.
                            </p>
                        </div>
                    </Col>
                    <Col md={4} xs={12}>
                        <div className="content">
                            {/* <p>Questions? Chat or contact us: 1300 399 902</p> */}
                            {getSiteValueByLang("questionChat")}
                            <span>©{dt.getFullYear()} Pay Later Travel.</span>
                        </div>
                    </Col>
                </Row>
                <div className="more-info">
                    <Row>
                        <Col sm="auto" xs={12}>
                            <img alt="cards" className="cards" src={_url("assets/images/cards.png")} />
                        </Col>
                        <Col className="text-center">
                            <a href="https://www.facebook.com/paylatertravel/" target="_blank">
                                <img alt="facebook" className="facebook" src={_url("assets/images/facebook2.svg")} />
                            </a>
                            <a href="https://www.instagram.com/paylatertravel/" target="_blank">
                                <img alt="instagram" className="instagram" src={_url("assets/images/instagram2.svg")} />
                            </a>
                        </Col>
                        <Col sm="auto" xs={12} className="text-right">
                            <img alt="mcafee" className="mcafee" src={_url("assets/images/mcafee.jpg")} />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default Footer;
