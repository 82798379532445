import Styled from "@emotion/styled";
import { Button } from "@mui/material";
import { ColorTheme } from "../../../styles/color";

export const LoginedAccountButton = Styled(Button)`
        padding: 7px 20px !important;
        border-radius: 30px;
        background: transparent;
        outline: none;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.18);
        font-weight: 600;
        margin-left: 20px;
        color: ${(props) => (props.isscrolled ? ColorTheme.base.black : ColorTheme.base.white)};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media (max-width: 900px) {
        
            color: #000;
            border-color: #000;
    }
`;
