import Styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Tag = Styled.div`
    padding: 5px;
    text-transform: uppercase;
    color: black;
    padding: 5px 10px;
    border-radius: 10px;
    background: rgb(244, 208, 63);
    font-weight: bold;
`;

export const BannerContainer = Styled(Box)`
    border: 1px solid gray;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background: white;
    color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    
    & .nav-link {
        color: black !important;
        font-weight: normal !important;
    }
    u {
        font-size: 20px;
        color: #1890ff;
    }

    @media (max-width: 768px) {
        border: none;
        padding-top: 0px;
        padding-bottom: px;
    u {
        font-size: 12px;
        color: #1890ff;
    }
    & .nav-link {
        color: black !important;
        font-weight: normal !important;
        font-size: 12px !important;
    }
    }
`;
