/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from "react";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";
import { IS_USA_SITE } from "../constants";
import { getSiteValueByLang } from "../utils/SiteUtils";
import Footer from "./Footer";

const USTerms = (
    <div className="block-content">
        <h2>1. General</h2>
        <p>
            1.1. This website is operated by Sky Pay Group, Inc. under the brand name PayLater Travel. Throughout this
            site the terms &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo; refer to PayLater Travel.
        </p>
        <p>
            1.2. PayLater Travel provides access to information, tools and services from the PayLater Travel website,
            phone and email communication conditional upon your acceptance of the following terms, conditions, policies
            and notices (collectively referred to as the &ldquo;Terms&rdquo; or &ldquo;Agreement&rdquo;). Your access of
            our website or engagement with us through email or phone represents your agreement to be bound by these
            Terms and are inclusive of any amendments which PayLater Travel reserves the right to impose at any time.
        </p>
        <p>
            1.3. Unless the contrary intention appears, amendments to the Terms will take effect immediately from the
            date and time they are published on the website and will be incorporated into this Agreement. Terms apply to
            all users of our site and services, including, without limitation, users who are browsers, vendors,
            customers, merchants and/or contributors of content.
        </p>
        <p>
            1.4. By engaging with the tools and services on the PayLater Travel website you confirm that you are over
            the age of eighteen (18). By making a purchase through the Website you warrant that you are over eighteen
            (18) and that you have the legal right and ability to enter into a legally binding agreement with us. No
            persons under the age of eighteen (18) are permitted to use the website or to sign up as a member of the
            website.
        </p>
        <h2>2. Terms of Website Usage</h2>
        <h3>Website content</h3>
        <p>
            2.1. You must not reproduce, duplicate, copy, sell, resell or exploit any written material, visual material
            or code on the website, including these terms and conditions, without express written permission from
            PayLater Travel.
        </p>
        <h3>Search Function, Product Listings &amp; Quotes</h3>
        <p>
            2.2. The search function on our website retrieves general information about flight paths and pricings made
            publicly available from various airline ticketing providers. Our systems use this general information to
            detail a quote for the layaway financial arrangement we are prepared to offer to our customers for their
            preferred flight options (referred to as &ldquo; Product Listings&rdquo;). Whilst we strive to ensure our
            system regularly updates, collects and publishes accurate and up to date information for each Product
            Listing, we cannot and do not warrant that all information published on PayLater Travel will be current,
            accurate, complete and/or free of system glitches.
        </p>
        <p>
            2.3. Where we become aware of any error, omission or system glitch, we reserve the right to correct that
            error or omission and notify affected customers immediately. Should you be a customer directly affected by a
            system glitch you will be notified within 5 days of your booking. If we notify you within 5 days of making
            your booking we reserve the right to reject your transaction and provide a full refund.
        </p>
        <p>
            2.4. All Product Listings are subject to revisions in line with any changes in price, time, dates, flight
            path or other associated ticketing details that each airline ticketing provider makes at any time.
        </p>
        <p>2.5. Quotes are subject to change prior to your deposit being received.</p>
        <h3>Prohibited Uses</h3>
        <p>
            2.6. In addition to any other prohibitions as set forth in this Agreement, you are prohibited from using the
            site or its content:
        </p>
        <ol type="a">
            <li>for any unlawful purpose;</li>
            <li>to solicit others to perform or participate in any unlawful acts;</li>
            <li>
                to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances
                or local laws;
            </li>
            <li>
                to infringe upon or violate our intellectual property rights or the intellectual property rights of
                others;
            </li>
            <li>
                to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender,
                sexual orientation, religion, ethnicity, race, age, national origin, or disability;
            </li>
            <li>to submit false or misleading information;</li>
            <li>
                to upload or transmit viruses or any other type of malicious code that will or may be used in any way
                that will affect the functionality or operation of the Service or of any related website, other
                websites, or the Internet;
            </li>
            <li>to collect or track the personal information of others;</li>
            <li>to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
            <li>for any obscene or immoral purpose; or</li>
            <li>
                to interfere with or circumvent the security features of the Service or any related website,other
                websites, or the Internet.
            </li>
        </ol>
        <p>
            2.7. PayLater Travel reserves the right to terminate your use of the Service or any related website for
            engaging in any of the prohibited uses. If your use of the Service is terminated after a purchase has been
            made, refund will be issued in accordance to our cancellation and refund policies set forth under the Terms.
        </p>
        <p>
            2.8. In line with our regulatory requirements for anti-money laundering and sanctions screening, we cannot
            register clients, provide our service for travel to or from these countries, receive funds from or send
            money to the following jurisdictions: Cuba, Iran, Syria, North Korea, Crimea.
        </p>
        <h3>Third Party Links</h3>
        <p>
            2.9. Certain content, products and services available via our Service may include materials from
            third-parties.
        </p>
        <p>
            2.10. Third-party links on this site may direct you to third-party websites that are not affiliated with
            PayLater Travel. PayLater Travel are not responsible for examining or evaluating the content or accuracy of
            any third-party materials.
        </p>
        <p>
            2.11. PayLater Travel do not warrant and will not have any liability or responsibility for any third-party
            materials or websites, or for any other materials, products, or services of third-parties.
        </p>
        <p>
            2.12. PayLater Travel are not liable for any harm or damages related to the purchase or use of goods,
            services, resources, content, or any other transactions made in connection with any third- party links.
            Please review Third Party Links carefully and make sure you understand them before you engage in any
            transaction.
        </p>
        <p>
            2.13. Complaints, claims, concerns, or questions regarding third-party linked products should be directed to
            the third-party.
        </p>
        <h3>Comments, Feedback and other Submissions</h3>
        <p>
            2.14. If, at the request of PayLater Travel, you send certain specific submissions (for example contest
            entries) or without a request from PayLater Travel you send creative ideas, suggestions, proposals, plans,
            or other materials, whether online, by email, by postal mail, or otherwise (collectively, &lsquo;Comments
            &rsquo;), you agree that PayLater Travel may, at any time and without restriction, edit, copy, publish,
            distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall
            be under no obligation to maintain any comments in confidence, pay compensation for any comments, or respond
            to any comments.
        </p>
        <p>
            2.15. PayLater Travel may, but have no obligation to, monitor, edit or remove content that we determine, in
            our sole discretion, as being unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene
            or otherwise objectionable or violates any party&rsquo;s intellectual property or these Terms of Service.
        </p>
        <p>
            2.16. You agree that your comments will not violate any right of any third-party, including copyright,
            trademark, privacy, personality or other personal or proprietary right. You further agree that your comments
            will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus
            or other malware that could in any way affect the operation of the Service or any related website.
        </p>
        <p>
            2.17. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise
            mislead PayLater Travel or third-parties as to the origin of any comments. You are solely responsible for
            any comments you make and their accuracy. PayLater Travel takes no responsibility and assume no liability
            for any comments posted by you or any third-party.
        </p>
        <h2>3. The Service</h2>
        <p>
            3.1. PayLater Travel provides a layaway financial arrangement for the customer&rsquo;s procurement of Travel
            Related Products (the Service ). This service is governed by the terms of the PayLater Travel Service
            Agreement as set forth under section 4 of the Terms. Note that &lsquo;layaway&rsquo; is referred to as
            &lsquo;layby&rsquo; in some jurisdictions. For the purpose of this agreement, the term 'layaway' and layby
            are used interchangeably.
        </p>
        <p>
            3.2. Reference to &lsquo;Travel Related Products&rsquo; in this agreement refers to travel products only.
            Travel products may include Airline Tickets, Hotel Accommodation, Tours and other Travel
        </p>
        <p>
            Related products. The PayLater Travel Service is not, in the context of this agreement, a Travel Related
            Product.
        </p>
        <p>
            3.3. The making and confirmation of Travel Related Products on the customer&rsquo;s behalf, are provided to
            customers by PayLater Travel as part of a concierge service. This service is governed by the terms of the
            PayLater Travel Concierge Agreement. PayLater Travel acts with your consent and direction to make and
            confirm your Travel Related Product and pay the Travel Provider, as if you had in fact procured it for
            yourself.
        </p>
        <p>
            3.4. Any changes and amendments of Travel Related Products on the customer&rsquo;s behalf will incur a fee
            of $100 per change, per booking, plus all applicable fees related to the Travel Provider. All change
            requests must be made in writing to bookings@paylatertravel.com no later than 60 days before intended
            travel.
        </p>
        <p>
            3.5. Payment of the non-refundable deposit signifies your consent to be bound by the terms of the PayLater
            Travel Service Agreement and PayLater Travel Concierge Agreement.
        </p>
        <h2>4. The PayLater Travel Service Agreement</h2>
        <p>
            4.1. This agreement relates to the PayLater Travel Service, referring to the layaway financial arrangement
            for the customer&rsquo;s procurement of Travel Related Products.
        </p>
        <p>
            4.2. By using our Service to purchase a Travel Related Product, you provide us with unconditional and
            irrevocable consent and direction to pay the Travel Provider on your behalf in exchange for your agreement
            and obligation to repay or pay to us in accordance with this Agreement, the agreed amounts (which may
            include any applicable taxes, duties or other related amounts charged by an Travel Provider) and on the
            dates outlined in your Payment Schedule, plus any additional applicable Fees, including Late Fees if you
            miss a repayment to us on or before the schedule date.
        </p>
        <p>
            4.3. All prices quoted are in United States dollars (&ldquo;$&rdquo; or &ldquo;USD&rdquo;) unless otherwise
            specifically denoted on the screen.
        </p>
        <p>
            4.4. PayLater Travel makes no representation that airline price sales are identical to prices on individual
            airline sites that may exist from time to time.
        </p>
        <p>
            4.5. Pay Later Travel reserves the right for marketing and commercial reasons to modify prices at its sole
            discretion. In the case where the price of an applicable booking has increased by 5% or more due to reasons
            outside our control, we reserve the right to amend the remaining payment installments by written notice to
            you. You may choose to cancel your booking thereby discontinuing your payments in which case you&rsquo;ll
            not be charged a cancellation fee and will be refunded any amounts paid.
        </p>
        <h3>Our Service Obligations</h3>
        <p>
            4.6. Our Service will allow you to buy your preferred Travel Related Product offered by your chosen Travel
            Provider.
        </p>
        <p>
            4.7. We will provide you with a Payment Schedule at the time of quotation which will be customizable by you
            to the extent that you can choose:
        </p>
        <ol type="a">
            <li>Whether to pay your installments weekly or fortnightly;</li>
            <li>When you would like your installments to start; and/or</li>
            <li>
                How long you would like your layaway period to be (of up to 6 months), with the final balance required
                at least 4 weeks prior to departure.
            </li>
        </ol>
        <p>
            4.8. PayLater Travel will, to the best of our ability endeavor to contact you should payments be missed,
            including via SMS, email or phone.
        </p>
        <p>
            4.9. We will not charge you a late fee, however, your financial institution may charge you a dishonor fee in
            the event of failed payments.
        </p>
        <p>
            4.10. PayLater Travel does not issue credit, nor perform any credit checks. We offer a layaway service only.
        </p>
        <p>4.11. Should you request, PayLater Travel will provide you with receipts of all payments.</p>
        <h3>Obtaining your Travel Related Product</h3>
        <p>
            4.12. We own your Travel Related Product, unencumbered and in trust for you, while you pay off the balance
            owing to PayLater Travel.
        </p>
        <p>
            4.13. Upon the full and final balance being paid, at least 4 weeks prior to your travel, we will provide you
            with your e-ticket. It will be sent to the email address provided to us at the time of booking.
        </p>
        <p>
            4.14. Upon receiving your e-ticket you will be at liberty to access your flight information using your
            preferred Travel Provider platform.
        </p>
        <h3>Confidentiality and use of Personal Information</h3>
        <p>
            4.15. Your submission of personal information through our site or other contact channels such as SMS, email,
            voice, will be governed by the terms of our Privacy Policy.
        </p>
        <p>
            4.16. We will only use your personal information to effect payments with our Third Party Provider and
            confirm your Travel Related Product bookings with your chosen Travel Provider.
        </p>
        <p>
            4.17. Your personal information will be stored on our secure platform only. 4.18. All payment information
            will be encrypted.
        </p>
        <h3>Customer Obligations:</h3>
        <h4>Payment</h4>
        <p>4.19. Payment of at least 10% of your total quote must be paid as a non-refundable deposit.</p>
        <p>4.20. You will provide us with a valid debit or credit card for payment (the Nominated Payment Option).</p>
        <p>
            4.21. By entering into a financial arrangement with PayLater Travel you authorize PayLater Travel to debit
            the installments from your Nominated Payment Option, at the amounts and dates set in the Payment Schedule.
        </p>
        <p>
            4.22. Should any changes to your Nominated Payment Option occur, you agree to promptly update your payment
            information, including card numbers and expiration dates, so that PayLater Travel can complete your
            transactions. If payment details need to be changed, please contact our Customer Service Team at
            bookings@paylatertravel.com.
        </p>
        <p>
            4.23. Where you wish to alter your Payment Schedule, you will notify us in writing, at least 48 hours prior
            to the scheduled payment. You acknowledge that any notifications that are received either within the 48
            hours prior, on the day of or after the scheduled payment may not be canceled or stopped. If you wish to
            alter your Payment Schedule please contact: bookings@paylatertravel.com.
        </p>
        <p>
            4.24. You are responsible for ensuring there is sufficient funds available through your Nominated Payment
            Option. Where a scheduled payment has failed, you are required to contact us to rectify the failed payment.
            If you do not rectify the failed payment before the next payment date, you acknowledge that you may lose
            your reservation or experience price increases.
        </p>
        <p>
            4.25. You agree to allow our third-party payment provider to deduct funds from your Nominated Payment
            Option. If the third-party payment provider is unable to deduct funds from your Nominated Payment Option,
            you acknowledge that it will continue to attempt to deduct those funds until the payment has been effected
            or your booking has been canceled, whichever comes earlier.
        </p>
        <p>4.26. Full payment made at least 4 weeks prior to the date of travel departure.</p>
        <h4>Personal Information</h4>
        <p>
            4.27. You agree to provide current, complete and accurate account information. You agree to promptly update
            your account and other information, including your email address, phone numbers and identification details
            so that PayLater Travel can complete your transactions and contact you as needed.
        </p>
        <h3>Right to refuse service and terminate:</h3>
        <p>
            4.28. In the event that your booking is terminated as a result of two missed payments, a cancellation fee
            will apply as follows:
        </p>
        <ol type="a">
            <li>A PayLater Travel reasonable service charge fee, plus:</li>
            <li>
                100% of any payments made on your behalf to third party suppliers to the extent that such payments are
                non-refundable to us by the third-party supplier.
            </li>
        </ol>
        <p>
            4.29. PayLater Travel reserves the right to deduct any outstanding money owed from payments you have already
            made.
        </p>
        <p>
            4.30. If your layaway installments do not cover the lay-by termination fee, PayLater Travel is entitled to
            recover the outstanding amount as a debt owed.
        </p>
        <p>
            4.31. A compulsory price increase may occur if the number of missed installments prevents PayLater Travel
            from ticketing your travel arrangements.
        </p>
        <p>
            4.32. If you are struggling with your installments or are experiencing financial hardship, please contact
            our team at +1 (888) 974 0329 to discuss your available options.
        </p>
        <p>4.33. PayLater Travel reserves the right to refuse service to anyone, for any reason, at any time.</p>
        <h2>5. The PayLater Travel Concierge Agreement</h2>
        <p>
            5.1. This agreement relates to PayLater Travel Concierge Services , being the making, arrangement and
            confirmation of travel related products with Travel Providers on behalf of our customers.
        </p>
        <p>
            5.2. By choosing your preferred flight option and Travel Provider through the Product Listing page, you
            consent to PayLater Travel making your chosen travel arrangement with your chosen Travel Provider on your
            behalf, as if you were in fact procuring it for yourself, and you agree to be bound by the relevant Travel
            Provider&rsquo;s contract terms and conditions.
        </p>
        <p>
            5.3. By choosing your preferred flight option and Travel Provider through the Product Listing page, you
            acknowledge that PayLater Travel does not have any control over, and are not responsible or liable for, your
            travel related product. PayLater Travel is only responsible for their Service and related obligations (see
            cl 4.6 - 4.18). You acknowledge, as if you had procured the travel related product yourself, that you bear
            the risk of your chosen Travel Provider canceling, changing, overbooking, delaying or dishonoring your
            travel arrangement, entering into voluntary administration, declaring bankruptcy or committing an act of
            insolvency.
        </p>
        <p>
            5.4. Accordingly, your legal rights in connection with the Travel Related Product are against the specific
            Travel Provider and are not against us (except to the extent a problem is either caused by a fault on our
            part in relation to the financial arrangement or the concierge service). We can provide you reasonable
            assistance with any claims against the Airline Provider and provide you with copies of the relevant service
            provider terms and conditions upon request.
        </p>
        <h3>Booking and Arranging Travel Related Products</h3>
        <h4>Entering your booking details</h4>
        <p>5.5. We make your travel arrangements in line with the details you provide to us.</p>
        <p>
            5.6. If you become aware of any error or omission in booking details you must notify us as soon as
            practicable.
        </p>
        <p>
            5.7. Should errors or omissions be made by reason of an error or omission made by PayLater Travel, any
            charges incurred with be borne at our expense.
        </p>
        <p>
            5.8. Any charges incurred by reason of an error or omission on your behalf will be borne by you. This
            includes, but is not limited to:
        </p>
        <ol type="a">
            <li>Total number of people travelling</li>
            <li>First and last names of all passengers as they appear on valid government-issued documents.</li>
            <li>Ages of all passengers as they appear on valid government-issued documents.</li>
        </ol>
        <h4>Obtaining additional travel features/products/services</h4>
        <p>
            5.9. You are required to bear the costs for any additional products or services in relation to your Travel
            Related Product that is not expressly stated as being included within the quote you receive from PayLater
            Travel.
        </p>
        <p>
            5.10. Prices quoted and charged by Pay Later Travel do not include personal expenditure such as, without
            limitation, travel insurance, emergency evacuation costs, drinks, excess baggage, passport &amp; visa fees,
            gratuities, and vaccination costs.
        </p>
        <h4>Travel Provider cancellations, changes, overbookings and delays</h4>
        <p>
            5.11. You acknowledge that PayLater Travel is not responsible for any revisions your chosen Travel Provider
            may make to your booking. All bookings are subject to supplier availability and should a Travel Provider
            make changes to your booking, which can occur at any time, we will make reasonable attempts to advise you of
            your options.
        </p>
        <p>
            5.12. You acknowledge, as if you had procured the travel related product yourself, that you are responsible
            for reorganizing your travel arrangements and bearing the relevant costs, subject to any arrangement or
            terms and conditions offered to you by your Airline or Insurance Provider, should you miss your flight or
            should your Travel Provider cancel, delay or overbook your chosen travel arrangement.
        </p>
        <p>
            5.13. If a Travel Provider cancels your travel product, PayLater Travel will only be liable to provide a
            refund to you to the extent that PayLater Travel actually receives a cash refund from the relevant Travel
            Provider. Where cash refunds are due to you from a Travel Provider, PayLater Travel reserves the right to
            deduct a fair and reasonable fee for the provision of our services.
        </p>
        <p>
            5.14. If a Travel Provider cancels your travel product, they may offer travel credit with the Travel
            Provider in accordance with their terms and conditions. While we do not control these commercial terms,
            PayLater Travel will provide reasonable assistance to convert your flights into airline credit that can be
            redeemed with the Travel Provider.
        </p>
        <h3>Refunding and/or Canceling Travel Related Products</h3>
        <p>
            5.15. It is your responsibility to notify us as soon as possible of a cancellation, or intention to cancel,
            the Travel Related Product. Cancellations can be made at any time, but please note that cancellation fees
            will apply.
        </p>
        <p>
            5.16. All cancellation requests must be made in writing no later than 30 days before travel to
            bookings@paylatertravel.com. Cancellation requests cannot be accepted over the phone or via live chat.
        </p>
        <p>
            5.17. A cooling off period will be provided where no cancellation fees will apply. The cooling off period
            will commence when your deposit is paid and conclude 24 hours later. Once the cooling off period has ended,
            the following cancellation fees will apply:
        </p>
        <ol type="a">
            <li>A PayLater Travel cancellation fee, plus:</li>
            <li>
                100% of any payments made on your behalf to third party suppliers to the extent that such payments are
                non-refundable to us by the third-party supplier.
            </li>
        </ol>
        <p>
            5.18. PayLater Travel will only be liable to provide a refund to you to the extent that PayLater Travel
            actually receives a refund from the relevant Travel Provider. Where refunds are due to you from a Travel
            Provider, we will provide reasonable assistance to you in claiming such funds from that Travel Provider.
        </p>
        <p>
            5.19. Should a Travel Provider not provide a refund, Paylater Travel will provide reasonable assistance to
            convert your flights into airline credit that can be redeemed with the Travel Provider.
        </p>
        <p>
            5.20. Once the Travel Provider has issued a refund, we will issue a refund to your Nominated Payment Option
            within 10 business days of receiving the cash refund from the Travel Provider.
        </p>
        <h3>Travel Provider Insolvency</h3>
        <p>
            5.21. Should a Travel Provider enter into voluntary administration, declare bankruptcy or commit an act of
            insolvency, you acknowledge that we have no control over whether the Travel Provider will complete the
            transaction, nor can we ensure you will be able to recuperate money from the Travel Provider.
        </p>
        <p>
            5.22. PayLater Travel will only be liable to provide a refund to you to the extent that it receives a refund
            from the relevant Travel Provider.
        </p>
        <p>
            5.23. You acknowledge that the obligation to repay or pay PayLater Travel the agreed amounts on the dates
            outlined in your Payment Schedule remains, even in the event of a Travel Provider becoming insolvent or
            entering in voluntary administration, subject to our discretion.
        </p>
        <p>
            5.24. Upon cancellation of the transportation through no fault of your own, all sums paid to PayLater Travel
            by the Travel Provider for services not performed under the contract between PayLater Travel and you will
            be, unless you otherwise advise us in writing, promptly refunded by PayLater Travel to you.
        </p>
        <h2>6. Your Consumer Rights</h2>
        <p>
            6.1. As a consumer, you have certain rights under consumer protection legislation pursuant to the laws of
            your residency state (Consumer Rights).
        </p>
        <p>
            6.2. Nothing in this Agreement is intended to exclude, restrict or modify any of your Consumer Rights,
            including by limiting our liability or imposing liability on you in a manner which would be considered
            unfair under the relevant consumer protection laws. Below are important disclosures of your rights under
            layaway plans.
        </p>
        <h3>IMPORTANT DISCLOSURES OF YOUR RIGHTS UNDER LAYAWAY PLANS</h3>
        <h3>PayLater Travel Disclosure Statement</h3>
        <p>
            By agreeing to the PayLater Travel Service Agreement, PayLater Travel Concierge Agreement, and the Terms
            (collectively &ldquo;Layaway Plan Agreement&rdquo;), you will have entered into a layaway financial
            arrangement for your procurement of Travel Related Products through PayLater Travel. Under Federal and State
            laws we are required to make disclosures concerning a layaway plan.
        </p>
        <p>
            By agreeing to the Layaway Plan Agreement set forth herein, you acknowledge that you have been provided in
            writing, with the following:
        </p>
        <h4>Terms and Conditions</h4>
        <ul>
            <li>A copy of the terms of the Layaway Plan Agreement.</li>
            <li>
                The name, address, telephone number of the airline carrier or any other provider of services under the
                Layaway Plan Agreement.
            </li>
            <li>
                A copy of the terms and conditions of any and all third-party agreements that are relevant to your
                purchase.
            </li>
        </ul>
        <h4>Prices and Costs</h4>
        <ul>
            <li>
                A description of the services provided by PayLater Travel and all other services available in
                conjunction with the travel.
            </li>
            <li>
                An itemization of charges that you are required to pay including service fees, the cost of the travel
                services, and additional service charges as provided in the Terms and Conditions.
            </li>
            <li>The exact deposit amount to be paid and any remaining amount due.</li>
            <li>A payment schedule and/or payment period in which the services must be paid.</li>
            <li>The location and number of the trust account created for your payment as applicable.</li>
            <li>PayLater Travel will provide you with receipts for each payment.</li>
        </ul>
        <h4>Cancellations, Refunds, and Exchanges</h4>
        <ul>
            <li>
                A copy of the cancellation, refund, and exchange policy for the services you have on layaway with
                PayLater Travel. This includes the right PayLater Travel has to deduct late charges for payment and
                amounts which may be refundable under the policy.
            </li>
            <li>A copy of the conditions of cancellation between PayLater Travel and the airline carrier.</li>
        </ul>
        <p>
            By agreeing to the Layaway Plan Agreement and by signing below, you certify that the information provided on
            the Layaway Plan Agreement are true and correct expressions of the disclosures made to you by PayLater
            Travel.
        </p>
        <h3>No warranty</h3>
        <p>
            6.3. We do not give any express warranty or guarantee as to the suitability, reliability or availability of
            our Service, or Travel Providers, or of the content on our Website.
        </p>
        <p>
            6.4. Subject to your Consumer Rights set out in clause 6, we do not give any implied warranties or
            guarantees.
        </p>
        <p>
            6.5. Except as required by law, we do not guarantee continuous, uninterrupted or secure access to our
            Service, and we make no representations or warranties regarding the amount of time needed to complete
            processing of payments or refunds.
        </p>
        <h3>Limitations of Liability</h3>
        <p>
            6.6. We arrange your holiday, which will be provided by other suppliers. PayLater Travel does not itself
            provide the transport, accommodation, meals or other facilities described on this website that you may
            receive on your holiday, all of which are provided by air and land carriers, cruise operators, hoteliers or
            suppliers of other services as principals.
        </p>
        <p>
            6.7. We bear no responsibility as an intermediary and cannot be held responsible in case of damage caused to
            you by any of our suppliers.
        </p>
        <h2>7. Indemnification</h2>
        <p>
            7.1. You agree to indemnify, defend and hold harmless PayLater Travel and our parent, subsidiaries,
            affiliates, partners, officers, directors, agents, contractors, licensors, service providers,
            subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable
            legal fees, made by any third-party due to or arising out of your breach of this Agreement or the documents
            any third-party may incorporate by reference, or your violation of any law or the rights of a third-party.
        </p>
        <h2>8. Termination</h2>
        <p>
            8.1. This Agreement is effective unless and until terminated by either you or us. You may terminate these
            Terms of Service at any time by notifying PayLater Travel in writing that you no longer wish to use our
            Services, or when you cease using our site.
        </p>
        <p>
            8.2. Any obligations and liabilities of the parties incurred prior to the termination date shall survive the
            termination of this agreement for all purposes.
        </p>
        <p>
            8.3. If in our sole judgment you fail, or we reasonably suspect that you have failed, to comply with any
            term or provision of these Terms of Service, PayLater Travel also may terminate this agreement at any time
            with written notice to you, effective immediately. Upon termination, cancellation and refund policies as set
            forth herein shall apply.
        </p>
        <h2>9. Interpretation</h2>
        <p>9.1. Headings are for convenience only and do not affect interpretation.</p>
        <p>
            9.2. Any failure by PayLater Travel to exercise or enforce any right or provision of this Agreement shall
            not constitute a waiver of such right or provision.
        </p>
        <p>
            9.3. This Agreement and any policies or operating rules posted by PayLater Travel on this site or in respect
            to the Service constitutes the entire Agreement and understanding between you and PayLater Travel and
            governs your use of the Service, superseding any prior or contemporaneous agreements, communications and
            proposals, whether oral or written, between you and PayLater Travel (including, but not limited to, any
            prior versions of the Terms of Service).
        </p>
        <p>
            9.4. Any ambiguities in the interpretation of these Terms of Service shall not be construed against the
            drafting party.
        </p>
        <p>
            9.5. In the event that any provision of these Terms of Service is determined to be unlawful, void or
            unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable
            law, and the unenforceable portion shall be deemed to be severed from these Terms of Service. Such
            determination shall not affect the validity and enforceability of any other remaining provisions.
        </p>
        <h2>10. Dispute Resolution</h2>
        <p>
            10.1. In the event of any dispute under this Agreement the parties agree to negotiate in good faith to
            resolve the dispute.
        </p>
        <p>
            10.2. At PayLater Travel&rsquo;s sole discretion, it may require you to submit any disputes arising from
            these Terms, including disputes arising from or concerning their interpretation, violation, invalidity,
            non-performance, or termination, to final and binding arbitration under the rules of arbitration of the
            American Arbitration Association applying Delaware law.
        </p>
        <h2>11. Governing Law</h2>
        <p>
            11.1. The agreement will be governed by and interpreted in accordance with the laws of Delaware. You
            irrevocably submit to the exclusive jurisdiction of the courts of the State of Delaware.
        </p>
        <h2>12. Contact Information</h2>
        <p>12.1. Questions about this agreement or any Terms of Service should be sent to: PayLater Travel</p>
        <p>Email: info@paylatertravel.com.au</p>
        <p>Phone number: +1 (888) 974 0329</p>
        <p>Address: 107 Spring Street, Seattle, WA 98104, United States</p>
    </div>
);

const Term = () => {
    const bookingsEmail = getSiteValueByLang("bookingsEmail");

    return (
        <div>
            <Helmet>
                <title>Terms | Book Now, Pay Later</title>
            </Helmet>
            <div className="term-content">
                <div className="container">
                    <h1 className="title">PayLater Travel Terms And Conditions</h1>
                    {IS_USA_SITE ? (
                        USTerms
                    ) : (
                        <div className="block-content">
                            <h2>1. General</h2>
                            <p>
                                1.1. This website is operated by Sky Pay Group Pty Ltd (ACN 621 979 431) trading as
                                PayLater Travel. Throughout this site the terms “we”, “us” and “our” refer to PayLater
                                Travel.
                            </p>
                            <p>
                                1.2. PayLater Travel provides access to information, tools and services from the
                                PayLater Travel website, phone and email communication conditional upon your acceptance
                                of the following terms, conditions, policies and notices (collectively referred to as
                                the “<strong>Terms</strong>”). Your access of our website or engagement with us through
                                email or phone represents your agreement to be bound by these Terms and are inclusive of
                                any amendments which PayLater Travel reserves the right to impose at any time.
                            </p>
                            <p>
                                1.3. Unless the contrary intention appears, amendments to the Terms will take effect
                                immediately from the date and time they are published on the website and will be
                                incorporated into this page. Terms apply to all users of our site and services,
                                including, without limitation, users who are browsers, vendors, customers, merchants
                                and/or contributors of content.
                            </p>
                            <p>
                                1.4. By engaging with the tools and services on the PayLater Travel website you confirm
                                that you are either over the age of 18. By making a purchase through the Website you
                                warrant that you are over 18 and that you have the legal right and ability to enter into
                                a legally binding agreement with us. No persons under the age of eighteen (18) are
                                permitted to use the Website or to sign up as a member of the website.
                            </p>
                            <h2>2. Terms of Website Usage</h2>
                            <h3>Website content</h3>
                            <p>
                                2.1. You must not reproduce, duplicate, copy, sell, resell or exploit any written
                                material, visual material or code on the website, including these terms and conditions,
                                without express written permission from PayLater Travel.
                            </p>
                            <h3>Search Function, Product Listings &amp; Quotes</h3>
                            <p>
                                2.2. The search function on our website retrieves general information about flight paths
                                and pricings made publicly available from various airline ticketing providers. Our
                                systems use this general information to detail a quote for the <Trans>layaway</Trans>{" "}
                                financial arrangement we are prepared to offer to our customers for their preferred
                                flight options (referred to as “ <strong>Product Listings</strong>
                                ”). Whilst we strive to ensure our system regularly updates, collects and publishes
                                accurate and up to date information for each Product Listing, we cannot and do not
                                warrant that all information published on PayLater Travel will be current, accurate,
                                complete and/or free of system glitches.
                            </p>
                            <p>
                                2.3. Where we become aware of any error, omission or system glitch, we reserve the right
                                to correct that error or omission and notify affected customers immediately. Should you
                                be a customer directly affected by a system glitch you will be notified within 5 days of
                                your booking. If notifying you within 5 days of making your booking we reserve the right
                                to reject your transaction and provide a full refund.
                            </p>
                            <p>
                                2.4. All Product Listings are subject to revisions in line with any changes in price,
                                time, dates, flight path or other associated ticketing details that each airline
                                ticketing provider makes from time to time.
                            </p>
                            <p>2.5. Quotes are subject to change prior to your deposit being received.</p>
                            <h3>Prohibited Uses</h3>
                            <p>
                                2.6. In addition to any other prohibitions as set forth in this Agreement, you are
                                prohibited from using the site or its content:
                            </p>
                            <ol type="a">
                                <li>for any unlawful purpose;</li>
                                <li>to solicit others to perform or participate in any unlawful acts;</li>
                                <li>
                                    to violate any international, federal, provincial or state regulations, rules, laws,
                                    or local ordinances or local laws;
                                </li>
                                <li>
                                    to infringe upon or violate our intellectual property rights or the intellectual
                                    property rights of others;
                                </li>
                                <li>
                                    to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or
                                    discriminate based on gender, sexual orientation, religion, ethnicity, race, age,
                                    national origin, or disability;
                                </li>
                                <li>to submit false or misleading information;</li>
                                <li>
                                    to upload or transmit viruses or any other type of malicious code that will or may
                                    be used in any way that will affect the functionality or operation of the Service or
                                    of any related website, other websites, or the Internet;
                                </li>
                                <li>to collect or track the personal information of others;</li>
                                <li>to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
                                <li>for any obscene or immoral purpose; or</li>
                                <li>
                                    to interfere with or circumvent the security features of the Service or any related
                                    website, other websites, or the Internet.
                                </li>
                            </ol>
                            <p>
                                2.7. PayLater Travel reserves the right to terminate your use of the Service or any
                                related website for violating any of the prohibited uses.
                            </p>
                            <p>
                                2.8. In line with our regulatory requirements for anti-money laundering and sanctions
                                screening, we cannot register clients, provide our service for travel to or from these
                                countries, receive funds from or send money to the following jurisdictions: Cuba, Iran,
                                Syria, North Korea, Crimea.
                            </p>
                            <h3>Third Party Links</h3>
                            <p>
                                2.9. Certain content, products and services available via our Service may include
                                materials from third-parties.
                            </p>
                            <p>
                                2.10. Third-party links on this site may direct you to third-party websites that are not
                                affiliated with PayLater Travel. PayLater Travel are not responsible for examining or
                                evaluating the content or accuracy of any third-party materials.
                            </p>
                            <p>
                                2.11. PayLater Travel do not warrant and will not have any liability or responsibility
                                for any third-party materials or websites, or for any other materials, products, or
                                services of third-parties.
                            </p>
                            <p>
                                2.12. PayLater Travel are not liable for any harm or damages related to the purchase or
                                use of goods, services, resources, content, or any other transactions made in connection
                                with any third-party links. Please review Third Party Links carefully and make sure you
                                understand them before you engage in any transaction.
                            </p>
                            <p>
                                2.13. Complaints, claims, concerns, or questions regarding third-party linked products
                                should be directed to the third-party.
                            </p>
                            <h3>Comments, Feedback and other Submissions</h3>
                            <p>
                                2.14. If, at the request of PayLater Travel, you send certain specific submissions (for
                                example contest entries) or without a request from PayLater Travel you send creative
                                ideas, suggestions, proposals, plans, or other materials, whether online, by email, by
                                postal mail, or otherwise (collectively, ‘<strong>Comments</strong> ’), you agree that
                                PayLater Travel may, at any time and without restriction, edit, copy, publish,
                                distribute, translate and otherwise use in any medium any comments that you forward to
                                us. We are and shall be under no obligation to maintain any comments in confidence, pay
                                compensation for any comments, or respond to any comments.
                            </p>
                            <p>
                                2.15. PayLater Travel may, but have no obligation to, monitor, edit or remove content
                                that we determine, in our sole discretion, as being unlawful, offensive, threatening,
                                libellous, defamatory, pornographic, obscene or otherwise objectionable or violates any
                                party’s intellectual property or these Terms of Service.
                            </p>
                            <p>
                                2.16. You agree that your comments will not violate any right of any third-party,
                                including copyright, trademark, privacy, personality or other personal or proprietary
                                right. You further agree that your comments will not contain libelous or otherwise
                                unlawful, abusive or obscene material, or contain any computer virus or other malware
                                that could in any way affect the operation of the Service or any related website.
                            </p>
                            <p>
                                2.17. You may not use a false e-mail address, pretend to be someone other than yourself,
                                or otherwise mislead PayLater Travel or third-parties as to the origin of any comments.
                                You are solely responsible for any comments you make and their accuracy. PayLater Travel
                                takes no responsibility and assume no liability for any comments posted by you or any
                                third-party.
                            </p>
                            <h2>3. The Service</h2>
                            <p>
                                3.1. PayLater Travel provides a{" "}
                                <strong>
                                    {" "}
                                    <Trans>layaway</Trans> financial arrangement{" "}
                                </strong>{" "}
                                for the customer’s procurement of Travel Related Products (the <strong>Service</strong>{" "}
                                ). This service is governed by the terms of the{" "}
                                <strong>PayLater Travel Service Agreement</strong>. Note that ‘<Trans>layaway</Trans>’
                                is referred to as ‘<Trans>layaway</Trans>’ in some jurisdictions. For the purpose of
                                this agreement, the terma &apos;layaway&apos; and layby are used interchangeably.
                            </p>
                            <p>
                                3.2. Reference to ‘Travel Related Products’ in this agreement refers to travel products
                                only. Travel products may include Airline Tickets, Hotel Accommodation, Tours and other
                                Travel Related products. The PayLater Travel Service is not, in the context of this
                                agreement, a Travel Related Product.
                            </p>
                            <p>
                                3.3. The making and confirmation of Travel Related Products on the customer’s behalf,
                                are provided to customers by PayLater Travel as part of a concierge service. This
                                service is governed by the terms of the
                                <strong>PayLater Travel Concierge Agreement. </strong> PayLater Travel acts with your
                                consent and direction to make and confirm your Travel Related Product and pay the Travel
                                Provider, as if you had in fact procured it for yourself.
                            </p>
                            <p>
                                3.4. Any changes and amendments of Travel Related Products on the customer’s behalf will
                                incur a fee of $100 per change, per booking, plus all applicable fees related to the
                                Travel Provider. All change requests must be made in writing to {bookingsEmail} no later
                                than 60 days before intended travel.
                            </p>
                            <p>
                                3.5. Payment of the non-refundable deposit signifies your consent to be bound by the
                                terms of the <strong>PayLater Travel Service Agreement</strong> and{" "}
                                <strong>PayLater Travel Concierge Agreement</strong>.
                            </p>
                            <h2>4. The PayLater Travel Service Agreement</h2>
                            <p>
                                4.1. This agreement relates to the <strong>PayLater Travel Service</strong>, referring
                                to the{" "}
                                <strong>
                                    {" "}
                                    <Trans>layaway</Trans> financial arrangement{" "}
                                </strong>{" "}
                                for the customer’s procurement of Travel Related Products.
                            </p>
                            <p>
                                4.2. By using our Service to purchase a Travel Related Product, you provide us with
                                unconditional and irrevocable consent and direction to pay the Travel Provider on your
                                behalf in exchange for your agreement and obligation to repay or pay to us in accordance
                                with this Agreement, the agreed amounts (which may include any applicable taxes, duties
                                or other related amounts charged by an Travel Provider) and on the dates outlined in
                                your Payment Schedule, plus any additional applicable Fees, including Late Fees if you
                                miss a repayment to us on or before the schedule date.
                            </p>
                            <p>
                                4.3. All prices quoted are in Australian dollars unless otherwise specifically denoted
                                on the screen.
                            </p>
                            <p>
                                4.4. PayLater Travel makes no representation that airline price sales are identical to
                                prices on individual airline sites that may exist from time to time.
                            </p>
                            <p>
                                4.5. PayLater Travel reserves the right for marketing and commercial reasons to modify
                                prices at its sole discretion. In the case where the price of an applicable booking has
                                increased by 10% or more due to reasons outside our control, we reserve the right to
                                amend the remaining payment <Trans>installments</Trans> by written notice to you. You
                                may choose to cancel your booking thereby discontinuing your payments in which case
                                you’ll not be charged a cancellation fee and will be refunded any amounts paid.
                            </p>

                            <h3>Our Service Obligations</h3>
                            <p>
                                <a name="_Ref38308373">
                                    4.6. Our Service will allow you to buy your preferred Travel Related Product offered
                                    by your chosen Travel Provider.
                                </a>
                            </p>
                            <p>
                                4.7. We will provide you with a Payment Schedule at the time of quotation which will be{" "}
                                <Trans>customizable</Trans> by you to the extent that you can choose:
                            </p>
                            <ol type="a">
                                <li>
                                    Whether to pay your <Trans>installments</Trans> weekly or fortnightly;
                                </li>
                                <li>
                                    When you would like your <Trans>installments</Trans> to start; and/or
                                </li>
                                <li>
                                    How long you would like your <Trans>layaway</Trans> period to be (of up to 6
                                    months), with the final balance required at least 4 weeks prior to departure.
                                </li>
                            </ol>
                            <p>
                                4.8. PayLater Travel will, to the best of our ability endeavour to contact you should
                                payments be missed, including via SMS, email or phone.
                            </p>
                            <p>
                                4.9. We will not charge you a late fee, however, your financial institution may charge
                                you a <Trans>dishonor</Trans> fee in the event of failed payments.
                            </p>
                            <p>
                                4.10. PayLater Travel does not issue credit, nor perform any credit checks. We offer a{" "}
                                <Trans>layaway</Trans> (also known as a layaway) service only.
                            </p>
                            <h3>Obtaining your Travel Related Product</h3>
                            <p>
                                4.11. We hold your Travel Related Product, unencumbered and in trust for you, while you
                                pay off the balance owing to PayLater Travel.
                            </p>
                            <p>
                                4.12. Upon the full and final balance has being paid,{" "}
                                <strong>at least 4 weeks prior to your travel</strong> , we will provide you with your
                                e-ticket. It will be sent to your email address.
                            </p>
                            <p>
                                4.13. Upon receiving your e-ticket you will be at liberty to access your flight
                                information using your preferred Travel Provider platform.
                            </p>
                            <h3>Confidentiality and use of Personal Information</h3>
                            <p>
                                4.14. Your submission of personal information through our site or other contact channels
                                such as SMS, email, voice, will be governed by the terms of our Privacy Policy.
                            </p>
                            <p>
                                4.15. We will only use your personal information to effect payments with our Third Party
                                Provider and your Travel Related Product bookings with your chosen Travel Provider.
                            </p>
                            <p>4.16. Your personal information will be stored on our secure platform only.</p>
                            <p>4.17. All payment information will be encrypted.</p>
                            <h3>Customer Obligations:</h3>
                            <h4>Payment</h4>
                            <p>
                                4.18. Payment of at least 10% of your total quote must be paid as a non-refundable
                                deposit.
                            </p>
                            <p>
                                4.19. You will provide us with a valid debit or credit card for payment (
                                <strong>the Nominated Payment Option</strong>
                                ).
                            </p>
                            <p>
                                4.20. By entering into a financial arrangement with PayLater Travel you{" "}
                                <Trans>authorize</Trans> PayLater Travel to debit the <Trans>installments</Trans> from
                                your Nominated Payment Option, at the amounts and dates set in the Payment Schedule.
                            </p>
                            <p>
                                4.21. Should any changes to your Nominated Payment Option occur, you agree to promptly
                                update your payment information, including card numbers and expiration dates, so that
                                PayLater Travel can complete your transactions. If payment details need to be changed,
                                please contact our Customer Service Team at {bookingsEmail}.
                            </p>
                            <p>
                                4.22. Where you wish to alter your Payment Schedule, you will notify us in writing, at
                                least 48 hours prior to the scheduled payment. You acknowledge that any notifications
                                that are received either within the 48 hours prior, on the day of or after the scheduled
                                payment may not be <Trans>canceled</Trans> or stopped. If you wish to alter your Payment
                                Schedule please contact: {bookingsEmail}.
                            </p>
                            <p>
                                4.23. You are responsible for ensuring there is sufficient funds available through your
                                Nominated Payment Option. Where a scheduled payment has failed, you are required to
                                contact us to rectify the failed payment. If you do not rectify the failed payment
                                before the next payment date, you acknowledge that you may lose your reservation or
                                experience price increases.
                            </p>
                            <p>
                                4.24. You agree to allow our third-party payment provider to deduct funds from your
                                Nominated Payment Option. If the third-party payment provider is unable to deduct funds
                                from your Nominated Payment Option, you acknowledge that it will continue to attempt to
                                deduct those funds until the payment has been effected or your booking has been{" "}
                                <Trans>canceled</Trans>, whichever comes earlier.
                            </p>
                            <p>4.25. Full payment made at least 4 weeks prior to the date of travel departure.</p>
                            <h4>Personal Information</h4>
                            <p>
                                4.26. You agree to provide current, complete and accurate account information. You agree
                                to promptly update your account and other information, including your email address,
                                phone numbers and identification details so that PayLater Travel can complete your
                                transactions and contact you as needed.
                            </p>
                            <h3>Right to refuse service and terminate:</h3>
                            <p>
                                4.27. In the event that your booking is terminated as a result of two missed payments, a
                                cancellation fee will apply as follows:
                            </p>
                            <ol type="a">
                                <li>A PayLater Travel reasonable service charge fee, plus:</li>
                                <li>
                                    100% of any payments made on your behalf to third party suppliers to the extent that
                                    such payments are non-refundable to us by the third-party supplier.
                                </li>
                            </ol>
                            <p>
                                4.28. PayLater Travel reserves the right to deduct any outstanding money owed from
                                payments you have already made.
                            </p>
                            <p>
                                4.29. If your <Trans>layaway</Trans> <Trans>installments</Trans> do not cover the lay-by
                                termination fee, PayLater Travel is entitled to recover the outstanding amount as a debt
                                owed.
                            </p>
                            <p>
                                4.30. A compulsory price increase may occur if the number of missed
                                <Trans>installments</Trans> prevents PayLater Travel from ticketing your travel
                                arrangements.
                            </p>
                            <p>
                                4.31. If you are struggling with your <Trans>installments</Trans> or are experiencing
                                financial hardship, please contact our team at <Trans>teamPhoneNumber</Trans> to discuss
                                your available options.
                            </p>
                            <p>
                                4.32. PayLater Travel reserves the right to refuse service to anyone, for any reason, at
                                any time.
                            </p>
                            <h2>5. The PayLater Travel Concierge Agreement</h2>
                            <p>
                                5.1. This agreement relates to <strong>PayLater Travel Concierge Services</strong> ,
                                being the{" "}
                                <strong> making, arrangement and confirmation of travel related products </strong> with
                                Travel Providers on behalf of our customers.
                            </p>
                            <p>
                                5.2. By choosing your preferred flight option and Travel Provider through the Product
                                Listing page, you consent to PayLater Travel making your chosen travel arrangement with
                                your chosen Travel Provider on your behalf, as if you were in fact procuring it for
                                yourself, and you agree to be bound by the relevant Travel Provider’s contract terms and
                                conditions.
                            </p>
                            <p>
                                5.3. By choosing your preferred flight option and Travel Provider through the Product
                                Listing page, you acknowledge that PayLater Travel does not have any control over, and
                                are not responsible or liable for, your travel related product. PayLater Travel is only
                                responsible for their Service and related obligations (see cl 4.6 - 4.17). You
                                acknowledge, as if you had procured the travel related product yourself, that you bear
                                the risk of your chosen Travel Provider <Trans>canceling</Trans>, changing, overbooking,
                                delaying or <Trans>dishonor</Trans>ing your travel arrangement, entering into voluntary
                                administration, declaring bankruptcy or committing an act of insolvency.
                            </p>
                            <p>
                                5.4. Accordingly, your legal rights in connection with the Travel Related Product are
                                against the specific Travel Provider and are not against us (except to the extent a
                                problem is either caused by a fault on our part in relation to the financial arrangement
                                or the concierge service). We can provide you reasonable assistance with any claims
                                against the Airline Provider and provide you with copies of the relevant service
                                provider terms and conditions upon request.
                            </p>
                            <h3>Booking and Arranging Travel Related Products</h3>
                            <h4>Entering your booking details</h4>
                            <p>5.5. We make your travel arrangements in line with the details you provide to us.</p>
                            <p>
                                5.6. If you become aware of any error or omission in booking details you must notify us
                                as soon as practicable.
                            </p>
                            <p>
                                5.7. Should errors or omissions be made by reason of an error or omission made by
                                PayLater Travel, any charges incurred with be borne at our expense.
                            </p>
                            <p>
                                5.8. Any charges incurred by reason of an error or omission on your behalf will be borne
                                by you. This includes, but is not limited to:
                            </p>
                            <ol type="a">
                                <li>Total number of people travelling</li>
                                <li>
                                    First and last names of all passengers as they appear on valid government-issued
                                    documents.
                                </li>
                                <li>Ages of all passengers as they appear on valid government-issued documents.</li>
                            </ol>
                            <h4>Obtaining additional travel features/products/services</h4>
                            <p>
                                5.9. You are required to bear the costs for any additional products or services in
                                relation to your Travel Related Product that is not expressly stated as being included
                                within the quote you receive from PayLater Travel.
                            </p>
                            <p>
                                5.10. Prices quoted and charged by Pay Later Travel do not include personal expenditure
                                such as travel insurance, emergency evacuation costs, drinks, excess baggage, passport
                                &amp; visa fees, gratuities, and vaccination costs.
                            </p>
                            <h4>Travel Provider cancellations, changes, overbookings and delays</h4>
                            <p>
                                5.11. You acknowledge that PayLater Travel is not responsible for any revisions your
                                chosen Travel Provider may make to your booking. All bookings are subject to supplier
                                availability and should a Travel Provider make changes to your booking, which can occur
                                at any time, we will make reasonable attempts to advise you of your options.
                            </p>
                            <p>
                                5.12. You acknowledge, as if you had procured the travel related product yourself, that
                                you are responsible for <Trans>reorganizing</Trans> your travel arrangements and bearing
                                the relevant costs, subject to any arrangement or terms and conditions offered to you by
                                your Airline or Insurance Provider, should you miss your flight or should your Travel
                                Provider cancel, delay or overbook your chosen travel arrangement.
                            </p>
                            <p>
                                5.13. If a Travel Provider cancels your travel product, PayLater Travel will only be
                                liable to provide a refund to you to the extent that PayLater Travel actually receives a
                                cash refund from the relevant Travel Provider. Where cash refunds are due to you from a
                                Travel Provider, PayLater Travel reserves the right to deduct a fair and reasonable fee
                                for the provision of our services.
                            </p>
                            <p>
                                5.14. If a Travel Provider cancels your travel product, they may offer travel credit
                                with the Travel Provider in accordance with their terms and conditions. While we do not
                                control these commercial terms, PayLater Travel will provide reasonable assistance to
                                convert your flights into airline credit, that can be redeemed with the Travel Provider.
                            </p>
                            <h3 id="cancellation-policy">
                                Refunding and/or <Trans>canceling</Trans> Travel Related Products
                            </h3>
                            <p>
                                5.15. It is your responsibility to notify us as soon as possible of a cancellation, or
                                intention to cancel, the Travel Related Product. Cancellations can be made at any time,
                                but please note that cancellation fees will apply.
                            </p>
                            <p align="left">
                                5.16. All cancellation requests must be made in writing no later than 30 days before
                                travel to {bookingsEmail}. Cancellation requests cannot be accepted over the phone or
                                via live chat.
                            </p>
                            <p>
                                5.17. A cooling off period will be provided where no cancellation fees will apply. The
                                cooling off period will commence when your deposit is paid and conclude 24 hours later.
                                Once the cooling off period has ended, the following cancellation fees will apply:
                            </p>
                            <ol type="a">
                                <li>A PayLater Travel cancellation fee, plus:</li>
                                <li>
                                    100% of any payments made on your behalf to third party suppliers to the extent that
                                    such payments are non-refundable to us by the third-party supplier.
                                </li>
                            </ol>
                            <p>
                                5.18. PayLater Travel will only be liable to provide a refund to you to the extent that
                                PayLater Travel actually receives a refund from the relevant Travel Provider. Where
                                refunds are due to you from a Travel Provider, we will provide reasonable assistance to
                                you in claiming such funds from that Travel Provider.
                            </p>
                            <p>
                                5.19. Should an Travel Provider not provide a refund, Paylater Travel will provide
                                reasonable assistance to convert your flights into airline credit that can be redeemed
                                with the Travel Provider.
                            </p>
                            <p>
                                5.20. Once the Travel Provider has issued a refund, we will issue a refund to your
                                Nominated Payment Option.
                            </p>
                            <h3>Travel Provider Insolvency</h3>
                            <p>
                                5.21. Should an Travel Provider enter into voluntary administration, declare bankruptcy
                                or commit an act of insolvency, you acknowledge that we have no control over whether the
                                Travel Provider will complete the transaction, nor can we ensure you will be able to
                                recuperate money from the Travel Provider.
                            </p>
                            <p>
                                5.22. PayLater Travel will only be liable to provide a refund to you to the extent that
                                it receives a refund from the relevant Travel Provider.
                            </p>
                            <p>
                                5.23. You acknowledge that the obligation to repay or pay PayLater Travel the agreed
                                amounts on the dates outlined in your Payment Schedule remains, even in the event of an
                                Travel Provider becoming insolvent or entering in voluntary administration, subject to
                                our discretion.
                            </p>
                            <h2>6. Your Consumer Rights</h2>
                            <p>
                                6.1. As a consumer, you have certain rights under consumer protection legislation
                                (Consumer Rights). These Consumer Rights include:
                            </p>
                            <ol type="a">
                                <li>
                                    statutory guarantees under the Australian Consumer Law that goods will be of
                                    acceptable quality, match their description and be fit for any purpose made known to
                                    the consumer, and that services supplied will be provided with due care and skill
                                    and be reasonably fit for any specified purpose.
                                </li>
                                <li>
                                    non-excludable implied warranties that financial services will be provided with due
                                    care and skill.
                                </li>
                            </ol>
                            <p>
                                6.2. Nothing in this Agreement is intended to exclude, restrict or modify any of your
                                Consumer Rights, including by limiting our liability or imposing liability on you in a
                                manner which would be considered unfair under the relevant consumer protection laws.
                            </p>
                            <h3>No warranty</h3>
                            <p>
                                6.3. We do not give any express warranty or guarantee as to the suitability, reliability
                                or availability of our Service, or Travel Providers, or of the content on our Website.
                            </p>
                            <p>
                                6.4. Subject to your Consumer Rights set out in clause 6, we do not give any implied
                                warranties or guarantees.
                            </p>
                            <p>
                                6.5. Except as required by law, we do not guarantee continuous, uninterrupted or secure
                                access to our Service, and we make no representations or warranties regarding the amount
                                of time needed to complete processing of payments or refunds.
                            </p>
                            <h3>Limitations of Liability</h3>
                            <p>
                                6.6. We arrange your holiday, which will be provided by other suppliers. PayLater Travel
                                does not itself provide the transport, accommodation, meals or other facilities
                                described on this website that you may receive on your holiday, all of which are
                                provided by air and land carriers, cruise operators, hoteliers or suppliers of other
                                services as principals.
                            </p>
                            <p>
                                6.7. We bear no responsibility as an intermediary and cannot be held responsible in case
                                of damage caused to you by any of our suppliers.
                            </p>
                            <h2>7. Indemnification</h2>
                            <p>
                                7.1. You agree to indemnify, defend and hold harmless PayLater Travel and our parent,
                                subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors,
                                service providers, subcontractors, suppliers, interns and employees, harmless from any
                                claim or demand, including reasonable legal fees, made by any third-party due to or
                                arising out of your breach of this Agreement or the documents any third-party may
                                incorporate by reference, or your violation of any law or the rights of a third-party.
                            </p>
                            <h2>8. Termination</h2>
                            <p>
                                8.1. This Agreement is effective unless and until terminated by either you or us. You
                                may terminate these Terms of Service at any time by notifying PayLater Travel in writing
                                that you no longer wish to use our Services, or when you cease using our site.
                            </p>
                            <p>
                                8.2. Any obligations and liabilities of the parties incurred prior to the termination
                                date shall survive the termination of this agreement for all purposes.
                            </p>
                            <p>
                                8.3. If in our sole judgment you fail, or we suspect that you have failed, to comply
                                with any term or provision of these Terms of Service, PayLater Travel also may terminate
                                this agreement at any time without notice and you will remain liable for all amounts due
                                up to and including the date of termination; and/or accordingly may deny you access to
                                our Services (or any part thereof).
                            </p>
                            <h2>9. Interpretation</h2>
                            <p>9.1. Headings are for convenience only and do not affect interpretation.</p>
                            <p>
                                9.2. Any failure by PayLater Travel to exercise or enforce any right or provision of
                                this Agreement not constitute a waiver of such right or provision.
                            </p>
                            <p>
                                9.3. This Agreement and any policies or operating rules posted by PayLater Travel on
                                this site or in respect to the Service constitutes the entire Agreement and
                                understanding between you and PayLater Travel and governs your use of the Service,
                                superseding any prior or contemporaneous agreements, communications and proposals,
                                whether oral or written, between you and PayLater Travel (including, but not limited to,
                                any prior versions of the Terms of Service).
                            </p>
                            <p>
                                9.4. Any ambiguities in the interpretation of these Terms of Service shall not be
                                construed against the drafting party.
                            </p>
                            <p>
                                9.5. In the event that any provision of these Terms of Service is determined to be
                                unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the
                                fullest extent permitted by applicable law, and the unenforceable portion shall be
                                deemed to be severed from these Terms of Service. Such determination shall not affect
                                the validity and enforceability of any other remaining provisions.
                            </p>
                            <h2>10. Dispute Resolution</h2>
                            <p>
                                10.1. In the event of any dispute under this Agreement the parties agree to negotiate in
                                good faith to resolve the dispute.
                            </p>
                            <p>
                                10.2. Any dispute or difference whatsoever arising out of or in connection with this
                                Agreement, which cannot be resolved by the parties, shall be submitted to mediation in
                                accordance with, and subject to, The Institute of Arbitrators &amp; Mediators Australia,
                                Mediation and Conciliation Rules.
                            </p>
                            <h2>11. Governing Law</h2>
                            <p>
                                11.1. The agreement will be governed by and interpreted in accordance with the laws of
                                New South Wales, Australia. You irrevocably submit to the exclusive jurisdiction of the
                                courts of the State of New South Wales.
                            </p>
                            <h2>12. Contact Information</h2>
                            <p>
                                12.1. Questions about this agreement or any Terms of Service should be sent to{" "}
                                <a href="mailto:info@paylatertravel.com.au">info@paylatertravel.com.au</a>.
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Term;
