import { Box, Grid, Popover } from "@mui/material";
import { addWeeks, differenceInDays, format } from "date-fns";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { LinkText, Text } from "../../../components/common/typography/Style";
import { _formatMoney, _url } from "../../../config/utils";
import { ColorTheme } from "../../../styles/color";
import { logAmplitudeEvent } from "../../../utils/SiteUtils";
import PaymentLinkDatePicker from "./PaymentLinkDatePicker";

export const PaymentStartDate = ({ reviewData, setReviewData, onUpdatePaymentPlan, paymentPlan, frequency }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { mobileView } = useGetWindowSize();

    const paymentsDisplayData = useMemo(() => {
        if (reviewData.plan === 1) {
            return { count: paymentPlan.weeks, installmentAmount: paymentPlan.pricePerWeek / 100 };
        }
        return { count: paymentPlan.fortnights, installmentAmount: paymentPlan.pricePerFortnight / 100 };
    }, [reviewData.plan, paymentPlan]);

    const onChange = (startDate) => {
        const now = new Date();
        logAmplitudeEvent("paymentStartDateFilled", {
            daysFromToday: differenceInDays(new Date(startDate), now)
        });
        const formattedStartDate = moment(startDate).set({
            hour: now.getHours(),
            minute: now.getMinutes(),
            second: now.getSeconds(),
            millisecond: now.getMilliseconds()
        });
        setReviewData({
            ...reviewData,
            startPayment: moment(formattedStartDate),
            error: {
                startPayment: false
            }
        });
        onUpdatePaymentPlan(
            {
                ...reviewData,
                startPayment: moment(formattedStartDate),
                error: {
                    startPayment: false
                }
            },
            false
        );
        setAnchorEl(null);
    };
    const onBlur = () => {
        if (reviewData.startPayment === "") {
            setReviewData({
                ...reviewData,
                error: {
                    startPayment: true
                }
            });
        }
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Grid container flexWrap={"nowrap"} gap={"10px"} alignItems={"stretch"}>
                <Grid item ml={"7px"}>
                    <Box
                        marginTop={"8px"}
                        height={"84%"}
                        gap={"5px"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"column"}
                    >
                        <img style={{ height: 8, width: 8 }} src={_url("assets/icons/blue_dot.svg")} />
                        <Box
                            height={"100%"}
                            borderRight={`1px ${ColorTheme.grayNeutral[300]} dashed`}
                            position={"relative"}
                            right={"4px"}
                        />
                        <img style={{ height: 8, width: 8 }} src={_url("assets/icons/dot_hole.svg")} />
                    </Box>
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container flexWrap={"nowrap"} justifyContent={"space-between"}>
                                <Grid item>
                                    <Text size={mobileView ? "sm" : "md"} textweight={"bold"}>
                                        Starting {format(new Date(reviewData.startPayment), "MMMM dd")}{" "}
                                        <LinkText
                                            itemType="button"
                                            onClick={handleClick}
                                            textsize={mobileView ? "14px" : "16px"}
                                        >
                                            Edit
                                        </LinkText>
                                    </Text>
                                </Grid>
                                <Grid item>
                                    <Text size={"md"} textweight={"bold"} textAlign={"right"}>
                                        ${_formatMoney(paymentsDisplayData.installmentAmount)}
                                    </Text>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mt={"10px"}>
                            <Text
                                visibility={paymentsDisplayData.count - 1 !== 0 ? "initial" : "hidden"}
                                size={mobileView ? "xs" : "sm"}
                                textweight={"regular"}
                                mb={"10px"}
                            >
                                + {paymentsDisplayData.count - 1} more payments
                            </Text>
                        </Grid>
                        <Grid item xs={12} mt={"10px"}>
                            <Grid container flexWrap={"nowrap"} justifyContent={"space-between"}>
                                <Grid item xs={10}>
                                    <Text size={"sm"} textweight={"regular"}>
                                        Final Payment{" "}
                                        {paymentsDisplayData.count - 1 !== 0
                                            ? format(
                                                  addWeeks(
                                                      new Date(reviewData.startPayment),
                                                      frequency === "Weekly"
                                                          ? paymentPlan.weeks - 1
                                                          : (paymentPlan.fortnights - 1) * 2
                                                  ),
                                                  "MMM dd"
                                              )
                                            : format(new Date(reviewData.startPayment), "MMM dd")}
                                    </Text>
                                </Grid>
                                <Grid item xs={2}>
                                    <Text size={mobileView ? "sm" : "md"} textweight={"regular"} textAlign={"right"}>
                                        ${_formatMoney(paymentsDisplayData.installmentAmount)}
                                    </Text>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Popover
                id={"datepicker"}
                open={!!anchorEl}
                anchorEl={anchorEl}
                PaperProps={{
                    style: {
                        visibility: "hidden"
                    }
                }}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <PaymentLinkDatePicker
                    id="single-date"
                    isError={reviewData.error.startPayment}
                    onFieldBlur={onBlur}
                    label="Start date:"
                    dateFormat="D MMM YYYY"
                    date={reviewData.startPayment ? reviewData.startPayment : ""}
                    numberOfMonths={1}
                    type={"secondary"}
                    errorMessage={"You must select a start date"}
                    handleDateChange={onChange}
                    minimumDate={moment()}
                    isDisabled
                    maximumDate={moment().add("14", "days")}
                />
            </Popover>
        </>
    );
};
