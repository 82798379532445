import { loadStripe } from "@stripe/stripe-js";
import HomeFeatureUs from "../components/homepage/HomeFeatureUs";
import UsaPrivacyPolicy from "../components/privacypolicy/UsaPrivacyPolicy";
import { CAR_RENTAL_LINK_US, HOTEL_LINK_US, US_BOOKING_REVIEW } from "../constants";
import { REVIEW_USA } from "../constants/AppConstants";
import { US_RECENT_BOOKINGS, US_SITE_POPULAR_ARRIVAL, US_SITE_POPULAR_DEPARTURE } from "../constants/Options";
import FaqUs from "../containers/faq/FaqUs";
import { usaPopularDestination } from "../containers/redesign/homepage/constant";
import ReviewSupportUs from "../containers/review/support/ReviewSupportUs";

const stripePromiseUS = loadStripe(process.env.REACT_APP_STRIPE_PK_US);

export const US_CONSTANT = {
    placesAutocompletedLocation: new window.google.maps.LatLng(31, -100),
    addressCountry: "United States",
    billingAddressSelectStates: "US",
    getPaymentFrequency: "Bi-weekly",
    passportOrigin: "US",
    stripePromise: stripePromiseUS,
    bookingsEmail: <a href="mailto:bookings@paylatertravel.com"> bookings@paylatertravel.com</a>,
    desktopPicker: "MM/dd/yyyy",
    phoneInputWrapper: "us",
    trustpilot: REVIEW_USA,
    homeflightMockup: "assets/images/paylaterflightswebusa.png",
    question: <p>If you have any questions, pleae contact us via chat or email support@paylatertravel.com.</p>,
    countryCode: "102",
    bookingReview: US_BOOKING_REVIEW,
    currencyCode: "USD",
    stateOrigin: "US",
    questionChat: (
        <p>
            Questions? Contact us via chat or call us on <a href="tel:+1 888 974 0329">+1 (888) 974 0329</a>
        </p>
    ),
    supportMail: "support@paylatertravel.com",
    madeWithLove: "",
    privacyPolicy: <UsaPrivacyPolicy />,
    formikMuiDateFormat: "MM/DD/YYYY",
    faq: <FaqUs />,
    hotelLink: HOTEL_LINK_US,
    cardRental: CAR_RENTAL_LINK_US,
    phoneAutoFormat: true,
    homeFeature: <HomeFeatureUs />,
    paymentPlan: (value) => `x${value}`,
    reviewSupport: <ReviewSupportUs />,
    gaMeasuremntId: "G-P3RJJR6Z0Z",
    showTravelInsurance: true,
    popularDestination: usaPopularDestination,
    popularDepartureDestination: US_SITE_POPULAR_DEPARTURE,
    popularArrivalDestination: US_SITE_POPULAR_ARRIVAL,
    recentBookings: US_RECENT_BOOKINGS
};
