import axios from 'axios';
import { API_URL } from './index';

const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    // if (response) {
    //   if (response.status === 403 || response.status === 401) {
    //     store.dispatch(logoutAuth());
    //   }
    // }

    return Promise.reject(error);
  },
);

export default instance;
