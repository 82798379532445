import Styled from "@emotion/styled";

export const UpfrontContainer = Styled.div`
    background-color: #E5F9FF;
    color: #00B4DC;
    border-radius: 30px;
    padding: 5px 10px;
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
`;

export const FlightResultBadgeContainer = Styled.div`
    background-color: #E6F3E5;
    color: #69BC5F;
    border-radius: 30px;
    padding: 5px 10px;
    width: fit-content;
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
`;
