import amplitude from "amplitude-js";
import { getYear } from "date-fns";
import i18next from "i18next";
import { range } from "lodash";
import moment from "moment";
import { IS_USA_SITE, SHOW_REDESIGN_NAVBAR } from "../constants";
import { AU_CONSTANT } from "./AUConstants";
import { US_CONSTANT } from "./USConstants";

export const getAddDays = (days) => {
    const result = new Date();
    result.setDate(result.getDate() + days);
    return result;
};

export const getDiffDays = (startDate, endDate) => {
    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD");
    return moment.duration(end.diff(start)).asDays();
};

export const getCurrency = () => {
    return getSiteValueByLang("stateOrigin");
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const capitalizeFirstLetterForEachWord = (string) =>
    string.toLowerCase().replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });

export const windowScroll = (id) => {
    window.scrollTo({
        top: document.getElementById(id).getBoundingClientRect().top + window.scrollY - (80 + 68),
        behavior: "smooth"
    });
};

export const yearsDropdown = range(1900, getYear(new Date()) + 1, 1);
export const monthsDropdown = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

export const formatCurrency = (amount) => amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

const formatNumberUSA = (phoneNumber) => {
    const phoneFormat = phoneNumber && phoneNumber.replace(/\D/g, "").match(/(\d{1})(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !phoneFormat[2]
        ? phoneFormat[1]
        : `+${phoneFormat[1]} (${phoneFormat[2]})${phoneFormat[3] ? ` ${phoneFormat[3]}-${phoneFormat[4]}` : ""}`;
};

const formatNumberAU = (phoneNumber) => {
    const phoneFormat = phoneNumber && phoneNumber.replace(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,3})/);
    return !phoneFormat[2]
        ? phoneFormat[1]
        : `+${phoneFormat[1]} ${phoneFormat[2]}${phoneFormat[3] ? ` ${phoneFormat[3]} ${phoneFormat[4]}` : ""}`;
};

export const formatPhoneNumber = (phoneNumber) => {
    if (IS_USA_SITE) {
        return formatNumberUSA(phoneNumber);
    }
    return formatNumberAU(phoneNumber);
};

export const getScrollPosition = (className) => {
    const rdrRoundEdgeElement = document.querySelector(className);
    const rect = rdrRoundEdgeElement.getBoundingClientRect();
    const distanceFromTop = rect.top + window.scrollY - 200;
    return distanceFromTop;
};

export const updateSiteLang = () => {
    i18next.changeLanguage(IS_USA_SITE ? "enUS" : "enAU");
};

export const siteValueConstant = {
    enUs: US_CONSTANT,
    enAu: AU_CONSTANT
};

export const getSiteValueByLang = (key, value) => {
    return value
        ? siteValueConstant[IS_USA_SITE ? "enUs" : "enAu"][key](value)
        : siteValueConstant[IS_USA_SITE ? "enUs" : "enAu"][key];
};

export const logAmplitudeEvent = (eventName, eventProperties = {}) => {
    amplitude.getInstance().logEvent("dev-" + eventName, eventProperties);
};

export const pathIncludesAnyKeyword = (path) => {
    if (path === "/") {
        return true;
    }
    const pathParts = path.split("/");

    return SHOW_REDESIGN_NAVBAR.some((keyword) => pathParts.includes(keyword));
};

export const pathIncludesNavbarStyling = (path) => {
    if (path === "/") {
        return false;
    }
    const pathParts = path.split("/");
    return SHOW_REDESIGN_NAVBAR.some((keyword) => pathParts.includes(keyword));
};

export const getPreviousResignedSearchStatus = () => {
    return localStorage.getItem("plt_display_redesigned_search");
};

export const setNewDesignStatusLocally = (status) => {
    return localStorage.setItem("plt_display_redesigned_search", status);
};
