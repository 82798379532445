import { Grid } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import { _url } from "../../config/utils";
import { US_TRUSTPILOT_URL, WISTIA_EMBED_ID } from "../../constants";
import { whilistEmbedColor } from "../../styles/color";
import WistiaEmbed from "../WistiaEmbed";

const HomeFeatureUs = () => {
    return (
        <Container>
            <Grid mb={"40px"} container justifyContent={"center"}>
                <a
                    onClick={() => {
                        logAmplitudeEvent("clicksTrustPilotRating");
                    }}
                    href={US_TRUSTPILOT_URL}
                    target={"_blank"}
                    rel="noreferrer"
                >
                    <img
                        style={{ width: "300px", height: "100%", cursor: "pointer" }}
                        src={_url("assets/images/trustpilot.png")}
                    />
                </a>
            </Grid>
            <Row style={{ marginBottom: 80 }}>
                <Col md={{ size: 8, offset: 2 }}>
                    <h2 className="text-center" style={{ marginBottom: 40 }}>
                        How it works
                    </h2>
                    <WistiaEmbed
                        hashedId={WISTIA_EMBED_ID}
                        controlsVisibleOnLoad={false}
                        settingsControl={false}
                        playerColor={whilistEmbedColor}
                        plugin={null}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4} xs={12} className="IconBox text-center">
                    <img alt="search" src={_url("assets/icons/phone.png")} />
                    <h3>Find great deals</h3>
                    <p>Search from thousands of destinations. Book with a 10%-15% deposit.</p>
                </Col>
                <Col md={4} xs={12} className="IconBox text-center">
                    <img alt="yoga" src={_url("assets/icons/calendar.png")} />
                    <h3>Set a payment plan</h3>
                    <p>Pay weekly or bi-weekly for up to 26 weeks. No interest. No credit checks.</p>
                </Col>
                <Col md={4} xs={12} className="IconBox text-center">
                    <img alt="hero" src={_url("assets/icons/sunset.png")} />
                    <h3>Book now, pay later</h3>
                    <p>Once all payments are made you&apos;ll receive your e-tickets within 24 hours.</p>
                </Col>
            </Row>
        </Container>
    );
};

export default HomeFeatureUs;
