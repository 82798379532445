import { loadStripe } from "@stripe/stripe-js";
import HomeFeatureAu from "../components/homepage/HomeFeatureAu";
import AuPrivacyPolicy from "../components/privacypolicy/AuPrivacyPolicy";
import { AU_TRUSTPILOT_URL, CAR_RENTAL_LINK_AU, HOTEL_LINK_AU } from "../constants";
import { REVIEW_AU } from "../constants/AppConstants";
import { AU_RECENT_BOOKINGS, AU_SITE_POPULAR_ARRIVAL, AU_SITE_POPULAR_DEPARTURE } from "../constants/Options";
import FaqAu from "../containers/faq/FaqAu";
import { auPopularDestination } from "../containers/redesign/homepage/constant";
import ReviewSupportAu from "../containers/review/support/ReviewSupportAu";

const stripePromiseAU = loadStripe(process.env.REACT_APP_STRIPE_PK_AU);

export const AU_CONSTANT = {
    placesAutocompletedLocation: new window.google.maps.LatLng(-34, 151),
    addressCountry: "Australia",
    billingAddressSelectStates: "US",
    getPaymentFrequency: "Fortnightly",
    passportOrigin: "AU",
    stripePromise: stripePromiseAU,
    bookingsEmail: <a href="mailto:bookings@paylatertravel.com.au"> bookings@paylatertravel.com.au</a>,
    desktopPicker: "dd/MM/yyyy",
    phoneInputWrapper: "au",
    trustpilot: REVIEW_AU,
    homeflightMockup: "assets/images/paylaterflightsweb.png",
    question: (
        <p>
            If you have any questions please contact on <a href="tel:1300 399 902">1300 399 902</a> or email
            support@paylatertravel.com.
        </p>
    ),
    countryCode: "28",
    bookingReview: AU_TRUSTPILOT_URL,
    currencyCode: "AUD",
    stateOrigin: "AU",
    questionChat: (
        <p>
            Questions? Chat or contact us: <a href="tel:1300 399 902">1300 399 902</a>
        </p>
    ),
    supportMail: "support@paylatertravel.com.au",
    madeWithLove: "Made with love in Sydney Australia.",
    privacyPolicy: <AuPrivacyPolicy />,
    formikMuiDateFormat: "DD/MM/YYYY",
    faq: <FaqAu />,
    hotelLink: HOTEL_LINK_AU,
    cardRental: CAR_RENTAL_LINK_AU,
    phoneAutoFormat: false,
    homeFeature: <HomeFeatureAu />,
    paymentPlan: (value) => `${value} fortnight(s)`,
    reviewSupport: <ReviewSupportAu />,
    gaMeasuremntId: "G-Q2KV8PSEVF",
    showTravelInsurance: false,
    popularDestination: auPopularDestination,
    popularDepartureDestination: AU_SITE_POPULAR_DEPARTURE,
    popularArrivalDestination: AU_SITE_POPULAR_ARRIVAL,
    recentBookings: AU_RECENT_BOOKINGS
};
