/* eslint-disable react/button-has-type */
import { Grid, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";
import { MobileFiltersHeader } from "./components/MobileFiltersHeader";
import { ArrivalTimeOptions, DepartureTimeOptions, StopsOptions } from "./Utils";

export const FlightFilters = ({
    searchResult,
    searchState,
    setSearchState,
    onClearFilter,
    toggleCarriersFilter,
    toggleStopFilter,
    toggleDepartureFilter,
    toggleArrivalFilter,
    showReturnFilter
}) => {
    const carriers = isEmpty(searchResult.carriers) ? [] : Object.keys(searchResult.carriers);
    const carrierStopFilter = searchResult.carrierStopFilter;
    const carrierArrivalFilter = searchResult.carrierArrivalFilter;
    const carrierDepartureFilter = searchResult.carrierDepartureFilter;

    return (
        <div>
            <div className={`filters ${searchState.filterToggled ? "opened" : ""}`}>
                <MobileFiltersHeader searchState={searchState} setSearchState={setSearchState} />

                {searchResult.carriersVisibilityFilter && (
                    <div className="filters-accordian filters-carriers">
                        <div className="filters-accordian__heading">Airlines</div>
                        <div className="filters-carriers-controls">
                            <button
                                onClick={onClearFilter}
                                disabled={searchResult.carriersVisibilityFilter.length === 0}
                            >
                                Clear all
                            </button>
                        </div>
                        <div className="filters-accordian__body">
                            {carriers.map((key) => (
                                <div key={key} className="filter">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={key}
                                            onChange={() => toggleCarriersFilter(key)}
                                            checked={!!searchResult.carriersVisibilityFilter.includes(key)}
                                        />
                                        <span>{searchResult.carriers[key].toLowerCase()}</span>
                                    </label>
                                </div>
                            ))}
                            <div className="filter">
                                <label>
                                    <input
                                        type="checkbox"
                                        value="COMBINATIONS"
                                        onChange={() => toggleCarriersFilter("COMBINATIONS")}
                                        checked={
                                            !!(
                                                searchResult.hasAirlineCombinations &&
                                                searchResult.carriersVisibilityFilter.includes("COMBINATIONS")
                                            )
                                        }
                                        disabled={!searchResult.hasAirlineCombinations}
                                    />
                                    <span>Airline Combinations</span>
                                </label>
                            </div>
                        </div>
                        <div>
                            <Grid container mt={"10px"}>
                                <Grid item xs={12} mb={"10px"}>
                                    <Typography variant={"body2"}>Stops</Typography>
                                </Grid>
                                {StopsOptions.map((item) => (
                                    <Grid item xs={12} key={item.value}>
                                        <div className="filter">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value={item.value}
                                                    checked={carrierStopFilter.includes(item.value)}
                                                    onChange={() => toggleStopFilter(item.value)}
                                                />
                                                <span>{item.label}</span>
                                            </label>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                        <div>
                            <Grid container mt={"10px"}>
                                <Grid item xs={12} mb={"10px"}>
                                    <Typography variant={"body2"}>Departure time</Typography>
                                </Grid>
                                {DepartureTimeOptions.map((item) => (
                                    <Grid item xs={12} key={item.id}>
                                        <div className="filter">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value={item.id}
                                                    checked={carrierDepartureFilter.includes(item.id)}
                                                    onChange={() => toggleDepartureFilter(item.id)}
                                                />
                                                <span>{item.title}</span>
                                            </label>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                        {showReturnFilter && (
                            <div>
                                <Grid container mt={"10px"}>
                                    <Grid item xs={12} mb={"10px"}>
                                        <Typography variant={"body2"}>Return time</Typography>
                                    </Grid>
                                    {ArrivalTimeOptions.map((item) => (
                                        <Grid item xs={12} key={item.id}>
                                            <div className="filter">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        value={item.id}
                                                        checked={carrierArrivalFilter.includes(item.id)}
                                                        onChange={() => toggleArrivalFilter(item.id)}
                                                    />
                                                    <span>{item.title}</span>
                                                </label>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div
                className="filter-button btn btn-default btn-block d-md-block d-lg-none"
                onClick={() =>
                    setSearchState({
                        ...searchState,
                        filterToggled: true
                    })
                }
            >
                Filters
            </div>
        </div>
    );
};
