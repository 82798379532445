/* eslint-disable unused-imports/no-unused-imports */
import { Close } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SecondaryDefaultButton } from "../../../components/common/button/ButtonStyle";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { Text } from "../../../components/common/typography/Style";
import LoginModal from "../../../components/header/LoginModal";
import { _url } from "../../../config/utils";
import { TOGGLE_LOGIN_MODAL, logoutUser } from "../../../redux/modules/auth";
import { ColorTheme } from "../../../styles/color";
import { pathIncludesNavbarStyling } from "../../../utils/SiteUtils";
import AccountButton from "./AccountButton";

const navItems = [
    {
        label: "How it works",
        url: "/how-it-works"
    },
    {
        label: "FAQ",
        url: "/faq"
    }
];

function Navbar(props) {
    const { windows } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const location = useLocation();
    const loginModal = useSelector((state) => state.auth.loginModal);
    const { mobileView } = useGetWindowSize();
    const listenScrollEvent = (event) => {
        if (window.scrollY < 73) {
            return setIsScrolled(false);
        } else if (window.scrollY > 70) {
            return setIsScrolled(true);
        }
    };
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);

        return () => window.removeEventListener("scroll", listenScrollEvent);
    }, []);

    const drawer = () => (
        <Box sx={{ textAlign: "center" }}>
            <Grid p={"16px"} flexWrap={"nowrap"} container justifyContent={"space-between"}>
                <img
                    alt="logo"
                    width="160px"
                    onClick={() => navigate("/")}
                    style={{ cursor: "pointer" }}
                    src={
                        mobileView || isScrolled
                            ? _url("assets/images/plt-blue-logo.png")
                            : _url("assets/images/plt-white-logo.png")
                    }
                />
                <IconButton
                    color="inherit"
                    edge="start"
                    onClick={() => {
                        handleDrawerToggle();
                    }}
                    sx={{ display: { sm: "none" } }}
                >
                    <Close style={{ color: ColorTheme.base.black }} />
                </IconButton>
            </Grid>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.label} disablePadding>
                        <ListItemButton sx={{ textAlign: "center" }}>
                            <ListItemText primary={item.label} onClick={() => navigate(item.url)} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem style={{ justifyContent: "center" }}>
                    {props.auth.isAuthenticated ? (
                        <AccountButton
                            logoutUser={() => {
                                dispatch(logoutUser());
                            }}
                            isScrolled={isScrolled || pathIncludesNavbarStyling(path)}
                            auth={props.auth}
                        />
                    ) : (
                        <SecondaryDefaultButton
                            onClick={() => {
                                handleDrawerToggle();
                                dispatch({
                                    type: TOGGLE_LOGIN_MODAL
                                });
                            }}
                            style={{ width: "141px" }}
                        >
                            Login
                        </SecondaryDefaultButton>
                    )}
                </ListItem>
            </List>
        </Box>
    );

    const container = windows !== undefined ? () => window().document.body : undefined;

    const path = location.pathname;
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                sx={{
                    background: mobileView || isScrolled || pathIncludesNavbarStyling(path) ? "white" : "inherit",
                    boxShadow: "none",
                    position: mobileView ? "sticky" : "fixed"
                }}
                component="nav"
            >
                <Toolbar>
                    <Grid p={"16px"} flexWrap={"nowrap"} container justifyContent={"space-between"}>
                        <img
                            alt="logo"
                            width="160px"
                            onClick={() => navigate("/")}
                            style={{ cursor: "pointer" }}
                            src={
                                mobileView || isScrolled || pathIncludesNavbarStyling(path)
                                    ? _url("assets/images/plt-blue-logo.png")
                                    : _url("assets/images/plt-white-logo.png")
                            }
                        />
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ display: { sm: "none" } }}
                        >
                            <MenuIcon style={{ color: ColorTheme.base.black }} />
                        </IconButton>
                        <Box alignItems={"center"} display={["none", "none", "flex"]} gap={"24px"}>
                            {navItems.map((item) => (
                                <Text
                                    size={"md"}
                                    textweight={"medium"}
                                    style={{ cursor: "pointer" }}
                                    key={item.label}
                                    onClick={() => navigate(item.url)}
                                    textcolor={
                                        isScrolled || pathIncludesNavbarStyling(path)
                                            ? ColorTheme.base.black
                                            : ColorTheme.base.white
                                    }
                                >
                                    {item.label}
                                </Text>
                            ))}
                            {!props.auth.isAuthenticated ? (
                                <SecondaryDefaultButton
                                    onClick={() => {
                                        dispatch({
                                            type: TOGGLE_LOGIN_MODAL
                                        });
                                    }}
                                    style={{ width: "141px" }}
                                >
                                    Login
                                </SecondaryDefaultButton>
                            ) : (
                                <AccountButton
                                    logoutUser={() => {
                                        dispatch(logoutUser());
                                    }}
                                    isScrolled={isScrolled || pathIncludesNavbarStyling(path)}
                                    auth={props.auth}
                                />
                            )}
                        </Box>
                    </Grid>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    anchor="top"
                    onClose={handleDrawerToggle}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box" }
                    }}
                >
                    {drawer()}
                </Drawer>
            </nav>
            {loginModal && (
                <LoginModal
                    toggleLoginModal={() =>
                        dispatch({
                            type: TOGGLE_LOGIN_MODAL
                        })
                    }
                />
            )}
        </Box>
    );
}

export default Navbar;
