import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Label, Nav, NavItem, NavLink } from "reactstrap";
import { IS_USA_SITE, STAYS_2_0, STAYS_NEW_STICKER } from "../../constants";
import { getReferrals } from "../../redux/modules/referrals";
import { updateSearch } from "../../redux/modules/search";
import { logAmplitudeEvent } from "../../utils/SiteUtils";
import { NewSticker } from "../common/Style";
import StaySearchForm from "../stays/searchform/StaySearchForm";
import MultiCityControls from "./MultiCityControls";
import SingleDestinationControls from "./SingleDestinationControls";
import { getHotelDestination } from "./util";

const SearchForm = ({ isLogAmplitude = true, ...props }) => {
    const selectorSearchQuery = useSelector((state) => state.search.query);
    const selectorActiveTab = useSelector((state) => state.search.activeTab || 2);
    const [activeTab, setActiveTab] = useState(selectorActiveTab);
    const [searchQuery, setSearchQuery] = useState(selectorSearchQuery);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [form, setForm] = useState({
        origin: { isTouched: false },
        destination: { isTouched: false },
        departureDate: { isTouched: false },
        returnDate: { isTouched: false },
        adults: { isTouched: false }
    });

    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth);
    const balance = useSelector((state) => state.referrals.balance);
    const dispatch = useDispatch();
    const params = useParams();

    const updateWindowDimensions = () => setWindowWidth(window.innerWidth);

    useEffect(() => {
        props.selectedTab && setActiveTab(props.selectedTab);
    }, [props.selectedTab]);
    useEffect(() => {
        if (auth.isAuthenticated) {
            dispatch(getReferrals("flight"));
        }
        getHotelDestination().then((res) => {
            if (params.location && params.state) {
                setSearchQuery((prev) => ({
                    ...prev,
                    hotels: {
                        ...prev.hotels,
                        destination: res.find(
                            (x) =>
                                x.split("_")[0].toLowerCase() === params.location.replace("-", " ") &&
                                x.split("_")[1].replace("/", "").toLowerCase() === params.state
                        )
                    }
                }));
            }
        });
        window.addEventListener("resize", updateWindowDimensions);
        const multiCityLegsForm = [];
        if (searchQuery.flights.multiCityLegs) {
            searchQuery.flights.multiCityLegs.forEach(() => {
                multiCityLegsForm.push({
                    origin: { isTouched: false },
                    destination: { isTouched: false },
                    departureDate: { isTouched: false }
                });
            });
        }

        setForm((prev) => ({
            ...prev,
            multiCityLegs: multiCityLegsForm
        }));
    }, []);

    const changeSearchType = (type) => {
        setSearchQuery((prev) => ({
            ...prev,
            flights: {
                ...prev.flights,
                type
            }
        }));
    };

    const updateLocation = ({ field, location }) => {
        setSearchQuery((prev) => ({
            ...prev,
            flights: {
                ...prev.flights,
                [field]: location
            }
        }));
        setForm((prev) => ({
            ...prev,
            [field]: { isTouched: true }
        }));
    };

    const addMultiCityLeg = () => {
        const { multiCityLegs } = searchQuery.flights;
        if (multiCityLegs.length < 5) {
            setSearchQuery((prev) => ({
                ...prev,
                flights: {
                    ...prev.flights,
                    multiCityLegs: [
                        ...multiCityLegs,
                        {
                            origin: multiCityLegs[multiCityLegs.length - 1].destination,
                            destination: null,
                            departureDate: ""
                        }
                    ]
                }
            }));
            setForm((prev) => ({
                ...prev,
                multiCityLegs: [
                    ...prev.multiCityLegs,
                    {
                        origin: { isTouched: false },
                        destination: { isTouched: false },
                        departureDate: { isTouched: false }
                    }
                ]
            }));
        }
    };

    const removeMultiCityLeg = (index) => {
        const { multiCityLegs } = searchQuery.flights;
        const multiCityLegsForm = form.multiCityLegs;

        if (multiCityLegs.length > 2) {
            multiCityLegs.splice(index, 1);
            multiCityLegsForm.splice(index, 1);
            setSearchQuery((prev) => ({
                ...prev,
                flights: {
                    ...prev.flights,
                    multiCityLegs
                }
            }));
            setForm((prev) => ({
                ...prev,
                multiCityLegs: multiCityLegsForm
            }));
        }
    };

    const updateMultiCityLocation = ({ field, location, index }) => {
        const { multiCityLegs } = searchQuery.flights;
        const multiCityLegsForm = form.multiCityLegs;

        multiCityLegs[index][field] = location || null;
        multiCityLegsForm[index][field].isTouched = true;

        setSearchQuery((prev) => ({
            ...prev,
            flights: {
                ...prev.flights,
                multiCityLegs
            }
        }));
        setForm((prev) => ({
            ...prev,
            multiCityLegs: multiCityLegsForm
        }));
    };

    const updateMultiCityDepartureDate = (index, date) => {
        if (!date) return;
        const { multiCityLegs } = searchQuery.flights;
        // start looping from the selected leg
        for (let i = index; i < multiCityLegs.length; i++) {
            // on the first loop, if we aren't changing the last leg date
            if (i === index && i + 1 < multiCityLegs.length) {
                if (date <= new Date(multiCityLegs[index + 1].departureDate)) {
                    multiCityLegs[i].departureDate = moment(date).format("YYYY-MM-DD");
                    break;
                }
            }
            multiCityLegs[i].departureDate = moment(date).format("YYYY-MM-DD");
        }
        setSearchQuery((prev) => ({
            ...prev,
            flights: {
                ...prev.flights,
                multiCityLegs
            }
        }));
    };

    const onFlightSearch = () => {
        let isError = false;
        const searchQueryData = searchQuery.flights;
        if (searchQueryData.type !== "multi-city") {
            const required = ["origin", "destination", "departureDate", "adults"];

            if (searchQueryData.type === "return") required.push("returnDate");
            if (searchQueryData.type === "one-way") searchQueryData.returnDate = undefined;

            required.forEach((field) => {
                if (!searchQueryData[field]) {
                    isError = true;
                    toast.error(`Please select a ${field}.`);
                }
            });
        } else if (searchQueryData.type === "multi-city") {
            form.multiCityLegs.forEach((leg, index) => {
                Object.keys(form.multiCityLegs[index]).forEach((key) => {
                    if (
                        searchQueryData.multiCityLegs[index][key] === null ||
                        searchQueryData.multiCityLegs[index][key] === ""
                    ) {
                        form.multiCityLegs[index][key].isTouched = true;
                        isError = true;
                    }
                });
            });
        }
        setForm(form);

        if (!isError) {
            const {
                origin,
                destination,
                departureDate,
                returnDate,
                multiCityLegs,
                currencyCode,
                type,
                c,
                includedAirlineCodes,
                ...others
            } = searchQueryData;
            let pathname = "/flightssearch/s/";
            const multiCity = [];
            if (type === "multi-city") {
                // multi city search
                multiCityLegs.forEach((x) => {
                    multiCity.push({
                        originId: x.origin.id,
                        destinationId: x.destination.id,
                        departureDate: x.departureDate
                    });
                });
                pathname = `flightssearch/s/${JSON.stringify(multiCity)}`;
            } else {
                // one way or return
                pathname = `/flightssearch/s/${origin.id}/${destination.id}/${departureDate}${
                    returnDate ? `/${returnDate}` : ""
                }`;
            }

            let newOthers = others;
            const usingCredit = auth.isAuthenticated && balance > 0;
            if (usingCredit) newOthers = { ...others, c: "t" };
            dispatch(updateSearch(searchQueryData));
            navigate(
                `${pathname}?${Object.keys(newOthers)
                    .map((key) => `${key}=${newOthers[key]}`)
                    .join("&")}`
            );

            // if edit search is active then we need to update the search
            if (props.editSearch) {
                props.editSearch(
                    {
                        ...searchQueryData,
                        c: usingCredit ? "t" : undefined
                    },
                    auth
                );
            }
        }
    };

    const flightsType = searchQuery.flights.type;

    return (
        <div className="SearchForm">
            {!props.editSearch && (
                <Nav className="SearchForm__tabs" tabs>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: activeTab === 1 })}
                            onClick={() => {
                                if (props.resetSelectedTab) props.resetSelectedTab();
                                props.setSelectedTab && props.setSelectedTab(1);
                            }}
                        >
                            Flights
                        </NavLink>
                    </NavItem>
                    {(STAYS_2_0 || (!IS_USA_SITE && !STAYS_2_0)) && (
                        <NavItem>
                            <NavLink
                                className={classNames({ active: activeTab === 2 })}
                                onClick={() => {
                                    if (props.resetSelectedTab) {
                                        props.resetSelectedTab();
                                    }
                                    props.setSelectedTab && props.setSelectedTab(2);
                                    isLogAmplitude && logAmplitudeEvent("staysTabClicked");
                                }}
                            >
                                {STAYS_NEW_STICKER && <NewSticker>New</NewSticker>}
                                Stays
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
            )}
            {activeTab === 1 && (
                <Form className="SearchForm__content">
                    <Label className="SearchForm__radio-label">
                        <input
                            type="radio"
                            name="flight-type"
                            onChange={() => changeSearchType("return")}
                            checked={flightsType === "return"}
                        />
                        <Trans>roundTrip</Trans>{" "}
                    </Label>
                    <Label className="SearchForm__radio-label">
                        <input
                            type="radio"
                            name="flight-type"
                            onChange={() => changeSearchType("one-way")}
                            checked={flightsType === "one-way"}
                        />
                        One Way
                    </Label>
                    <Label className="SearchForm__radio-label">
                        <input
                            type="radio"
                            name="flight-type"
                            onChange={() => changeSearchType("multi-city")}
                            checked={flightsType === "multi-city"}
                        />
                        Multi City
                    </Label>
                    {flightsType !== "multi-city" && (
                        <SingleDestinationControls
                            auth={auth}
                            balance={balance}
                            onFlightSearch={onFlightSearch}
                            searchQuery={searchQuery}
                            setForm={setForm}
                            setSearchQuery={setSearchQuery}
                            updateLocation={updateLocation}
                            isLogAmplitude={isLogAmplitude}
                        />
                    )}
                    {flightsType === "multi-city" && (
                        <MultiCityControls
                            onFlightSearch={onFlightSearch}
                            removeMultiCityLeg={removeMultiCityLeg}
                            searchQuery={searchQuery}
                            setForm={setForm}
                            addMultiCityLeg={addMultiCityLeg}
                            updateMultiCityDepartureDate={updateMultiCityDepartureDate}
                            setSearchQuery={setSearchQuery}
                            updateMultiCityLocation={updateMultiCityLocation}
                            windowWidth={windowWidth}
                        />
                    )}
                </Form>
            )}
            {activeTab === 2 && (
                <Form style={{ paddingBottom: 0 }} className="SearchForm__content">
                    <StaySearchForm />
                </Form>
            )}
        </div>
    );
};

export default SearchForm;
