import React from "react";
import { _url } from "../../../config/utils";
import { getSiteValueByLang } from "../../../utils/SiteUtils";

export const ReviewSupport = () => (
    <div className="end max-w-640">
        <div className="checkout-feature-wrapper">
            <div>
                <img
                    className="checkout-tids"
                    style={{ width: "250px", height: "100%", cursor: "pointer" }}
                    src={_url("assets/images/trustpilot-transparent.png")}
                />
            </div>
            <br />
            {getSiteValueByLang("reviewSupport")}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 30 }}>
                <img className="checkout-tids" alt="iata-tids" src={_url("assets/images/iata-tids.png")} />
            </div>
        </div>
    </div>
);
